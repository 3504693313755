import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';


import authAxios from "../../components/authAxios";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { VisibilityOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import EllipsisMenu from "../../components/EllipsisMenu";

import UnitNew from "./UnitNew";

const UnitList = ({property}) => {

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const queryClient = useQueryClient();
    const [error, setError] = useState('');

    const propertyId = property.id

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const { data: units } = useQuery({
        queryKey: ['property-units', propertyId, page],
        queryFn: async () => {
        //   const response = await authAxios.get(`/rentals/inventory/?property=${propertyId}&page=${page}`);
        //   const response = await authAxios.get(`/rentals/inventory/?property=${property.id}&page=${page}`);
            const response = await authAxios.get(`/rentals/property-units/?property=${property.id}&page=${page}`);
            setPageCount(response.data.page_count)
            return response.data.results
        },
    });


    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isMoveInModalOpen, setIsMoveInModalOpen] = useState(false);

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    // console.log("UNITS",units)

    const menuItems = (unit) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                // navigate(`/dashboard/customer/${customer.id}`)
            }
        },
        {
            label: 'Delete',
            // icon: <DeleteIcon />,
            icon: <DeleteOutlineIcon />,
            onClick: () => {
                // Handle view action
                // navigate(`/dashboard/customer/${customer.id}`)
                console.log("UNIT",unit)
                mutation.mutate(unit)
            }
        },
    ];

    const mutation = useMutation({
        mutationFn: (unit) => {
            return authAxios.delete(`/rentals/property-units/${unit.id}`);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                        // console.log("WE ARE HERE:", errorMessage) || setError(errorMessage)

                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['property-units']);
            // navigate('/dashboard/leases/');
        },
      })

    return (
        <>
            {/* {units && */}
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Reference</th>
                        {/* <th>Tenant</th> */}
                        <th>Type</th>
                        <th>Date Created</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                {units && units.map(unit => (
                    <tbody key={unit.id}>
                        <tr>
                            <td>{unit.reference}</td>
                            {/* <td>"[VACANT]"</td> */}
                            <td>{unit.product_name}</td>
                            <td>{convertDateTime(unit.created_at)}</td>
                            <td><Pillchip label={unit.status_name} /></td>
                            <td className='styled-table-menu'>
                                <EllipsisMenu items={menuItems(unit)} />
                            </td>

                        </tr>
                    </tbody>
                ))}
                
            </table> 
            <UnitNew 
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                property={property.id}
            />
                    
            <Pagination 
                className="custom-mui-pagination"
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                setIsModalOpen(true)}}
                >
                <AddIcon />
            </Fab>

        </>
    )

}

export default UnitList;