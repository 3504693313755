import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import ContactNew from "../../components/ContactNew";
import CustomSearchBar from "../../components/CustomSearchBar";

function Owners() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const tag = "OWNER"
    const [searchText, setSearchText] = useState("");

    const { data: owners, status, isPreviousData } = useQuery({
        queryKey: ['owners', page, searchText],
        queryFn: async () => {
        //   const response = await authAxios.get(`/rentals/owners/?page=${page}`);
          const response = await authAxios.get(`/rentals/owners/?page=${page}&search=${searchText}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/owner/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];   

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">

                        <DashboardHeader dashboardTitle="Owners" dashboardPreTitle="CRM" />

                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>

                
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Telephone</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th></th>
                            </tr>
                        </thead>

                        {owners && owners.map(owner => (

                            <tbody key={owner.id}>
                                <tr>
                                    <td>{owner.id}</td>
                                    <td>{owner.name}</td>
                                    <td>{owner.telephone}</td>
                                    <td>{owner.email}</td>
                                    <td>{owner.address}</td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={owner} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <ContactNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        tag={tag}
                        // property={property.id}
                    />
                        
                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/dashboard/contact-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                    {/* <Link to="/dashboard/contact-new" state={{ tag: "OWNER" }} className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}

                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                </section>
            </div>

    )
}
export default Owners