// import React, {memo} from "react";
import { useState, useEffect } from "react";
import { useQueryClient, useMutation } from '@tanstack/react-query';

import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useNavigate, useParams } from "react-router-dom";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete } from "@mui/material";
import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";
import { useCountRenders } from "../../hooks/useCountRenders";


// const CustomerEdit = () => {

const OwnerEdit = ({owner, setError, contact, countries}) => {

    // useCountRenders()
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)
    const queryClient = useQueryClient();


    const {fetchData, data, response}  = useAxios();
    // const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState(owner.name);
    const [email, setEmail] = useState(owner.email);
    const [phone, setPhone] = useState(owner.telephone);
    const [address, setAddress] = useState(owner.address);
    const [city, setCity] = useState(owner.city);
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');

    const [website, setWebsite] = useState(owner.website);
    const [isPerson, setIsPerson] = useState(false);
    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const [nameError, setNameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    const navigate = useNavigate();

    const handleChange = (event) => {
        setName(event.target.value);
      };

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }

    const mutation = useMutation({
        mutationFn: (customer) => {
            return authAxios.put(`crm/customers/${params.id}/`, customer);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['customer', 'customers']);
            queryClient.refetchQueries(['customer']);
            navigate('/dashboard/customers/');
        },
      })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const telephone = phone.replace(/[()" "]/g,"")
            // const birth_date = moment(dt).format("YYYY-MM-DD")

            // const customer = { client, contact, name, address, country, email, telephone, id_type, id_number, gender, birth_date, user };

            const customer = { client, name, address, city, email, telephone, website, user };
            console.log(customer)
            mutation.mutate(customer)
        } 
    }

    return(
        <section className="dashboard-form">
            {owner &&
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        value={name}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <TextField className="custom-input"
                        // value={email}
                        value={email ?? ''}

                        // value={customer.email}

                        onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={emailError}
                    />

                    <NumberFormat 
                        value = {phone}
                        // value = {customer.telephone}

                        onChange={(e) => {
                            setPhoneError(false)
                            setPhone(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Mobile" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        // required
                        error={phoneError}
                    />

                    <TextField className="custom-input"
                        value={address ?? ''}
                        onChange={(e) => setAddress(e.target.value)}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />

                    <TextField className="custom-input"
                        value={city ?? ''}
                        onChange={(e) => setCity(e.target.value)}
                        label="City/Town" 
                        variant="outlined" 
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        // error={cityError}
                    />  

                    {country &&

                    <Autocomplete
                        // value={country}
                        value={ countries[country]}

                        // value={country ?? ''}
                        // getOptionSelected={(option, value) => option.value === value.value}

                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        // defaultValue={ countries[country] }

                        // renderOption={(props, option) => (
                        //     <li {...props} key={option.id}>
                        //       {option.name}
                        //     </li>
                        //   )}
                        // // isOptionEqualToValue={(option, value) => option.id === value.id}  
                        // isOptionEqualToValue={(option, value) =>
                        //         value === undefined || value === "" || option.id === value.id
                        //       }

                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />
                    }

                    <TextField className="custom-input"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />  
                </div>
                {isPerson &&
                <>
                {/* <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={customer.id_type}
                        onChange={(e) => setId_type(e.target.value)}
                    >
                        <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="Citizen ID" />
                        <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Passport" />
                    </RadioGroup>

                    <TextField className="custom-input"
                        value={customer.id_number}
                        onChange={handleIdNumber}
                        label="ID/Passport Number" 
                        helperText="Maximum number of characters is 9" 
                        variant="outlined" 
                        fullWidth
                        error={idNumberError}
                    />
                </div> */}

                <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // defaultChecked=""
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        // className="custom-input"
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                </>
                }

                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
            }
        </section>
    )

}

// export default memo(CustomerEdit);
export default OwnerEdit;

