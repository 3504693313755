import {Routes, Route} from "react-router-dom";

import Home from "../pages/home/Home";
import Join from "../pages/join";
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import Contact from "../pages/Contact";
import Crm from '../pages/products/Crm';
// import Pos from '../pages/products/Pos';
// import Inventory from '../pages/products/Inventory';
import Layout from "./Layout";
// import Dashboard from "./Dashboard";

import Dashboard from "./Dashboard";
import AdminDashboard from "../admin/AdminDashboard";

import AdminRoutes from "../admin/AdminRoutes";
import ClientRoutes from "./ClientRoutes";

const userType = localStorage.getItem('userType');



const AppRoutes = () => {
    return (
    <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/join" element={ <Join/> } />  
        <Route path="/login" element={ <Login/> } />  
        <Route path="/forgot" element={ <ForgotPassword/> } />  
        <Route path="/password/reset/:uid/:token" element={ <ResetPassword/> } />  
        <Route path="/contact" element={ <Contact/> } />  


        <Route path="/products"  > 
            <Route path="crm" element={ <Crm/> }/>
        </Route>

        <Route path="dashboard" element={<Layout />}> 
            {/* {userType === 'ADM' &&  <Route path="*" element={<AdminRoutes />} />}
            {userType !== 'ADM' &&  <Route path="*" element={<ClientRoutes />} />} */}

            {userType === 'ADM' &&
            <>
                <Route index element={<AdminDashboard />} />
                <Route path="*" element={<AdminRoutes />} />
            </>
            }
            {userType !== 'ADM' &&
            <>
                <Route index element={<Dashboard />} />
                <Route path="*" element={<ClientRoutes />} />
            </>
            }
        </Route>
    </Routes>
    )
}
export default AppRoutes


