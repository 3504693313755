
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

// import authAxios from './authAxios';
import authAxios from '../components/authAxios';


import { Result } from 'antd';
import {IconButton, Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';

// import Layout from './Layout';
// import InvoiceReport from '../reports/InvoiceReport';


const Confirmed = () => {

    //Pass state variables from router
    const location = useLocation();
    const navigate = useNavigate();
    // const {fetchData}  = useAxios();   


    const title = location.state.title;
    const subTitle = location.state.subtitle;
    const reference = location.state.reference;
    const tag = location.state.tag;

    // const id = location.state.id;

    const mutation = useMutation({
        // mutationFn: (transaction) => {
        mutationFn: () => {
            // return authAxios.post("/accounting/transactions/", transaction);
            return authAxios.post("/africastalking/send-sms/");
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            // if (error.response.data.error){
            //     // If it's an array, display each error message
            //     if (Array.isArray(error.response.data.error)) {
            //         error.response.data.error.map((errorMessage) => (
            //             setError(errorMessage)
            //         ));
            //     } else {
            //         // Handle single error message
            //         setError(error.response.data.error); 
            //     }
            // } else {
            //     // Fallback to Standard error message
            //     setError(error.message); 
            // }      
        },
        onSuccess: (result) => {
            console.log("SUCCESS",result)

            // queryClient.invalidateQueries(['transactions']);
            // if (isInvoice){
            //     navigate('/dashboard/confirmed/', {state:{title:title, subtitle:subTitle, reference:reference}});
            // }
            // else {
            //     navigate('/dashboard/transactions/');
            // }
        },
      })


    const printInvoice = () => {
        console.log("TAG", tag)
        navigate('/dashboard/invoicereport/',{state:{reference:reference, tag:tag}});
    }


    const sendSms = () => {
        console.log("REFERENCE",reference)
        mutation.mutate()

    }


    return (
        // <Layout>
            <div className="dashboard-main">

                    <Result
                        status="success"
                        // title="Successfully Purchased Cloud Server ECS!"
                        title={title}
                        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                        subTitle={subTitle}
                        extra={[
                                // <Button type="primary" key="console">
                                //     Go Console
                                // </Button>,
                                // <Button key="Print">Print Invoice</Button>,
                                <Button
                                    variant="outlined" 
                                    size="small"
                                    onClick={printInvoice}
                                    startIcon={<DownloadForOfflineIcon />}
                                >
                                    Download
                                </Button>,
                                <Button
                                 variant="outlined" 
                                 size="small"
                                 startIcon={<MailOutlineIcon />}
                                >
                                    Email
                                </Button>,
                                <Button
                                     variant="outlined" 
                                     size="small"
                                     startIcon={<WhatsAppIcon />}
                                >
                                    WhatsApp
                                </Button>, 
                                <Button
                                    variant="outlined" 
                                    size="small"
                                    startIcon={<SmsIcon />}
                                    onClick={sendSms}
                                >
                                   SMS
                                </Button>
                               

                        ]}
                    />

            </div>
        // </Layout>

    )
}

export default Confirmed;