// import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios"

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import { VisibilityOutlined } from "@mui/icons-material";
import BorderColorSharpIcon from '@mui/icons-material/BorderColor';



// import Layout from "../../components/Layout";
// import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import EllipsisMenu from "../../components/EllipsisMenu";
import Pillchip from "../../components/Pillchip";


import { format } from 'date-fns';
import ProductEdit from "./ProductEdit";


// import ProductNew from "./ProductNew";

function Products() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { data, fetchData}  = useAxios();   

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data
        },
    });


    const { data: products } = useQuery({
        queryKey: ['skus', page],
        queryFn: async () => {
          const response = await authAxios.get(`/products/skus/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [product, setProduct] = useState("")



    // const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    // const handleDrawerOpen = () => {
    //     setIsDrawerOpen(true)
    // };
    // const handleDrawerClose = () => {
    //     setIsDrawerOpen(false)
    // };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    //  const threeSubMenu = [
    //     {
    //         id: 1,
    //         menu: "View",
    //         url: "/dashboard/product/",
    //     }, 
    //     {
    //         id: 2,
    //         menu: "Edit",
    //         url: "/customeredit/",
    //     }
    // ];

    const menuItems = (product) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                navigate(`/dashboard/product/${product.id}`)
                // navigate('/dashboard/product/')

            }
        },
        {
            label: 'Edit',
            icon: <BorderColorSharpIcon />,
            onClick: () => {
                setProduct(product)
                setIsModalOpen(true)
                // Handle view action
                // navigate(`/dashboard/customer/${customer.id}`)
            }
        },
    ];


    return (
            <div className="dashboard-main">

                <section className="dashboard-content">
                    
                    <DashboardHeader dashboardTitle="Products" dashboardPreTitle="Rentals" />

                    {isMobile? 
                    <>
                    
                    {products && products.map(product => ( 

                    <Card className="dashboard-card" key={product.id}>
                        
                        <CardContent className="dashboard-card-content">
                            <div className="dashboard-card-title">{product.name}</div>
                            <div className="dashboard-card-subtitle">{product.sku}</div>                            
                            <p>{product.type_name}</p>
                            <p>{product.price}</p>
                            {/* <p>Gender: {customer.gender}</p> */}
                            {/* <Pillchip label={booking.status_name} /> */}
                        </CardContent>
                        {/* <CardActions>
                            <Button size="small">Booking</Button>
                        </CardActions> */}
                    </Card>
                    ))}

                    </>

                    :
                    // If Device is not mobile, display data using a table
                    <>
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>Reference</th>
                                <th>Date Created</th>
                                <th>Name</th>
                                {/* <th className="cell-align-right">Price Lists</th> */}
                                <th className="cell-align-right">Base Price</th>

                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {products && products.map(product => (

                            <tbody key={product.id}>
                                <tr>
                                    <td>{product.sku}</td>
                                    <td>{convertDateTime(product.created_at)}</td>
                                    <td>{product.name}</td>
                                    {/* <td>{product.price}</td> */}
                                    {clientProfile && clientProfile.currency_code? 
                                        <td className="cell-align-right">{Number(product.price).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                        :
                                        <td>{Number(product.price).toLocaleString('en-UK')}</td>
                                    }
                                    <td><Pillchip label={product.status_name} /></td>

                                    <td className='styled-table-menu'>
                                        {/* <ThreeDotsMenu data={product} threeSubMenu={threeSubMenu} /> */}
                                        <EllipsisMenu items={menuItems(product)} />

                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <ProductEdit
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        product={product}
                    />
                    </>

                    }

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/product-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>

                </section>
            </div>

        // </Layout>


    )
}
export default Products