import { useState, useEffect, useRef, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
// import NumberFormat from "react-number-format";
// import { Select } from "@mui/material";

// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import moment from 'moment';

//ANT DESIGN
// import { message, Upload } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import { TreeSelect } from 'antd';
import { Modal } from 'antd';


import {UserContext} from "../../App"
import UserAlerts from "../../components/UserAlerts";
// import DashboardHeader from "../../components/DashboardHeader";

import './Rentals.css'


// import { Steps, Form, Input, Radio, Select, DatePicker, Button } from 'antd';
// import { Steps, Form, Input, Select, DatePicker, Button } from 'antd';


function PropertyNew({isModalOpen, setIsModalOpen}) {

    // const {response, error, fetchData}  = useAxios();
    const [error, setError] = useState('');
    const errorRef = useRef(null);


    const queryClient = useQueryClient();

    const page_size = useContext(UserContext).page_size;

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');


    // FETCH COUNTRIES
    // const { data: countries } = useAxios({
    //     url: '/countries',
    //     method: 'get'
    // })

    // const { data: locationTypes } = useAxios({
    //     url: '/inventory/location-types',
    //     method: 'get'
    // })

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: propertyTypes } = useQuery({
        queryKey: ['propertyTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/rentals/property-types");
          return response.data
        },
    });


    // const [accounts, setAccounts] = useState([]);

    // const transformDataToTree = (data) => {
    //     return data.map((item) => ({
    //         value: item.id, // Convert to string if needed
    //         title: item.name,
    //         children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
    //     }));
    // };


    // const { data: accts } = useQuery({
    //     queryKey: ['accounts-tree'],
    //     queryFn: async () => {
    //       const response = await authAxios.get("/accounting/accounts-tree");
    //       setAccounts(transformDataToTree(response.data))
    //       return response.data
    //     },
    // });

    const { data: owners } = useQuery({
        queryKey: ['owners'],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/owners/?page_size=${page_size}`);
        //   const response = await authAxios.get(`/crm/customers?page_size=${page_size}`);
          return response.data.results
        },
        keepPreviousData : true,
    });


    const [clientType, setClientType] = useState('');
    const { data: rentalConfig } = useQuery({
        queryKey: ['rentalConfig'],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/rental-config`);
            const config = response.data.find(config => parseInt(config.client) === parseInt(client));
            setClientType(config.client_type)
            return response.data
        },
    });


    const navigate = useNavigate();
    // const location = useLocation();
    // const tag = location.state.tag
    // const [dt, setDt] = useState(new Date());
    // const [dt, setDt] = useState(null);

    const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');


    // const [id_files, setIdFiles] = useState([]);
    // const [type, setType] = useState('');
    

    const [landlord, setLandlord] = useState('');
    // const [trustAccount, setTrustAccount] = useState('');
    // const [controlAccount, setControlAccount] = useState('');
    const [memo, setMemo] = useState('');
    // const [source, setSource] = useState('');
    // const [locationType, setLocationType] = useState('');
    const [nameError, setNameError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);


    const contactRef = useRef("");
    contactRef.showContactType=true    

    // const filterTreeNode = (input, treeNode) => {
    //     const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
    //     return nodeLabel.includes(input.toLowerCase());
    // };

    const mutation = useMutation({
        mutationFn: (property) => {
            return authAxios.post("/rentals/properties/", property);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['properties']);
            // navigate('/dashboard/properties/')
            setIsModalOpen(false)
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (address === '' ) {
            setAddressError(true)
        }

        if (city === '' ) {
            setCityError(true)
        }

        if (name && address && city ) {

            // const location_type = locationType
            // const propType = propertyTypes.find(type => type.code === "30200");
            // const trust_account = trustAccount
            // const control_account = controlAccount

            // const property = { type, client, name, address, city, country, email, trust_account, control_account, user, memo, landlord };
            const property = {client, name, address, city, country, user, memo, landlord };

            console.log(property)
            mutation.mutate(property)
            
        } 
    
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return(
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Property" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultChecked=""
                        onChange={(e)=>{
                            const propType = propertyTypes.find(type => type.code === e.target.value);
                            // setType(e.target.value)
                            setType(propType.id)
                        }}
                    >
                        <FormControlLabel className="custom-radio" value="010" control={<Radio />} label="Residential" />
                        <FormControlLabel className="custom-radio" value="020" control={<Radio />} label="Commercial" />

                    </RadioGroup> */}

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                        }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setAddressError(false)
                            setAddress(e.target.value)
                        }}
                        label="Physical Address" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={addressError}
                    />  

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setCityError(false)
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={cityError}
                    />  

                    {/* <Autocomplete
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    /> */}

                    
                    {clientType==="010" && 
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setLandlord(newValue.id);
                                console.log("VALUE", newValue.id)
                                }}
                            options={owners}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Owner"  /> }
                        />
                    }

                    <TextField className="custom-input"
                        onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                    />
                </form>
                </div>
            </Modal>
        }
        </>

    )

}

export default PropertyNew;
