// import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import {Button} from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const PropertyView = ({property}) => {
    // const client = localStorage.getItem('clientID');
    // const user = localStorage.getItem('userID');
    const navigate = useNavigate()


    return(
        <section className="dashboard-form">
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Name"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {property.name}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Address"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {property.address}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField className="custom-input"
                        label="City"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {property.city}
                        InputProps={{ readOnly: true }}
                    />
                </div>

                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Landlord"
                        variant="outlined" 
                        fullWidth
                        // defaultValue = {property.landlord}
                        defaultValue = {property.owner}

                        InputProps={{ readOnly: true }}
                    />

                </div>

                
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        label="Memo"
                        variant="outlined" 
                        fullWidth
                        multiline
                        rows={2}
                        defaultValue = {property.memo}
                        InputProps={{ readOnly: true }}
                    />
                  
                </div>
                {/* <div className="button-border-top">
                    <button className="btn btn-primary form-buttons" onClick={()=>navigate("/dashboard/transactions")}>
                        Back
                    </button>
                    <Button
                        onClick={()=>navigate("/dashboard/transactions")}
                        variant="outlined" 
                        size="small"
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </div> */}
                <br/>
                <br/>

            </form>

        </section>
    )
}

export default PropertyView;