import React from "react"
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from 'axios';
// import useAxios from "../components/useAxios";
// import useAxiosInterceptor from "../components/useAxiosInterceptor";

import Header from "./Header";
import { TextField } from '@mui/material';
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormLabel } from "@mui/material";

import NumberFormat from "react-number-format";
import FooterSlim from "../components/FooterSlim";
import UserAlerts from "../components/UserAlerts";
import CustomSnackBar from "../components/CustomSnackBar";

import contactusimg from '../assets/contact-us.png'


function Contact() {
  //Axios
  // const { err, fetchData}  = useAxios();
  // const {response, error } = useAxiosInterceptor();
  // const [open, setOpen] = useState(false);


  //  Fetch Products 
  const [products, setProducts] = useState([]);
  useEffect(() => {

  axios.get('/products/')
  .then(res => {
    // console.log("res",res);
    console.log("res.data", res.data);
    setProducts(res.data)
    // navigate(from, { replace: true });
  })
}, []); 


  


  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [prodCode, setProdCode] = useState('');
  const [units, setUnits] = useState('');
  const [error, setError] = useState('');

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [prodCodeError, setProdCodeError] = useState(false)
  const [unitsError, setUnitsError] = useState(false)

  //Handle snackbar for success feedback to the user
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSnackBarClose = () => {
    // console.log("DRAWER CLOSE")
    setSuccess(false)
  };

  const [response, setResponse] = useState('');
  useEffect(() => {
    // console.log("RESPONSE",response.status)
    // if (response.status === 201){
    if (response.status === 201 && success === false){
        navigate(from, { replace: true });
    }    
}, [ response.status, success]); 



  const handleSubmit = (e, data) => {
    e.preventDefault();
    
    if (name === '') {
      setNameError(true)
    }
    else{
      setNameError(false)
    }

    if (email === '' ) {
        setEmailError(true)
    }

    if (phone === '' ) {
      setPhoneError(true)
    }

    if (message === '' ) {
      setMessageError(true)
    }

    if (prodCode === '' ) {
      setProdCodeError(true)
    }

    if (name && email && message) {

      const telephone = phone.replace(/[()" "]/g,"")

      // const prodChoice = products.find(function(product) {
      //   return product.code === prodCode;
      // });
      // const product = prodChoice.id

      const lead = { name, email, telephone, message, units };

      console.log("lead", lead)

      // fetchData({
      //   url: '/saasleads/',
      //   method: 'post',
      //   data: lead
      // })

      axios.post('/saasleads/', lead )
      // .then(res => {

      .then(res => {
        // console.log("res",res);
        // console.log("res.data", res.data);
        setResponse(res)
        setSuccess(true)
        setSuccessMessage("Thank you. We will get back to you shortly")
        // console.log("RESPONSE",response.status)
        
        // navigate(from, { replace: true });

      })
      .catch(function (error) {
        if (error.response.status === 400 ){
          setError(error.message + " - " + error.response.request.response);
        }
        else if (error.response.status === 401){
          setError(error.response.status +" " + error.response.statusText + " - " + error.response.data.detail);
        }
        else {
          setError(error.response.status + " " + error.response.statusText);
        }
        // setError(error.message)
        // console.log("error", error.response);
        console.log("error", error);

      });
      

    }
  }


  return (
      <div className="container-fluid">

        <div className="container">
          <Header />

          {/* <div className="container-auth"> */}

          <div className="landing-form-container">
            <div className="landing-form-bg-purple">
              <div className="auth-img-title">
                Get in touch!
              </div>
              <div className="auth-img-subtitle">
                Enterprise software for growing businesses
              </div>
              {/* <img src={contactusimg} alt="contact us"/>   */}
            </div>

            <div className="landing-form">


              {/* User Alerts */}
              {/* <UserAlerts error={error} /> */}
              <UserAlerts error={error} />


              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            
                {/* <div className='auth-form-brand'>
                  <img src={logo} alt="brand logo"/>  

                </div> */}

                <TextField
                  onChange={e => {
                    setNameError(false)
                    setName(e.target.value)
                  }}
                  className="custom-input"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  type="text"
                  name="name"
                  // autoComplete="email"
                  autoFocus
                  error={nameError}
                />

                <TextField
                  onChange={e => {
                    setEmailError(false)
                    setEmail(e.target.value)
                  }}
                  className="custom-input"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  // autoComplete="email"
                  // autoFocus
                  error={emailError}
                />

                <NumberFormat 
                  onChange={(e) => {
                    setPhoneError(false)
                    setPhone(e.target.value)
                  }}
                  className="custom-input"
                  fullWidth
                  label="Telephone" 
                  variant="outlined" 
                  customInput={TextField}
                  format="### (###) ######" mask="_"
                  // thousandSeparator={true} 
                  // prefix={'KES '} 
                  required
                  error={phoneError}
                />
              
                {/* <FormControl 
                  className="custom-formcontrol" 
                  error={prodCodeError}
                  required
                  onChange={(e) => {
                    setProdCodeError(false)
                    setProdCode(e.target.value)
                  }}
                >
                  <FormLabel id="demo-radio-buttons-group-label">Type of Business</FormLabel>
                  <RadioGroup
                    name="row-radio-buttons-group"
                    defaultChecked=""
                  >
                    <FormControlLabel className="custom-radio" value="10" control={<Radio />} label="AirBnB/Vacation Homes"/>
                    <FormControlLabel className="custom-radio" value="20" control={<Radio />} label="Serviced Apartments" />
                    <FormControlLabel className="custom-radio" value="30" control={<Radio />} label="Hotels and Lodges" />
                  </RadioGroup>
                </FormControl>

                <NumberFormat 
                  onChange={(e) => {
                    setUnitsError(false)
                    setUnits(e.target.value)} 
                  }
                  className="custom-input"
                  fullWidth
                  label="Number of Units" 
                  variant="outlined" 
                  customInput={TextField}
                  thousandSeparator={true} 
                  required
                  error={unitsError}

                /> */}

                
                <TextField className="custom-input"
                    onChange={(e) => {
                      setMessageError(false)
                      setMessage(e.target.value)
                    }}
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    error={messageError}
                />

                <div>
                  <button className='btn btn-primary' type="submit">Send Message</button>
                </div>
              </form>
            </div>
          </div>

          <CustomSnackBar success={success} successMessage={successMessage} handleSnackBarClose={handleSnackBarClose}/>


        </div>

        <div className="footer-bg-lightblue">
            {/* <div className="container"> */}
              <FooterSlim />
              {/* <Footer /> */}

            {/* </div> */}
          </div>

      </div>

    )
  }


 export default Contact;
