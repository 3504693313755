import { useNavigate } from "react-router-dom";
// import { useQueryClient } from '@tanstack/react-query';
// import authAxios from "../../components/authAxios";
import NumberFormat from "react-number-format";

import { TextField, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import moment from 'moment';



const ClientProfileView = ({clientProfile}) => {
    // const queryClient = useQueryClient();
    // const [dt, setDt] = useState(new Date());
    // const [name, setName] = useState(clientProfile.name);
    // const [tin, setTin] = useState(clientProfile.tin);
    // const [email, setEmail] = useState(clientProfile.email);
    // const [phone, setPhone] = useState(clientProfile.telephone);
    // const [address, setAddress] = useState(clientProfile.address);
    // const [currency, setCurrency] = useState(clientProfile.currency);
    // const [city, setCity] = useState(clientProfile.city);
    // const [country, setCountry] = useState(clientProfile.country);
    // const [website, setWebsite] = useState(clientProfile.website);
    // const [debtorsControl, setDebtorsControl] = useState(clientProfile.debtors_control);
    // const [apAccount, setApAccount] = useState(clientProfile.ap_account);
    // const [contact, setContact] = useState(clientProfile.contact);
    // const client = localStorage.getItem('clientID');
    // const user = localStorage.getItem('userID');
    // const [nameError, setNameError] = useState(false)
    // const [cityError, setCityError] = useState(false)
    // const [phoneError, setPhoneError] = useState(false)
    // const [emailError, setEmailError] = useState(false)

    const navigate = useNavigate();


    return(
        <section className="dashboard-form">
            {/* <form noValidate autoComplete="off" onSubmit={handleSubmit}> */}

            <form noValidate autoComplete="off">
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        // value={name}
                        defaultValue = {clientProfile.name}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                    />

                    <TextField className="custom-input"
                        defaultValue = {clientProfile.tin}
                        InputProps={{ readOnly: true }}
                        label="Tax Identification Number" 
                        variant="outlined" 
                        fullWidth
                    />  

                    
                </div>
                <div className="grouped-fields-light">

                    <NumberFormat 
                        // value = {phone}
                        // onChange={(e) => {
                        //     setPhoneError(false)
                        //     setPhone(e.target.value)
                        // }}
                        defaultValue = {clientProfile.telephone}
                        className="custom-input"
                        fullWidth
                        label="Telephone" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        InputProps={{ readOnly: true }}
                        // required
                        // error={phoneError}
                    />

                    <TextField className="custom-input"
                        // value={email ?? ''}
                        // onChange={(e) => {
                        //     setEmailError(false)
                        //     setEmail(e.target.value)
                        // }}
                        defaultValue = {clientProfile.email}
                        label="Email" 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={emailError}
                    />
                    <TextField className="custom-input"
                        // value={website ?? ''}
                        // onChange={(e) => setWebsite(e.target.value)}
                        defaultValue = {clientProfile.website}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                        InputProps={{ readOnly: true }}
                        // required
                        // error={mobileError}
                    />  

                   
                </div>
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        // value={address ?? ''}
                        // onChange={(e) => setAddress(e.target.value)}
                        defaultValue = {clientProfile.address}
                        label="Address" 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />
                    {/* } */}
                    
                    <TextField className="custom-input"
                        // value={city ?? ''}
                        // onChange={(e) => {
                        //     setCityError(false)
                        //     setCity(e.target.value)
                        // }}
                        defaultValue = {clientProfile.city}
                        InputProps={{ readOnly: true }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={cityError}
                    />  

                    {/* {countries &&
                    <Autocomplete
                        value={country ?? ''}
                        onChange={(event, newValue) => {
                            // setCountry(newValue.id);
                            setCountry(newValue);
                            }}
                        options={countries}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Country"  /> }
                    />
                    } */}
                </div>
                <br/>
                <br/>


                {/* <Button
                    onClick={()=>navigate("/dashboard")}
                    variant="outlined" 
                    size="small"
                    startIcon={<ArrowBackIcon />}
                >
                    Back
                </Button> */}
            </form>            
        </section>
    )

}

export default ClientProfileView;

