// import React, {memo} from "react";
import { useState, useEffect, useRef } from "react";
import { useQueryClient, useMutation } from '@tanstack/react-query';

import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import { useNavigate, useParams } from "react-router-dom";
import { Radio } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete } from "@mui/material";

import { Modal } from 'antd';


import moment from 'moment';
import UserAlerts from "../../components/UserAlerts";
import { useCountRenders } from "../../hooks/useCountRenders";

// const CustomerEdit = () => {

const TenantEdit = ({customer, isModalOpen, setIsModalOpen}) => {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const errorRef = useRef(null);
    const userAlertRef = useRef(null);


    // useCountRenders()
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)
    const queryClient = useQueryClient();


    // const {fetchData, data, response}  = useAxios();
    // const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");

    // const [name, setName] = useState(customer.name);
    // const [email, setEmail] = useState(customer.email);
    // const [phone, setPhone] = useState(customer.telephone);
    // const [address, setAddress] = useState(customer.address);
    // const [city, setCity] = useState(customer.city);
    // const [id_type, setId_type] = useState('');
    // const [id_number, setId_number] = useState('');
    // const [gender, setGender] = useState('');
    // const [country, setCountry] = useState('');

    const [website, setWebsite] = useState(customer.website);
    const [isPerson, setIsPerson] = useState(false);
    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const [nameError, setNameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        setName(event.target.value);
      };

    // const handleIdNumber = (e) => {
    //     if (e.target.value.length>9){
    //         setIdNumberError(true)
    //     }
    //     else{
    //         setIdNumberError(false)
    //         setId_number(e.target.value)
    //     }
    // }

    const mutation = useMutation({
        mutationFn: (customerData) => {
            return authAxios.put(`crm/customers/${customer.id}/`, customerData);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            // setSuccess("Customer has been updated")

            queryClient.invalidateQueries(['customer', 'customers']);
            // queryClient.invalidateQueries(['customers']);
            queryClient.refetchQueries(['customers']);
            queryClient.refetchQueries(['customer']);

            // navigate('/dashboard/customers/');
            setIsModalOpen(false);
        },
      })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            const telephone = phone.replace(/[()" "]/g,"")
            // const birth_date = moment(dt).format("YYYY-MM-DD")

            // const customer = { client, contact, name, address, country, email, telephone, id_type, id_number, gender, birth_date, user };

            const customerData = { client, name, address, city, email, telephone, website, user };
            console.log(customerData)
            mutation.mutate(customerData)
        } 
    }

    useEffect(()=>{   
        if (customer){
            setName(customer.name);
            setPhone(customer.telephone)
            setEmail(customer.email)
            setAddress(customer.address)
            setCity(customer.city)
        }
    },[customer])

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return(
        <>
        {isModalOpen && 
        <Modal className="dashboard-modal" title="Edit Customer" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                {/* <div ref={userAlertRef}>
                    <UserAlerts error={error} success={success}/>
                </div> */}
                {customer &&
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <TextField className="custom-input"
                            value={name}
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                                
                            }}
                            // onChange={handleChange}
                            label="Name" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />

                        <NumberFormat 
                            value = {phone}
                            onChange={(e) => {
                                setPhoneError(false)
                                setPhone(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Mobile" 
                            variant="outlined" 
                            customInput={TextField}
                            format="### (###) ######" mask="_"
                            required
                            error={phoneError}
                        />

                        <TextField className="custom-input"
                            value={email ?? ''}
                            onChange={(e) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                            }}
                            label="Email" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                            required
                            error={emailError}
                        />

                    
                        <TextField className="custom-input"
                            value={address ?? ''}
                            onChange={(e) => setAddress(e.target.value)}
                            label="Address" 
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            fullWidth
                        />

                        <TextField className="custom-input"
                            value={city ?? ''}
                            onChange={(e) => setCity(e.target.value)}
                            label="City/Town" 
                            variant="outlined" 
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                        />  

                </form>
                }
            </section>
        </Modal>
        }
        </>

    )

}

// export default memo(CustomerEdit);
export default TenantEdit;

