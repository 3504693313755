// import React from "react"
import { useRef, useEffect } from "react";

import Footer from "../Footer";
import Header from "../Header";
import Hero from "../../components/Hero";
// import Main from "../components/Main";
// import Features from "../../components/Features";
import Cta from "./Cta";
// import Clients from "../Clients";
import Partners from "./Partners";

import hero from '../../assets/crm-hero.png'

import './Home.css'



function Home() {
    const scrollRef = useRef(null);

    useEffect(()=>{
        scrollRef.current.scrollIntoView();  
    },[])

    return (
        // <>
        //     <div className="container-fluid">
        //         <div className="hero-bg-lightblue">
        //             <div className="container">
        //                 <Header />
        //                 <Hero /> 
        //                 <Features />
        //                 <Clients />
        //                 <Cta />
        //                 <Footer />        
        //             </div>
        //         </div>
               
        //     </div>

        // </>

        <div className="container-fluid">

            <div className="home-container">
                <div ref={scrollRef}>
                    <Header />
                </div>

                <div className="hero hero-bg-cyan">
                    <div className="hero-text-container">

                        <div className="hero-sub-title">
                            Property Management
                        </div>
                        <div className="hero-title">
                            be different.
                        </div>
                        <div className="hero-text">
                            {/* A single view of your prospects and customers that gives sales, marketing and service teams the real-time data they need to deliver exceptional customer experiences and growth. */}
                            {/* Having full, real-time visibility to accurate inventory levels is critical to running a smooth operation. Whether you have a single location or many, our inventory management system gives you this visibility. */}
                            Whether you are a commercial or residential property Owner or Agent, we use technology to provide exceptional tenant service and create a better living. You save money, communicate better and rent happy. Paperless. Simple. Efficient. Secure.
                        </div>
                    </div>
                    <div className="hero-img-container">
                        <img src={hero} alt="hero"/> 
                    </div>
                </div>


                <div className="features">
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Property Accounting
                        </div>
                        <div className="features-card-text">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            Comprehensive financial management of each property including profitability, expenses, taxes, debtors and cash flow enabling you to make informed financial decisions.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Billing
                        </div>
                        <div className="features-card-text">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            {/* This feature enables property managers to set up automated billing schedules for rent, utilities, or other recurring charges, eliminating the need for manual invoicing and payment collection.  */}
                            Streamline timely collection of rent (and other recurring charges), reduce administrative overheads, and improve cash flow through automatic billing bundled with sms/email notifications. 
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Payments
                        </div>
                        <div className="features-card-text">
                            {/* A dynamic and centralized platform to systematically track and win opportunities throughout the entire sales lifecycle. */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            We automatically reconcile Tenant payments through a seamless integration with multiple electronic methods including credit/debit cards, bank transfers, or mobile wallets.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Tenant Portal
                        </div>
                        <div className="features-card-text">
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud. */}
                            Improve Tenant experience through a self-service portal that manages rent payments, access to account statements, and submission of maintenance requests. 

                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Online Leases
                        </div>
                        <div className="features-card-text">
                            {/* Get a 360-degree view of your customers through a seamless connectivity of the CRM with other business applications and tools. */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            {/* Expedite the leasing process by allowing parties to complete and sign lease documents entirely online, eliminating the need for physical paperwork and manual signatures.  */}
                            Enhance effeciency and expedite the leasing process through digital creation, tracking, signing, and management of rental agreements with Tenants.
                        </div>

                    </div>

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Analytics
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            We provide analytics tools that empower you to measure key metrics, identify trends, forecast occupancy, and make informed strategic decisions.

                        </div>

                    </div>                    

                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Mobile 
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            Our responsive design enables your Tenants, Partners and staff to access and update information from anywhere using mobile devices.
                        </div>

                    </div>
                    <div className="features-card">
                        <div className="feature-bullet">

                        </div>
                        <div className="features-card-title">
                            Customization
                        </div>
                        <div className="features-card-text">
                            {/* a book or other written or printed work, regarded in terms of its content rather than its physical form. */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. */}
                            Every business is unique, and your Property Management platform should reflect that. During implementation we mold the technology platform to fit your business.

                        </div>

                    </div>

                </div>
                
                <Partners />


                <Cta />

                <Footer />        
            </div>
        </div>
    ) 
}

export default Home