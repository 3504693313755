// import React, {memo} from "react";
import { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from "react-router-dom";
import authAxios from "../../components/authAxios";

import { TextField, Autocomplete } from "@mui/material";

import { Radio } from "antd";

import NumberFormat from "react-number-format";
import { Modal } from 'antd';
import UserAlerts from "../../components/UserAlerts";
import Loading from "../../components/Loading";



const ProductEdit = ({product, isModalOpen, setIsModalOpen}) => {
    const [error, setError] = useState('');
    const errorRef = useRef(null);
    const queryClient = useQueryClient();

    const [name, setName] = useState("");
    const [sku, setSku] = useState("");
    const [newPrice, setNewPrice] = useState("");
    const [productType, setProductType] = useState(null);

    const [nameError, setNameError] = useState(false)
 

    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (event) => {
        setName(event.target.value);
      };

    
    const { data: productTypes, isLoading: isLoadingProductTypes } = useQuery({
        queryKey: ['productTypes'],
        queryFn: async () => {
          const response = await authAxios.get("/rentals/rental-products/types");
          return response.data
        },
    });

    const mutation = useMutation({
        mutationFn: (productData) => {
            return authAxios.put(`products/skus/${product.id}/`, productData);
        },
        onError: (error, variables, context) => {
            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (data, variables, context) => {
            // setSuccess("Customer has been updated")
            // queryClient.invalidateQueries(['product', 'products']);
            queryClient.invalidateQueries(['skus']);
            // queryClient.refetchQueries(['products']);
            // queryClient.refetchQueries(['product']);
            // navigate('/dashboard/customers/');
            setIsModalOpen(false);
        },
      })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (name ) {
            // const telephone = phone.replace(/[()" "]/g,"")
            const price = parseFloat(newPrice.replace(/,/g, ''));
            // const type = productType.code;
            const type = productType ? productType.code : null;

            // const productData = { client, sku, name, price, user };
            const productData = { name, type, price };
            console.log(productData)
            mutation.mutate(productData)
        } 
    }

    useEffect(()=>{   
        if (product){
            setName(product.name);
            setSku(product.sku);
            setNewPrice(product.price);
            if (product.product_type){
                setProductType(product.product_type);
                console.log("CODE", product.product_type)
            }
            else{
                setProductType(null)
            }
        }
    },[product])

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return(
        <>
        {isModalOpen && 
        <Modal className="dashboard-modal" title="Edit Product" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
            <section className="dashboard-form">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                {product &&
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <TextField className="custom-input"
                        value={sku}
                        // onChange={(e) => setAddress(e.target.value)}
                        label="SKU" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        disabled
                    />
                    {/* <Radio.Group onChange={handlePaymentMode} className="custom-input"> */}
                    {/* <Radio.Group 
                        className="custom-input"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                    >
                        <Radio value="30">Deposit</Radio>
                        <Radio value="40">Rent</Radio>
                        <Radio value="50">Service Charge</Radio>
                        <Radio value="60">Utilities</Radio>
                    </Radio.Group> */}


                    <Autocomplete
                        loading={isLoadingProductTypes}
                        value={productType}
                        onChange={(event, newValue) => {
                            console.log("NEW VALUE", newValue)
                            setProductType(newValue);}
                        }
                        options={productTypes || []}
                        // getOptionLabel={(option) => option?.name || ''}
                        getOptionLabel={(option) => option?.name || ''}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                       
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            className="custom-input"
                            fullWidth
                            label="Product Type"
                        />
                        )}
                    />
                    

                    <TextField className="custom-input"
                        value={name}
                        onChange={(e) => {
                            setNameError(false)
                            setName(e.target.value)
                            
                        }}
                        // onChange={handleChange}
                        label="Name" 
                        InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        required
                        error={nameError}
                    />
                    <NumberFormat 
                        value = {newPrice}
                        onChange={(e) => {
                            setNewPrice(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Price" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator
                        required
                    />


                </form>
                }
            </section>
        </Modal>
        }
        </>

    )

}

export default ProductEdit;

