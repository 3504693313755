
import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import { useQuery } from "@tanstack/react-query";

import { IconButton, Button } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faFileExcel} from '@fortawesome/free-solid-svg-icons';



import { SearchOutlined } from '@ant-design/icons';
import { FilterFilled } from '@ant-design/icons';



import { Dropdown, Menu, Form, Space, DatePicker, Row, Col } from 'antd';

import { Spin } from 'antd';


// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import authAxios from "../components/authAxios";

import moment from 'moment';



// import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";
import './Report.css'
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

import Loading from "../components/Loading";



const { RangePicker } = DatePicker;

const AccountStatement = () => {
    const location = useLocation();
    // const property = location.state.property;
    const account = location.state.account;
    const [reportMonth, setReportMonth] = useState(moment().format('MM'))
    const [reportYear, setReportYear] = useState(moment().format('YYYY'))
    // const [totalBalanceBf, setTotalBalanceBf] = useState(0)


    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));


    // const { data: collectionReport } = useQuery({
    //     queryKey: ['collection-report', reportMonth, reportYear],
    //     // queryFn: async () => {
    //     //     const response = await authAxios.get(`/rentals/collection-report/?property=${property.id}&month=${reportMonth}&year=${reportYear}`);
    //     //     return response.data
    //     // },
    // });

    const { data: accountStatement, isLoading, error } = useQuery({
        queryKey: ['account-statement', account.id, startDate, endDate],
        queryFn: async () => {
            const response = await authAxios.get(`/accounting/account-statement-report/${account.id}?start_date=${startDate}&end_date=${endDate}`);
            return response.data
        },
    });

    // const {loading} = isLoading
    // if (isLoading) return <Spin size="large" />;
    if (isLoading) return <Loading loading={true} />;
    if (error) return <div>Error loading account statement: {error.message}</div>;

    // const totalBalanceBf = collectionReport ? collectionReport.reduce((acc, data) => {
    //     return acc + (parseFloat(data.balance_brought_forward) || 0);
    // }, 0) : 0;

    // const totalAmountPayable = collectionReport ? collectionReport.reduce((acc, data) => {
    //     return acc + (parseFloat(data.amount_payable) || 0);
    // }, 0) : 0;

    // const totalAmountPaid = collectionReport ? collectionReport.reduce((acc, data) => {
    //     return acc + (parseFloat(data.amount_paid) || 0);
    // }, 0) : 0;

    // const totalBalance = collectionReport ? collectionReport.reduce((acc, data) => {
    //     return acc + (parseFloat(data.balance) || 0);
    // }, 0) : 0;

    // console.log("BAL",totalBalanceBf)
    const downloadXLS = () => {

        const worksheetData = accountStatement.map(data => ({
            'House No': data.house_no,
            'Tenant': data.customer,
            'Contact': data.contact,
            'Balance B/F': parseFloat(data.balance_brought_forward),
            'Amount Payable': parseFloat(data.amount_payable),
            'Amount Paid': parseFloat(data.amount_paid),
            'Balance': parseFloat(data.balance),
        })); // Add totals row at the end
    // })).concat(totalsRow); // Add totals row at the end

       
        const totalsRow = {
            // 'House No': 'TOTALS', // Adjust label if needed
            // 'Tenant': '', 
            // 'Contact': '',
            // 'Balance B/F': totalBalanceBf,
            // 'Amount Payable': totalAmountPayable,
            // 'Amount Paid': totalAmountPaid,
            // 'Balance': totalBalance,
          };

        // Add totals row to the worksheet data
        worksheetData.push(totalsRow);
        

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(worksheetData);
       

        // console.log("WS", ws)
        // console.log("TOTALS", wsTotals)


        // Append totals to the worksheet
        // XLSX.utils.sheet_add_json(ws, [wsTotals[0]], { skipHeader: true, origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Collection Report');

        XLSX.writeFile(wb, `Collection_Report_${account.name}_${reportMonth}_${reportYear}.xlsx`);
    };

    const onChange = (dates, dateStrings) => {
        if (dates) {
            // Dates were selected
            setStartDate(dateStrings[0]);
            setEndDate(dateStrings[1]);
        } else {
            // Dates were cleared
            setStartDate(null);
            setEndDate(null);
        }
      };

    const menu = (
        <Menu>
            <form className="filter-form">
                <Space direction="vertical">
                    {/* <DatePicker onChange={onChange} picker="month" placement="bottomRight" /> */}
                    <RangePicker 
                        onChange={onChange} 
                        defaultValue={[moment(startDate), moment(endDate)]}
                        format="YYYY-MM-DD"
                    />
                </Space>
            </form>
        </Menu>
      );
    const reportTitle=account.name+" Statement"
    const reportPreTitle="PERIOD "+moment(startDate).format('DD-MM-YYYY')+" TO "+moment(endDate).format('DD-MM-YYYY')


    return (
        <div className="dashboard-main collection-report">
            <section className="dashboard-content">
                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle={reportTitle} dashboardPreTitle={reportPreTitle} />
                    <div className="dashboard-content-header-nav-icons">
                        {/* <button onClick={downloadXLS}>Download XLS</button> */}
                        <IconButton onClick={downloadXLS}>
                            <FontAwesomeIcon className="menu-icon" icon={faFileExcel} />
                        </IconButton>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow>
                      
                        <Button className="menu-button-filter" variant="outlined" startIcon={<TuneIcon />}>
                            Filter
                        </Button>

                    </Dropdown>
                    </div>
                
                </div>

                <table >
                        <thead>
                        <tr>
                            <th>Reference</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th className="cell-align-right">Amount</th>
                            <th className="cell-align-right">Balance</th>
                        </tr>
                        </thead>

                        {accountStatement.transactions.map((data, index) => (
                        <tbody key={index}>
                            <tr>
                                <td>{data.reference}</td>
                                <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                <td>{data.type}</td>
                                <td>{data.description}</td>
                                <td className="cell-align-right">{Number(data.amount).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.balance).toLocaleString()}</td>
                            </tr>
                        </tbody>
                        ))}

                        <tfoot>
                          <tr>
                              <th colSpan="5">ACCOUNT BALANCE</th>
                              <th className="cell-align-right">{accountStatement.account_balance.toLocaleString()}</th>
                          </tr>
                        </tfoot>
                </table>
            </section>
        </div>
    );
};

export default AccountStatement;


