// import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import {Button} from "@mui/material";
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const PaymentView = ({payment}) => {
    // const client = localStorage.getItem('clientID');
    // const user = localStorage.getItem('userID');
    const navigate = useNavigate()


    return(
        <section className="dashboard-form">
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">

                        <TextField className="custom-input"
                            label="Transaction Reference"
                            variant="outlined" 
                            fullWidth
                            defaultValue = {payment.reference}
                            InputProps={{ readOnly: true }}
                        />
                   
                    <TextField className="custom-input"
                        label="Customer"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.account_name}
                        defaultValue = {payment.customer_name}
                        InputProps={{ readOnly: true }}
                    />

                    <div className="two-columns">
                        <NumberFormat 
                            className="custom-input"
                            fullWidth
                            label="Amount" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            // value = {transaction.amount}
                            defaultValue = {payment.amount}
                            // disabled
                            InputProps={{ readOnly: true }}

                        />
                        <TextField className="custom-input"
                            label="Currency"
                            variant="outlined" 
                            fullWidth
                            // value = {transaction.entry_type}
                            defaultValue = {payment.currency}
                            InputProps={{ readOnly: true }}
                        />
                    </div>

                    
                    <div className="two-columns">
                        <TextField className="custom-input"
                            label="Customer ID"
                            variant="outlined" 
                            fullWidth
                            defaultValue = {payment.customer_reference}
                            InputProps={{ readOnly: true }}
                        />


                        <TextField className="custom-input"
                            label="Mobile Number"
                            variant="outlined" 
                            fullWidth
                            defaultValue = {payment.customer_mobile_number}
                            InputProps={{ readOnly: true }}
                        />

                    </div>


                    

                    <div className="two-columns">

                        <TextField className="custom-input"
                            label="Date"
                            variant="outlined" 
                            fullWidth
                            // value = {transaction.date}
                            defaultValue = {payment.date}
                            InputProps={{ readOnly: true }}
                        />
                        <TextField className="custom-input"
                            label="Status"
                            variant="outlined" 
                            fullWidth
                            defaultValue = {payment.status_name}
                            InputProps={{ readOnly: true }}
                        />

                    </div>

                    <TextField className="custom-input"
                        label="Description"
                        variant="outlined" 
                        fullWidth
                        // value = {transaction.description}
                        defaultValue = {payment.description}
                        InputProps={{ readOnly: true }}

                    />
                    <TextField className="custom-input"
                        label="Memo"
                        variant="outlined" 
                        fullWidth
                        multiline
                        rows={2}
                        // value = {transaction.memo}
                        defaultValue = {payment.memo}
                        InputProps={{ readOnly: true }}
                    />
                  
                </div>
                <div className="button-border-top">
                    {/* <button className="btn btn-primary form-buttons" onClick={()=>navigate("/dashboard/transactions")}>
                        Back
                    </button> */}
                    <Button
                        onClick={()=>navigate("/dashboard/payments")}
                        variant="outlined" 
                        size="small"
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>

                </div>
             
                
            </form>

        </section>
    )
}

export default PaymentView;