import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";
import DashboardHeader from "../../components/DashboardHeader";

import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//TABS - ANT DESIGN
// import { Tabs } from "antd";

import TransactionList from "../accounting/TransactionList";
import OrderList from "../orders/OrderList";
import CustomerEdit from "./CustomerEdit";
// import Loading from "../../components/Loading";

import UserAlerts from "../../components/UserAlerts";
import OwnerEdit from "./OwnerEdit";


const Owner = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const ownerId = params.id
    const subledger_type = "Owner"

    const [removeBorder, setRemoveBorder] = useState(true);
    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    // const [dashboardPreTitle, setDashboardPreTitle] = useState("");


    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: transactions } = useQuery({
        queryKey: ['owner-ledger', page],
        queryFn: async () => {
        //   const response = await authAxios.get(`/accounting/customer-ledger/?customer=${params.id}&page=${page}`);
        //   const response = await authAxios.get(`/accounting/subledger/?account=${params.id}&page=${page}`);
          const response = await authAxios.get(`/accounting/subsidiary-ledger/?account=${ownerId}&subledger=${subledger_type}&page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    // const { data: customerOrders } = useQuery({
    //     queryKey: ['customer-orders', page],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/orders/customer-orders/?customer=${params.id}&page=${page}`);
    //       setPageCount(response.data.page_count)
    //       return response.data.results
    //     },
    // });

    const { data: owner } = useQuery({
        queryKey: ['owner', ownerId],

        queryFn: async () => {
          const response = await authAxios.get(`rentals/owners/${ownerId}`);
          return response.data
        },
    });

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    return (
        <div className="dashboard-main">
            {owner &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">
                    {/* <DashboardHeader dashboardTitle={customer.name} dashboardPreTitle="Tenant" removeBorder={removeBorder} /> */}

                    <DashboardHeader dashboardTitle={owner.name} dashboardPreTitle="Owner" removeBorder={removeBorder} />
                    <div className="dashboard-content-header-nav-icons">
                        <Tooltip title="Statement">
                            <IconButton 
                                // onClick={() => navigate('/dashboard/customer-statement/',{state:{reference:params.id}})}
                                onClick={() => navigate('/dashboard/statement/',{state:{reference:params.id}})}
                            > 
                                {/* <FontAwesomeIcon icon={faFilePdf} />  */}
                                <DescriptionIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>


              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="customer-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <OwnerEdit 
                            owner={owner}
                            setError={setError}
                            // contact={contact}
                            // countries={countries}
                        />
                    </Tab>

                    <Tab eventKey="statement" title="Ledger">
                        {clientProfile &&
                        <div className="dashboard-content-subheader">
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Debits
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {owner.total_debits.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Credits
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {owner.total_credits.toLocaleString()}

                                </div>
                            </div>
                            <div className='dashboard-card'>
                                <div className="dashboard-card-pretitle">
                                    Balance
                                </div>
                                <div className="dashboard-card-text">
                                    {clientProfile.currency_code} {owner.balance.toLocaleString()}

                                </div>
                            </div>

                        </div>
                        }
                        <TransactionList 
                            module="CRM" 
                            transactions={transactions} 
                            // fetchTransactions={fetchTransactions} 
                            handlePageChange={handlePageChange} 
                            page={page} 
                            pagecount={pagecount}
                        />
                    </Tab>
                    <Tab eventKey="properties" title="Properties" disabled={true}>
                       
                    </Tab>

                </Tabs> 


            </section>
            }

        </div>
    );
  };
  
  export default Owner;