import React from "react";

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";


import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import NumberFormat from "react-number-format";
import moment from 'moment';


import {Radio } from "antd";


// IMAGE UPLOAD ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";


function UploadPropertyData() {
  const location = useLocation();

  const {response, error, fetchData}  = useAxios();

  useEffect(() => {
      if (response.status === 201){
          navigate('/dashboard/customers/');
      }    
  }, [response.status]); 

  const navigate = useNavigate();

  const [dt, setDt] = useState(null);
  const [dt1, setDt1] = useState(null);
  const [dt2, setDt2] = useState(moment())
  const [dt3, setDt3] = useState(moment())


  const [file, setFile] = useState([]);
  const user = localStorage.getItem('userID');
  const client = localStorage.getItem('clientID');
  // const [settlementId, setSettlementId] = useState('');

  const property = location.state.property;

  const [uploadType, setUploadType] = useState('');


  const [billingCycle, setBillingCycle] = useState('');
  const [billingInterval, setBillingInterval] = useState(1);

  const { data: billingCycles } = useQuery({
    queryKey: ['billingCycles'],
    queryFn: async () => {
      const response = await authAxios.get("/subscriptions/billing-cycles");
      return response.data

    },
  });


  // IMAGE UPLOAD ANT DESIGN
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);

  const getBase64 = (img, callback) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
      // setFileList([...fileList, file]);

      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
    
      const isLt2M = file.size / 1024 / 1024 < 2;
    
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
    
      return isJpgOrPng && isLt2M;
    };
  
  // const handleUpload = (info) => {

  const handleUpload = (info) => {

      // setFiles(fileList)
      setFile(info.file)

      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
  
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
      }
  };

  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div 
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
  );


    const handleSubmit = (e) => {
        e.preventDefault();

        if (property) {
            const property_id = property.id

            if (uploadType === '10'){

                const start_date = moment(dt).format("YYYY-MM-DD")
                const end_date = moment(dt1).format("YYYY-MM-DD")

                let next_billing_time = moment(dt2).format("YYYY-MM-DD")
                const currentDate = moment();
                if (moment(dt2).isAfter(currentDate, 'day')) {
                    next_billing_time = moment(dt2)
                        .hours(12)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .format("YYYY-MM-DD HH:mm:ss");
                }

                const billing_cycle = billingCycle
                const billing_interval = billingInterval

                // const customer = { type, client, name, address, country, email, website, telephone, id_type, id_number, gender, birth_date, user, tag, id_files };
                const upload = { client, property_id, start_date, end_date, next_billing_time, billing_cycle, billing_interval, user, file };

                console.log(upload)

                fetchData({
                    url: '/rentals/upload-property-data/',
                    method: 'post',
                    data: upload,
                    headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
                })
            }

            if (uploadType === '20'){

                const posting_date = moment(dt3).format("YYYY-MM-DD")

                const upload = { client, property_id, posting_date, user, file };

                console.log(upload)

                fetchData({
                    url: '/rentals/upload-balance-data/',
                    method: 'post',
                    data: upload,
                    headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
                })

            }

            if (uploadType === '30'){

                const posting_date = moment(dt3).format("YYYY-MM-DD")

                const upload = { client, property_id, posting_date, user, file };

                console.log(upload)

                fetchData({
                    url: '/rentals/upload-invoice-data/',
                    method: 'post',
                    data: upload,
                    headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
                })

            }
      } 
  
  }

    return(
        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
            {/* <section className="dashboard-form-light"> */}
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Upload Data" dashboardPreTitle="Properties" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Radio.Group 
                        onChange={(e) => setUploadType(e.target.value)} 
                        className="custom-input"
                    >
                        <Radio value="10">New</Radio>
                        <Radio value="20">Balances</Radio>
                        <Radio value="30">Invoices</Radio>

                    </Radio.Group>

                    <TextField className="custom-input"
                        label="Name"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {property.name}
                        InputProps={{ readOnly: true }}
                    />

                    {uploadType === '10' && (
                        <>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Lease Start Date"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));

                            }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Lease End Date"
                            value={dt1}
                            onChange={(newDate) => {
                                setDt1(moment(newDate).format("YYYY-MM-DD"));

                            }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="First Billing Date"
                            value={dt2}
                            onChange={(newDate) => {
                                setDt2(moment(newDate).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>

                    <Autocomplete
                        onChange={(event, newValue) => {
                            setBillingCycle(newValue.id);
                        }}
                        options={billingCycles || []}
                        getOptionLabel={(option) => { return option ? option.name : '' }} // Ensure option is not null or undefined
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Billing Cycle" />}
                        value={billingCycle !== null && billingCycles? billingCycles.find((option) => option.id === billingCycle) : null }
                    />

                    <NumberFormat 
                        onChange={(e) => setBillingInterval(e.target.value)} 
                        className="custom-input"
                        fullWidth
                        label="Billing Interval" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator={true} 
                        required
                        // error={amtError}
                    />
                    </>
                    )}

                    {uploadType !== '10' && (

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Posting Date"
                            value={dt3}
                            maxDate={moment()}
                            onChange={(newDate) => {
                                setDt3(moment(newDate).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                    )}

                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader custom-upload-card custom-input"
                        beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                        onChange={handleUpload}
                        // maxCount={2}
                        maxCount={1}

                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Submit
                        </button>
                    </div>


                </form>

            </section>
            </section> 

        </div>
    )

}

export default UploadPropertyData;
