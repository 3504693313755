import { useState } from 'react';

import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
// import LoginSharp from '@material-ui/icons/LoginSharp';
// import LogoutSharp from '@material-ui/icons/LogoutSharp';

const EllipsisMenu = ({ items }) => {
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {items.map((item, index) => (
                    <MenuItem key={index} className="styled-threedots-dropdown">
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText onClick={item.onClick}>
                            {item.label}
                        </ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default EllipsisMenu;