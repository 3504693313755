import React from "react";
import { useNavigate } from "react-router-dom";


function Cta() {
    const navigate = useNavigate();

    return (
        <section className="cta">

            <div className="cta-text">
                {/* From managing tenants, finances and occupancy, our technology is designed to make running your property management business easier and more efficient. Sign up for a Demo today and experience the power of our smart technology. */}
                From managing tenants, finances and occupancy, our technology is designed to make running your property management business frictionless and efficient. Sign up for a demo today and experience our smart technology.
                {/* Don't miss out on this opportunity to optimizing your business operations and grow! */}
            </div>
            <div className="cta-button">
              <button className="btn btn-outline-primary" onClick={() => navigate("/contact")}>Request Demo</button>

            </div>
        </section>

    ) 
}
    
export default Cta