import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import authAxios from "../../components/authAxios";
import { useNavigate } from "react-router-dom";

import Chip from '@mui/material/Chip';
import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { VisibilityOutlined } from "@mui/icons-material";
// import { BorderColorIcon } from "@mui/icons-material";
// import { BorderColorSharpIcon } from "@mui/icons-material";

import BorderColorSharpIcon from '@mui/icons-material/BorderColor';

import { Tabs } from "antd";
import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import EllipsisMenu from "../../components/EllipsisMenu";
import DashboardHeader from "../../components/DashboardHeader";
import TenantNew from "../rentals/TenantNew";
import CustomSearchBar from "../../components/CustomSearchBar";
import TenantEdit from "../rentals/TenantEdit";

import './Crm.css'



const Customers = () => {
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [dashboardTitle, setDashboardTitle] = useState("");
    const [tag, setTag] = useState("");
    const [status, setStatus] = useState("");

    const [searchText, setSearchText] = useState('');
    const [property, setProperty] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);


    const { TabPane } = Tabs;
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: clientProfile, refetch } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);

        //   console.log("RES",response.data)
        //   if (response.data.client_type==="030"){
        //     setDashboardTitle("Residents")
        //     setTag("RESIDENT")
        //   }
        //   else{
        //     setDashboardTitle("Tenants")
        //     setTag("TENANT")
        //   }
          return response.data

        },
        // staleTime: 0, // Always consider the data stale and refetch on mount
    });

    // const { data: customers, ...rest  } = useQuery({

    const { data: customers  } = useQuery({
        queryKey: ['customers', status, page, property, searchText],
        // queryKey: ['customers', page, searchText],

        queryFn: async () => {
        //   const response = await authAxios.get(`/crm/customers/?status=${status}&page=${page}&search=${searchText}`);
          const response = await authAxios.get(`/crm/customers/?status=${status}&property=${property}&page=${page}&search=${searchText}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        keepPreviousData : true,
    });

 


    const onChange = (key) => {
        setStatus(key)
        if (key==="000"){
            setStatus("")
        }
        if (key==="020"){
            setProperty("")
        }
        // else{
        //     setStatus(key)
        // }

      };

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    
    
    useEffect(()=>{   
        // Trigger a manual refetch for client profile:
        // refetch()  

        if (clientProfile && clientProfile.client_type==="030"){
            setDashboardTitle("Residents")
            setTag("RESIDENT")

        }
        else{
            setDashboardTitle("Tenants")
            setTag("TENANT")

        }   
    },[clientProfile])

    // console.log("T1",tag)
    // console.log("CP",clientProfile)



    return (
            <div className="dashboard-main">                
               
                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">

                        {/* <DashboardHeader dashboardTitle={dashboardTitle} dashboardPreTitle="Rentals" removeBorder={true}/> */}
                        <DashboardHeader dashboardTitle={dashboardTitle} dashboardPreTitle="CRM" removeBorder={true} />

                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>


                    <Tabs defaultActiveKey="All" onChange={onChange}>
                        <TabPane tab="All" key="000">
                            <FilterCustomers 
                                // status="" 
                                status={status} 
                                clientProfile={clientProfile} 
                                customers={customers}      
                                property={property}                         
                                setProperty={setProperty}
                            />
                        </TabPane>
                        <TabPane tab="Active" key="010">
                            <FilterCustomers 
                                // status="010" 
                                status={status}
                                clientProfile={clientProfile}
                                customers={customers}
                                property={property}
                                setProperty={setProperty}

                            />                 
                        </TabPane>
                        {/* <TabPane tab="Inactive" key="3"> */}

                        <TabPane tab="Inactive" key="020">
                            <FilterCustomers 
                                // status="020" 
                                status={status}
                                clientProfile={clientProfile} 
                                customers={customers}
                            />
                        </TabPane >
                    </Tabs>


                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />
                    <TenantNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        tag={tag}
                    />

                  
                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                            <AddIcon />
                    </Fab>
                   

                </section>
            </div>

    )
}
export default Customers


// const FilterCustomers = ({ status, clientProfile }) => {

const FilterCustomers = ({ status, customers, clientProfile, setProperty, property }) => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customer, setCustomer] = useState("")

    const { data: properties } = useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/properties/`);
          return response.data.results
        },
    });

    const menuItems = (customer) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                navigate(`/dashboard/customer/${customer.id}`)
            }
        },
        {
            label: 'Edit',
            icon: <BorderColorSharpIcon />,
            onClick: () => {
                setCustomer(customer)
                setIsModalOpen(true)
                // Handle view action
                // navigate(`/dashboard/customer/${customer.id}`)
            }
        },
    ];

    const handleChipClick = (e) => {
        if (e.id){
            // console.log('You clicked the Chip.', e);
            setProperty(e.id)
        }
        else{
            setProperty("")
        }
      };



    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

  
    return (
    <div>
        {clientProfile &&
            <div className="filter-customers">
                {properties && properties.length>1 && status !== "020" &&
                <div className="chip-list-container">
                    <div className="chip-list">
                        <Chip 
                            className="custom-chip" 
                            sx={{width:75}} 
                            onClick={()=>handleChipClick("All")} 
                            label="All" 
                            variant="outlined"
                            // variant={property === "" ? "filled" : "outlined"}
                            color={property === "" ? "primary" : "default"}

                        />
                        {properties.map(myProperty => (
                            <Chip 
                                className="custom-chip" 
                                key={myProperty.id} 
                                onClick={()=>handleChipClick(myProperty)} 
                                label={myProperty.name} 
                                variant="outlined"
                                // variant={property === myProperty.id ? "filled" : "outlined"}
                                color={property === myProperty.id ? "primary" : "default"}

                            />
                        ))
                        }
                    </div>
                </div>

                }

                <table className="styled-table">
                <thead>
                    <tr >
                        <th>ID</th>
                        <th>Name</th>
                        <th>Telephone</th>
                        <th>Date Acquired</th>
                        {!status &&
                            <th>Status</th>
                        }
                        {status === "010" &&
                            <th>Property</th>
                        }
                        <th className="cell-align-right">Balance</th>
                        <th></th>
                    </tr>
                </thead>

                {customers && customers.map(customer => (

                    <tbody key={customer.id}>
                        <tr>
                            <td>{customer.customer_id}</td>
                            <td>{customer.name}</td>
                            <td>{customer.telephone}</td>
                            <td>{convertDateTime(customer.created_at)}</td>
                            {status==="010" &&
                                <td>{customer.property}</td>
                            }
                            {!status &&
                                <td><Pillchip label={customer.status_name} /></td>
                            }
                        
                            {clientProfile && clientProfile.currency_code? 
                                <td className="cell-align-right">{Number(customer.balance).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(customer.balance).toLocaleString('en-UK')}</td>
                            }
                            <td className='styled-table-menu'>
                                <EllipsisMenu items={menuItems(customer)} />
                            </td>
                        </tr>
                    </tbody>
                ))}


                </table>

                <TenantEdit
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    customer={customer}
                    // tag={tag}
                />
            </div>

        }

    </div>
    );
  };


