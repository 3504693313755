// import React from "react";

import { useState, useRef, useEffect } from "react";
import authAxios from "../../components/authAxios";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';


import { useNavigate, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";
import CustomSwitch from "../../components/CustomSwitch";

import { TreeSelect } from "antd";


const ProductView = ({product, setError}) => {
    const params = useParams()
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    // const { data: categories } = useQuery({
    //     queryKey: ['categories-tree'],
    //     queryFn: async () => {
    //         const response = await authAxios.get("/products/categories-tree");
    //         return transformCategoriesTree(response.data)
    //     },
    // });

    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');
    // const [name, setName] = useState(product.name);
    const [sku, setSku] = useState(product.sku);
    const [newPrice, setNewPrice] = useState(product.price);
    // const [category, setCategory] = useState(product.categories.map(category => category.toString())); // Convert IDs to strings as required by TreeSelect

    // const [category, setCategory] = useState(product.categories);

    const [memo, setMemo] = useState(product.memo);
    const [checked, setChecked] = useState(product.is_active);

    const [nameError, setNameError] = useState(false)

    return(
        <section className="dashboard-form">
           
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        // value={ sku || ''}
                        defaultValue = {product.sku}

                        // onChange={(e) => {
                        //     setSku(e.target.value)
                        // }}
                        label="SKU" 
                        // InputLabelProps={{ shrink: true }}
                        variant="outlined" 
                        fullWidth
                        InputProps={{ readOnly: true }}

                        // required
                        // error={nameError}
                    />

                    <TextField className="custom-input"
                        // value={ name || ''}
                        defaultValue = {product.name}
                        // onChange={(e) => {
                        //     setNameError(false)
                        //     setName(e.target.value)
                        // }}
                        // InputLabelProps={{ shrink: true }}
                        label="Name" 
                        variant="outlined" 
                        fullWidth
                        InputProps={{ readOnly: true }}

                        // required
                        // error={nameError}
                    />
                

                    {/* <NumberFormat 
                        value = {newPrice}
                        onChange={(e) => {
                            setNewPrice(e.target.value)
                        }}
                        className="custom-input"
                        fullWidth
                        label="Price" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator
                        readOnly
                        required
                    /> */}

                    <TextField className="custom-input"
                        label="Status"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {product.status_name}
                        InputProps={{ readOnly: true }}
                    />
                </div>


                <div className="grouped-fields-light">


                    <TextField className="custom-input"
                        // value={memo ?? ''}
                        defaultValue = {product.memo}

                        // onChange={(e) => setMemo(e.target.value)}
                        label="Memo"
                        // InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        multiline
                        rows={3}
                        fullWidth
                        InputProps={{ readOnly: true }}
                    />
                </div>

                {/* <div className="custom-switch-container custom-switch-container-white">
                        <CustomSwitch checked={checked} setChecked={setChecked}/>
                        <div className="custom-switch-label">
                            Active
                        </div>
                    </div> */}
                
                <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </section>
    )

}

export default ProductView;