// import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios"

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

// import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

import { format } from 'date-fns';
import MeterReadingNew from "./MeterReadingNew";
import Pillchip from "../../components/Pillchip";

// import ProductNew from "./ProductNew";

function MeterReadings({meter, meterReadings}) {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { data, fetchData}  = useAxios();   
    const [isModalOpen, setIsModalOpen] = useState(false);


    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });


    // const { data: products } = useQuery({
    //     queryKey: ['skus', page],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/products/skus/?page=${page}`);
    //       setPageCount(response.data.page_count)
    //       return response.data.results
    //     },
    // });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/product/",
        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/customeredit/",
        // }
    ];


    return (
        <>          
        <table className="styled-table">
            <thead>
                <tr >
                    <th>Id</th>
                    <th>Date Posted</th>
                    <th>Reading Date</th>
                    <th>Current</th>
                    <th>Previous</th>
                    <th>Consumption</th>
                    <th className="cell-align-right">Bill</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>

            {meterReadings && meterReadings.map(reading => (

                <tbody key={reading.id}>
                    <tr>
                        <td>{reading.id}</td>
                        <td>{convertDateTime(reading.created_at)}</td>
                        <td>{convertDateTime(reading.date)}</td>

                        <td>{reading.current_reading}</td>
                        <td>{reading.last_reading}</td>
                        <td>{reading.consumption}</td>
                        {clientProfile && clientProfile.currency_code? 
                            <td className="cell-align-right">{Number(reading.amount).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                            :
                            <td>{Number(reading.amount).toLocaleString('en-UK')}</td>
                        }
                        <td><Pillchip label={reading.status_name} /></td>
                        <td className='styled-table-menu'>
                            <ThreeDotsMenu data={reading} threeSubMenu={threeSubMenu} />
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>

        <MeterReadingNew 
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            meter={meter}
        />
        
        <Pagination 
            className="custom-mui-pagination"
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        {/* <Link to="/dashboard/product-new" className="nav-link">
            <Fab className="fab" color="primary" aria-label="add">
                <AddIcon />
            </Fab>
        </Link> */}
        <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
            setIsModalOpen(true)}}
            >
            <AddIcon />
        </Fab>

    </>            

    )
}
export default MeterReadings