
import { useState, createContext } from 'react';

//import logo from './logo.svg';
// import { useQuery } from '@tanstack/react-query';
// import authAxios from './components/authAxios';
// import jwt from 'jwt-decode';

import 'bootstrap/dist/css/bootstrap.css';
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import './style.css'

// import userType from './components/useUserType';

// import useToken from './components/useToken';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material';

import {defaults} from "chart.js"


// import PublicRoutes from './components/AppRoutes';
// import AdminRoutes from './admin/AdminRoutes';
// import ClientRoutes from './clients/ClientRoutes';
// import AppRoutes from './components/AppRoutes';
// import ClientRoutes from './components/ClientRoutes';
import AppRoutes from './components/AppRoutes';

//Override MUI global settings
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  // overrides: {
  //   MuiButton: {
  //     root: {
  //       textTransform : 'non'
  //     }
  //   }
  // }
})

export const UserContext = createContext();


function App() {
  //SET DEFAULT FONT FOR CHART JS
  defaults.font.family = 'Poppins';


  // const location = useLocation();
  // const navigate = useNavigate();

  // const [pageSize, setPageSize] = useState(1000);
  const pageSize = 1000
  const page_size = 1000


  // const token = localStorage.getItem('accessToken');

  const userType = localStorage.getItem('userType');

  console.log("USER TYPE",userType)

  // FETCH CLIENT PROFILE
  const [clientProfile, setClientProfile] = useState(null);
 
  return (
    <UserContext.Provider value={{ pageSize, page_size, clientProfile, setClientProfile }}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <AppRoutes />
        </StyledEngineProvider>
      </ThemeProvider>
    </UserContext.Provider>
    );
}

export default App;
