import { Link, useNavigate } from "react-router-dom";
import logo from '../assets/koddi-logo.svg'

function Header() {
    const navigate = useNavigate();

    return (
        <header>
            <nav className="navbar">
                <div className="navbar-brand">
                    <Link to="/">
                        <img src={logo} alt="brand logo"/>  
                    </Link>
                </div>

                <div className="login-button">
                    <button 
                        className="login-button"
                        onClick={()=>{
                        // navigate('/dashboard/stats/')
                        // navigate('/dashboard/business-intelligence/')
                        navigate('/dashboard/')


                        }}

                    >
                        Login
                    </button>

                </div>
            </nav>
        </header>
    ) 
}

export default Header