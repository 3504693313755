import { useState, useContext } from "react";
import { useQuery } from '@tanstack/react-query';

import useAxios from "./useAxios";
import authAxios from "./authAxios";
import {UserContext} from "../App"

// import Layout from "./Layout";
import DashboardHeader from "./DashboardHeader";
import 'chart.js/auto'
import { Bar, Doughnut } from "react-chartjs-2";
import moment from 'moment';
import Loading from "./Loading";


function Dashboard() {

    const {page_size} = useContext(UserContext);
    const { loading } = useAxios()


    // FETCH CLIENT PROFILE
    // const clientProfileID = localStorage.getItem('clientProfile');
    // const { data: clientprofile } = useAxios({
    //     url: `/clientprofiles/${clientProfileID}`,
    //     method: 'get'
    // })  

    const {clientProfile} = useContext(UserContext);



    const [customerCount, setCustomerCount] = useState(0);
    const [occupancy, setOccupancy] = useState(0.00);
    // const [debtors, setDebtors] = useState(0);
    // const client = localStorage.getItem('clientID');

    const { data: dashboard } = useQuery({
        queryKey: ['dashboard'],
        queryFn: async () => {
            const response = await authAxios.get(`/bi/dashboard/${localStorage.getItem('clientProfile')}`);
            return response.data;
        },
    });


    const { data: kpis } = useQuery({
        queryKey: ['kpis'],
        queryFn: async () => {
          const response = await authAxios.get(`/bi/kpis/?page_size=${page_size}`);
          const customerCountKpi = response.data.find(kpi => kpi.type_code === "00100")
          setCustomerCount(customerCountKpi.value)
          const occupancyKpi = response.data.find(kpi => kpi.type_code === "00200")
          setOccupancy(occupancyKpi.value)
          return response.data;
        },
    });



    const [expenseData, setExpenseData] = useState({})
    const [viewChart, setViewChart] = useState(false)


    const { data: accounts } = useQuery({
        queryKey: ['accounts'],
        queryFn: async () => {
            const response = await authAxios.get(`/accounting/accounts?page_size=${page_size}`);
            setViewChart(true)
            // createChart()
            const expaccts = Array.from(response.data.results).filter(account => account.type_code === "20100")
            setExpenseData({
                labels: expaccts.map((expacct) => expacct.name),
                datasets: [
                {
                    label: "Expenses",
                    data: expaccts.map((expacct) => expacct.balance),
                    backgroundColor: [
                        "#ecf0f1",
                        "#50AF95",
                        // "#d81159", //Added
                        "#ffafcc", //Added
                        "#3a86ff", //Added
                        "#8338ec", //Added
                        "#38b000", //Added
    
    
                        "#f3ba2f",
                        "#2a71d0",
                    ],
                },
                ],
            });
            return response.data.results;
        },
    });

    

    // FETCH TRANSACTION 
    // const { data: transactions } = useAxios({
    //     url: `/accounting/transactions?page_size=${page_size}`,
    //     method: 'get'
    // })

    // const { data: transactions } = useQuery({
    //     queryKey: ['transactions'],
    //     queryFn: async () => {
    //         const response = await authAxios.get("/accounting/transactions");
    //         return response.data;
    //     },
    // });

    // FETCH ACCOUNT BALANCES 
    // const { data: monthlyRevenue } = useAxios({
    //     url: "/accounting/monthly-revenue",
    //     method: 'get'
    // })

    const [revenueData, setRevenueData] = useState({})
    const [viewBarChart, setViewBarChart] = useState(false)


    const { data: monthlyRevenue } = useQuery({
        queryKey: ['monthlyRevenue'],
        queryFn: async () => {
            const response = await authAxios.get("/accounting/monthly-revenue");

            setViewBarChart(true)
            // createBarChart()
            // const createBarChart = () => {
                // const revenues = Array.from(transactions).filter(transaction => (transaction.type === invoice.id && transaction.entry_type === "C"))
                // const revenues = AccountBalance.filter(period => (period.year === invoice.id && transaction.entry_type === "C"))
        
                setRevenueData({
                    // labels: monthlyRevenue.map((period) => moment().month(period.month-1).format("MMM")),
                    labels: response.data.map((period) => moment().month(period.month-1).format("MMM")),

                    datasets: [
                    {
                        label: "Revenue",
                        // data: monthlyRevenue.map((period) => period.amount),
                        data: response.data.map((period) => period.amount),
                        backgroundColor: [
                            "#ecf0f1",
                            "#50AF95",
                            "#d81159", //Added
                            "#f3ba2f",
                            "#2a71d0",
                        ],
                    },
                    ],
                });
            // }
            return response.data;
        },
    });




    

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 20,
            // padding: {
            //     // right: 5
            // }
        },
        plugins: {
            legend: {
                // display: false,
                position: 'right',
                labels: {
                    font: {
                        size: 11,
                    }
                //     color: 'rgb(255, 99, 132)'                   
                }
            }
        }
    }

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 20,
            // padding: {
            //     // right: 5
            // }
        },
        plugins: {
            legend: {
                display: false,
                // position: 'right',
                labels: {
                    font: {
                        size: 8,
                    }
                //     color: 'rgb(255, 99, 132)'                   
                }
            }
        },
        scales: {
            x: {
              grid: {
                display: false,
              },
            },
      
            y: {
              grid: {
                // display: false,
                color: 'rgba(217,143,7,0.1)',
              },
              display: false,
            },
        }
    }




    return (
            <div className="dashboard-main">
                
                
                <section className="dashboard-content">

                    <DashboardHeader dashboardTitle="Insights" dashboardPreTitle="Dashboard" />

                    {loading? <Loading loading={loading}/>
                    :
                    <>

                    <div className="dashboard-content-subheader" id="dashboard-charts">


                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Revenue
                            </div>
                            {viewBarChart && 

                            <div className="dashboard-card-chart">
                                <Bar data={revenueData} options={barChartOptions}/>
                            </div>
                            }

                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Expenses
                            </div>

                            { viewChart && 
                                <div className="dashboard-card-chart" >

                                {/* <div className="dashboard-card-text" > */}
                                    <Doughnut 
                                        data={expenseData} 
                                        options={chartOptions}/>
                                </div>

                            }
                        </div>
                    </div>

                    <div className="dashboard-content-subheader" id="dashboard-landing">
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Properties
                            </div>
                            <div className="dashboard-card-text">
                                {dashboard && Math.round(dashboard.property_count).toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Tenants
                            </div>
                            <div className="dashboard-card-text">
                                {Math.round(customerCount).toLocaleString()}
                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Occupancy
                            </div>
                            <div className="dashboard-card-text">
                                {/* {occupancy} */}
                                <td>{Number(occupancy).toLocaleString('en-UK', { style: 'percent', minimumFractionDigits: 2 })}</td>

                            </div>
                        </div>
                      
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Creditors
                            </div>
                            <div className="dashboard-card-text">
                                {/* {clientProfile && clientProfile.currency_code} {sumInventory.toLocaleString()} */}
                                {clientProfile && clientProfile.currency_code} {dashboard && dashboard.creditors.toLocaleString()}

                            </div>
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Debtors
                            </div>
                            <div className="dashboard-card-text">
                                {clientProfile && clientProfile.currency_code} {dashboard && dashboard.debtors.toLocaleString()}
                            </div>
                            
                        </div>
                        <div className='dashboard-card'>
                            <div className="dashboard-card-pretitle">
                                Bank/Cash
                            </div>
                            <div className="dashboard-card-text">
                                {clientProfile && clientProfile.currency_code} {dashboard && dashboard.bank_cash_balance.toLocaleString()}
                            </div>
                            
                        </div>
                        

                    </div>

                    {/* <Leads leads={currentPosts} loading={loading}/> */}
                    {/* <Leads leads={leads} loading={loading}/> */}
                    </>
                    }
                </section>
            </div>

    ) 

}

export default Dashboard