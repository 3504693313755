// import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import {Button} from "@mui/material";


const PriceListView = ({priceList}) => {
    const navigate = useNavigate()


    return(
        <>
        <section className="dashboard-form">
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="Name"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceList.name}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Date Created"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceList.created_at}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField className="custom-input"
                        label="Status"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceList.status_name}
                        InputProps={{ readOnly: true }}
                    />
                </div>

                
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        label="Memo"
                        variant="outlined" 
                        fullWidth
                        multiline
                        rows={2}
                        defaultValue = {priceList.memo}
                        InputProps={{ readOnly: true }}
                    />
                  
                </div>
              
                <br/>
                <br/>

            </form>

        </section>
        </>

    )
}

export default PriceListView;