import {Routes, Route} from "react-router-dom";
import AdminDashboard from "./AdminDashboard";


// PRODUCTS
import Products from '../features/products/Products';
import ProductNew from '../features/products/ProductNew'
import Product from '../features/products/Product';
import PriceLists from '../features/products/PriceLists';
import PriceList from '../features/products/PriceList';
import PriceListItem from '../features/products/PriceListItem';


// ACCOUNTING
import Accounts from '../features/accounting/Accounts';
import AccountNew from '../features/accounting/AccountNew';
import Transactions from '../features/accounting/Transactions';
// import TransactionNew from '../features/accounting/TransactionNew'
import TransactionNew from "./accounting/TransactionNew";
// import Confirmed from "../components/Confirmed";
import Confirmed from "./Confirmed";
import Account from '../features/accounting/Account';
import Transaction from '../features/accounting/Transaction';
import Payments from '../features/accounting/Payments';
import Payment from '../features/accounting/Payment';

// REPORTS
import MonthlyReport from '../reports/MonthlyReport';
// import InvoiceReport from '../reports/InvoiceReport';
import InvoiceReport from "./reports/InvoiceReport"; //Use the Admin Invoice
import IncomeStatement from '../reports/IncomeStatement';
import BalanceSheet from '../reports/BalanceSheet';
import Statement from '../reports/Statement';
import CustomerStatement from '../reports/CustomerStatement';
import CollectionReport from '../reports/CollectionReport';


const AdminRoutes = () => {
    return (
    <Routes>
        {/* <Route path="" element={ <AdminDashboard/> } index={true} />  */}
        {/* <Route path="" element={ <AdminDashboard/> } />  */}
        <Route path="business-intelligence" element={ <AdminDashboard/> } /> 
        <Route path="stats" element={ <AdminDashboard/> } />


        <Route path="products" element={ <Products/> } />  
        <Route path="product/:id" element={ <Product/> } />  
        <Route path="price-lists" element={ <PriceLists/> } />
        <Route path="price-list/:id" element={ <PriceList/> } />  
        <Route path="price-list-item/:id" element={ <PriceListItem/> } />  
        <Route path="product-new" element={ <ProductNew/> } /> 


        <Route path="accounts" element={ <Accounts/> } />  
        <Route path="account/:id" element={ <Account/> } />  
        <Route path="accountnew" element={ <AccountNew/> } />  
        <Route path="payments" element={ <Payments/> } />  
        <Route path="payment/:id" element={ <Payment/> } />  
        <Route path="transactions" element={ <Transactions/> } />  {/* To be refactored with General Ledger */}
        <Route path="general-ledger" element={ <Transactions/> } />  
        <Route path="transaction/:id" element={ <Transaction/> } />  
        <Route path="transactionnew" element={ <TransactionNew/> } />  
        <Route path="confirmed" element={ <Confirmed/> } />  
        <Route path="monthlyreport" element={ <MonthlyReport/> } />  
        <Route path="invoicereport" element={ <InvoiceReport/> } />  
        <Route path="customer-statement" element={ <CustomerStatement/> } />  {/*Re factored to "statement"*/}
        <Route path="statement" element={ <Statement/> } />  


        <Route path="income-statement" element={ <IncomeStatement/> } />  
        <Route path="balance-sheet" element={ <BalanceSheet/> } />  
        <Route path="collection-report" element={ <CollectionReport/> } />  
    </Routes>
    )
}
export default AdminRoutes





