import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import Pagination from '@mui/material/Pagination';
import DashboardHeader from "../../components/DashboardHeader";
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
// import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { VisibilityOutlined } from "@mui/icons-material";
import BorderColorSharpIcon from '@mui/icons-material/BorderColor';



import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import PropertyNew from "./PropertyNew";
import PropertyEdit from "./PropertyEdit";
import EllipsisMenu from "../../components/EllipsisMenu";
import CustomSearchBar from "../../components/CustomSearchBar";


const Properties = () => {
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');
    const [property, setProperty] = useState("")


    const { data: properties } = useQuery({
        // queryKey: ['properties', page],
        queryKey: ['properties', page, searchText],

        queryFn: async () => {
        //   const response = await authAxios.get(`/rentals/properties/?page=${page}`);
          const response = await authAxios.get(`/rentals/properties/?page=${page}&search=${searchText}`);
        //   const response = await authAxios.get(`/rentals/properties/?page=${page}&?search=${searchText}`);


          setPageCount(response.data.page_count)
          return response.data.results
        },
    });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    const menuItems = (property) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                navigate(`/dashboard/property/${property.id}`)
            }
        },
        {
            label: 'Edit',
            icon: <BorderColorSharpIcon />,
            onClick: () => {
                setProperty(property)
                // setIsModalOpen(true)
                setIsEditModalOpen(true)

            }
        },
    ];


    return (
        // <Layout>

            <div className="dashboard-main">

                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">
                    
                        <DashboardHeader dashboardTitle="Properties" dashboardPreTitle="Rentals" />
                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>

                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                {/* <th>Type</th> */}
                                <th>Units</th>
                                <th>Occupied</th>
                                <th>Occupancy</th>
                                <th></th>
                            </tr>
                        </thead>

                        {properties && properties.map(property => (

                            <tbody key={property.id}>
                                <tr>
                                    <td>{property.id}</td>
                                    <td>{property.name}</td>
                                    <td>{property.address}</td>
                                    {/* <td>{property.type_name}</td> */}
                                    <td>{property.units}</td>
                                    <td>{property.occupied_units}</td>

                                    {/* <td>{Number(property.occupancy).toLocaleString('en-UK', { style: 'percent', minimumFractionDigits: 2 })}</td> */}
                                    <td>{Number(property.occupancy_rate).toLocaleString('en-UK', { style: 'percent', minimumFractionDigits: 2 })}</td>

                                    <td className='styled-table-menu'>
                                    <EllipsisMenu items={menuItems(property)} />
                                </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <PropertyNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        // tag={tag}
                        // property={property.id}
                    />

                    <PropertyEdit 
                        property={property}
                        isModalOpen={isEditModalOpen}
                        setIsModalOpen={setIsEditModalOpen}
                    />

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />


                    {/* <Link to="/dashboard/add-property" state={{ tag: "LOCATION" }} className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}

                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                    

                </section>
            </div>

    )
}
export default Properties