// import React from "react"
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';

// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";


// import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
// import { Card, CardContent, TablePagination } from "@mui/material";
import { Fab, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Card, CardContent } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive'

import { format } from 'date-fns';


import ThreeDotsMenu from "../../components/ThreeDotsMenu";


// const TransactionList = ({module, transactions, fetchTransactions, handlePageChange, page, pagecount}) => {
const TransactionList = ({module, transactions, order, handlePageChange, page, pagecount, setIsModalOpen}) => {
    // useCountRenders()

    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [showAccount, setShowAccount] = useState(true)
    const [showDescription, setShowDescription] = useState(false)
    const [showQuantity, setShowQuantity] = useState(false)

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const threeSubMenu = [
        // {
        //     id: 1,
        //     menu: "PDF",
        //     url: "/dashboard/invoicereport/",
        // }, 
        {
            id: 1,
            menu: "View",
            url: "/dashboard/transaction/",

        }, 
    ];

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };
    
 
    useEffect(()=>{
        // if (module==="CRM" || module==="COA" || module==="SCM" || module==="INV" ) {
        //     setShowAccount(false)
        //     setShowDescription(true)
        // }
        if (module!=="ACC" && module!=="PMS") {
            setShowAccount(false)
            setShowDescription(true)
        }
        
        if (module==="PMS") {
            setShowDescription(true)
        }

        if (module==="INV" ) {
            setShowQuantity(true)
        }
        // else {
        //     setShowAccount(false)
        //     setShowDescription(true)
        // }
        // setShowAccount(false)
        // setShowDescription(true)
     },[module])


    return (
        <>

        {isMobile? 
            <>
            
            {transactions && transactions.map(transaction => ( 

            <Card className="dashboard-card" key={transaction.id}>
                
                <CardContent className="dashboard-card-content">
                    <div className="dashboard-card-title">{transaction.amount}</div>
                    <div className="dashboard-card-subtitle">{transaction.trans_type}</div>                            
                    <p>Reference: {transaction.reference}</p>
                    <p>Account: {transaction.account}</p>
                    <p>Date: {transaction.date}</p>
                    {/* <Pillchip label={booking.status_name} /> */}
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Booking</Button>
                </CardActions> */}
            </Card>
            ))}

            </>

            :
            // If Device is not mobile, display data using a table
            <>
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Type</th>
                        {showAccount && 
                            <th>Account</th>
                        }
                        {showDescription && 
                            <th>Description</th>
                        }
                        {showQuantity && 
                            <th>Quantity</th>
                        }
                        <th className="cell-align-right">Amount</th>
                        <th>DR/CR</th>
                        <th></th>
                    </tr>
                </thead>

                {transactions && transactions.map(transaction => (
                    <tbody key={transaction.id}>
                        <tr>
                            <td>{transaction.reference}</td>
                            {/* <td>{transaction.date}</td> */}
                            <td>{convertDateTime(transaction.date)}</td>

                            <td>{transaction.trans_type}</td>
                            {showAccount &&
                                <td>{transaction.account_name}</td>
                            }
                            {showDescription &&
                                <td>{transaction.description}</td>
                            }
                            {showQuantity &&
                                <td>{transaction.quantity}</td>
                            }
                            {/* <td>{Number(transaction.amount).toLocaleString('en-UK', { style: 'currency', currency: client.currency_code })}</td> */}
                            {clientProfile && clientProfile.currency_code? 
                                <td className="cell-align-right">{Number(transaction.amount).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(transaction.amount).toLocaleString('en-UK')}</td>
                            }

                            <td>{transaction.entry_type}</td>
                            {/* {transaction.type_code==="010" ? */}
                            {module==="ACC" &&
                                <td className='styled-table-menu'>
                                    {/* <ThreeDotsMenu data={transaction} threeSubMenu={threeSubMenu} urlWithState={true} /> */}
                                    <ThreeDotsMenu data={transaction} threeSubMenu={threeSubMenu} />
                                </td>
                            }
        
                        </tr>
                    </tbody>
                ))}
            </table>
            </>
        }

            
        {/* <TransactionNew isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} module={module}/> */}
        <Pagination 
            className="custom-mui-pagination"
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        />

        {/* {(module==="ACC" || module==="OMS") && */}
        {(module==="ACC") &&
            <Link to="/dashboard/transactionnew" state={{module: module, order:order}} className="nav-link">
                <Fab className="fab" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>
        }
        {/* {module==="OMS" &&
            <Fab className="fab" onClick={()=>setIsModalOpen(true)}  color="primary" aria-label="add">
                <AddIcon />
            </Fab>
        } */}

        </>

    );
  };
  
export default TransactionList;