import React, { useState, useRef } from 'react';

import {useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
// import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";

import { Modal, Radio } from 'antd';
// import NumberFormat from "react-number-format";


import NumberFormat from "react-number-format";


import UserAlerts from '../../components/UserAlerts';


const PriceTierNew = ({isModalOpen, setIsModalOpen, priceListItem }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    // const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');

    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [tier, setTier] = useState('');
    const [price, setPrice] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');


    const handleCancel = () => {
        setIsModalOpen(false);
    };
   
    const mutation = useMutation({
        mutationFn: (newTier) => {
            return authAxios.post("/products/price-tiers/", newTier);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['priceTiers']);
            setIsModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

       
        if (validDataRef.current===true) {

            // const price_meter = priceMeter.id
            const price_list_item = priceListItem.id

            const newTier = {client, price_list_item, tier, start, end, price, user };
            console.log("TIER", newTier)
            mutation.mutate(newTier)
        }
    };


    return (
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Price Tier" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <NumberFormat 
                            onChange={(e) => {
                                // setPriceError(false)
                                setTier(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Tier" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            // error={priceError}
                        />

                        <NumberFormat 
                            onChange={(e) => {
                                // setPriceError(false)
                                setStart(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Start" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            // error={priceError}
                        />

                        <NumberFormat 
                            onChange={(e) => {
                                setEnd(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="End" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            // error={priceError}
                        />

                        <NumberFormat 
                            onChange={(e) => {
                                // setPriceError(false)
                                setPrice(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Price" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            // error={priceError}
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default PriceTierNew;
