// import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios"

import { createSearchParams, Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

// import Layout from "../../components/Layout";
import ThreeDotsMenu from "../../components/ThreeDotsMenu";

import { format } from 'date-fns';
import PriceTierNew from "./PriceTierNew";


// function PriceTiers({priceTiers}) {


function PriceTiers({priceTiers, priceListItem}) {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);


    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    // const { data: priceTiers } = useQuery({
    //     queryKey: ['priceTiers'],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`products/price-tiers/?price_meter=${priceListItem.id}`);
    //       return response.data.results
    //     },
    //     enabled: !!priceListItem
    // });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/product/",
        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/customeredit/",
        // }
    ];


    return (
        <>          
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Tier</th>
                        <th>Date Created</th>
                        <th>Start</th>
                        <th>End</th>
                        {/* <th>Price</th> */}
                        <th className="cell-align-right">Price</th>
                        <th></th>
                    </tr>
                </thead>

                {priceTiers && priceTiers.map(tier => (

                    <tbody key={tier.id}>
                        <tr>
                            <td>{tier.tier}</td>
                            <td>{convertDateTime(tier.created_at)}</td>
                            <td>{tier.start}</td>
                            <td>{tier.end}</td>
                            {clientProfile && clientProfile.currency_code? 
                                <td className="cell-align-right">{Number(tier.price).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(tier.price).toLocaleString('en-UK')}</td>
                            }
                            <td className='styled-table-menu'>
                                <ThreeDotsMenu data={tier} threeSubMenu={threeSubMenu} />
                            </td> 
                        </tr> 
                    </tbody>
                ))}
                

            </table>

            <PriceTierNew 
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                priceListItem={priceListItem}
            />
            

            <Pagination 
                className="custom-mui-pagination"
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            {/* <Link to="/dashboard/product-new" className="nav-link">
                <Fab className="fab" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link> */}

            <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                setIsModalOpen(true)}}
                >
                <AddIcon />
            </Fab>

    </>            

    )
}
export default PriceTiers