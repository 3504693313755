import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { Spin } from 'antd';



const Loading = ({loading}) => {

    return (
        <div className='dashboard-loading'>    
            {loading &&
            // <CircularProgress 
            //     size={150}
            //     // thickness={4}
            // />
            <Spin tip="Loading..." size="large" />
            }
        </div>
    );
}

export default Loading;