import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import authAxios from "../../components/authAxios";

import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";

// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

//ANT DESIGN
import { message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Steps, Button, Radio } from 'antd';

import NumberFormat from "react-number-format";

import moment from 'moment';

import { UserContext } from "../../App";

import UserAlerts from "../../components/UserAlerts";

import './Rentals.css'

function TenantNew({ tag, isModalOpen, setIsModalOpen }) {
    const {page_size} = useContext(UserContext);

    const [error, setError] = useState('');
    // const [error, setError] = useState([]);

    const errorRef = useRef(null);

    const queryClient = useQueryClient();
    const modalTitle = useRef("");



    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          response.data.client_type==="030"? modalTitle.current="Add Resident": modalTitle.current="Add Tenant"
          return response.data
        },
    });


    const { data: properties } = useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/properties/?page_size=${page_size}`);
          return response.data.results;
        },
    });


    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    // const { data: contacts } = useQuery({
    //     queryKey: ['contacts'],
    //     queryFn: async () => {
    //       const response = await authAxios.get('/contacts');
    //       return response.data
    //     },
    // });

    const navigate = useNavigate();
    const location = useLocation();


    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);

    // const [property, setProperty] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [website, setWebsite] = useState('');
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [id_copy, setIdCopy] = useState(null);
    const [id_files, setIdFiles] = useState([]);
    const [gender, setGender] = useState('');
    const [type, setType] = useState('10');
    const [residentType, setResidentType] = useState('10');

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [memo, setMemo] = useState('');
    // const [source, setSource] = useState('');
    // const [locationType, setLocationType] = useState('');

    const [nameError, setNameError] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);

    const [isCompany, setIsCompany] = useState(false);
    const [isPerson, setIsPerson] = useState(false);


    const { Step } = Steps;
    // const { Item } = Form;
    // const { TextArea } = Input;
    // const { Option } = Select;
    const [currentStep, setCurrentStep] = useState(0);

   
    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };
  
    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };


    // const handleCustomerType = (e) => {
    //     // setType(e.target.value)
    //     setType(locationTypes.filter(type => type.code === e.target.value))
    // }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleCustomerType = (e) => {
        setType(e.target.value)
        if (e.target.value===10){
            // console.log("TRUE")
            setIsPerson(true)
        }
        else {
            console.log("FALSE")
            setIsPerson(false)
        }
    }


    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }


    // IMAGE UPLOAD ANT DESIGN
    // const [imageUrl, setImageUrl] = useState();
    // const [loading, setLoading] = useState(false);
    // var fileList = []

    // const getBase64 = (img, callback) => {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => callback(reader.result));
    //     reader.readAsDataURL(img);
    // };

    // const beforeUpload = (file) => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //       message.error('You can only upload JPG/PNG file!');
    //     }
      
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //       message.error('Image must smaller than 2MB!');
    //     }
      
    //     return isJpgOrPng && isLt2M;
    //   };
    
    // const handleUpload = (info) => {        
    //     console.log("UPLOAD",info.fileList)
    //     console.log("ARRAY LENGTH 2",(info.fileList).length)
    //     setIdCopy(info.file)
    //     for(let i= 0; i < info.fileList.length; i++){
    //         fileList.push(info.fileList[i].originFileObj)
    //     }

    //     setIdFiles(fileList)

    //     if (info.file.status === 'uploading') {
    //       setLoading(true);
    //       return;
    //     }
    
    //     if (info.file.status === 'done') {
    //       // Get this url from response in real world.
    //       getBase64(info.file.originFileObj, (url) => {
    //         setLoading(false);
    //         setImageUrl(url);
    //       });
    //     }
    // };

    // const uploadButton = (
    //     <div>
    //       {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //       <div 
    //         style={{
    //           marginTop: 8,
    //         }}
    //       >
    //         Upload
    //       </div>
    //     </div>
    // );

    const mutation = useMutation({
        mutationFn: (contact) => {
            // return authAxios.post("/contacts/", supplier);
            return authAxios.post('/contacts/', contact, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            });
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)
          

            if (error.response.data){
                const errorData = error.response.data;
                // const errorMessages = useRef(null);
                // console.log("ERROR DATA",errorData)

                Object.keys(errorData).map((field, index) => (
                        <div key={index}> {/* Wrap content in a container (optional) */}
                            {
                            Array.isArray(errorData[field]) ? (
                                
                            <ul> {/* Use a list for arrays */}
                                {errorData[field].map((errorMessage) => (
                                <li key={index}> {/* Use the index as the key */}
                                    {setError(`${field}: ${errorMessage}`)}
                                </li>
                                ))}
                            </ul>
                            ) : (
                            <p>{setError(`${errorData[field]}`)}</p>
                            )}
                        </div>
                    ));


                // if (Array.isArray(errorData)) {
                //     console.log("ERROR DATA IS ARRAY",errorData)
                //     errorData.forEach((errorObject) => {
                //         Object.keys(errorObject).map((field) => (
                //           <div key={field}> {/* Wrap content in a container (optional) */}
                //             {Array.isArray(errorObject[field]) ? (
                //               <ul> {/* Use a list for arrays */}
                //                 {errorObject[field].map((errorMessage, messageIndex) => (
                //                   <li key={`${field}-${messageIndex}`}> {/* Combine field and message index */}
                //                     {setError(`${field}: ${errorMessage}`)}
                //                   </li>
                //                 ))}
                //               </ul>
                //             ) : (
                //               <p>{setError(`${errorObject[field]}`)}</p>
                //             )}
                //           </div>
                //         ));
                //     })
                // }
                // else {
                //     console.log("ERROR DATA NOT ARRAY",errorData)
                //     Object.keys(errorData).map((field) => {
                //         <li>
                //         // console.log("Field:", field); // Log the field name
                //         // console.log("Error Messages:", errorData[field]); // Log the error message array
                    

                //         {/* {setError(`${field}: ${errorData[field]}`)} */}
                //         {setError([...error, `${field}: ${errorData[field]}`])}
                //         </li>

                //     })
                //     // Object.keys(errorData).map((field, index) => (
                //     //     <div key={index}> {/* Wrap content in a container (optional) */}
                //     //         {
                //     //         Array.isArray(errorData[field]) ? (
                                
                //     //         <ul> {/* Use a list for arrays */}
                //     //             {errorData[field].map((errorMessage) => (
                //     //             <li key={index}> {/* Use the index as the key */}
                //     //                 {setError(`${field}: ${errorMessage}`)}
                //     //             </li>
                //     //             ))}
                //     //         </ul>
                //     //         ) : (
                //     //         <p>{setError(`${errorData[field]}`)}</p>
                //     //         )}
                //     //     </div>
                //     // ));
                // }  

         
            } 
            else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['customers']);
            navigate('/dashboard/customers/');
            setIsModalOpen(false)

        },
      })



    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setNameError(true)
        }

        if (mobile === '' ) {
            setMobileError(true)
        }

      
        // if (name && mobile && email && address && city ) {
        if (name && mobile) {

            const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            let birth_date = "";
            if (dt){
                birth_date = moment(dt).format("YYYY-MM-DD")
            }

            const resident_type = residentType

            // const contact = { property, type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, memo, source, location_type, tag, id_files };

            const contact = {type, client, name, address, city, country, email, website, telephone, id_type, id_number, gender, birth_date, user, memo, resident_type, tag, id_files };
            console.log(contact)
            mutation.mutate(contact)
            
        } 
        else {
            setError("Missing name and/or mobile number"); 
        }
    
    }

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    // console.log("TAG",tag)
   

    return(
        <div className="tenant-new">
        {isModalOpen && 
        <Modal 
            className="dashboard-modal" 
            // title="Add Tenant" 
            title={modalTitle.current}
            visible={isModalOpen} 
            open={isModalOpen} 
            onOk={handleSubmit} 
            onCancel={handleCancel} 
            footer={[
                currentStep > 0 &&
                    <Button type="default" key="prev" onClick={prevStep}>
                        Previous
                    </Button>
                ,
                currentStep < 2 && (
                    <Button type="primary" key="next" onClick={nextStep}>
                        Next
                    </Button>
                ),
                currentStep === 2 && (
                    <Button type="primary" key="save" htmlType="submit" onClick={handleSubmit}>
                        Save
                    </Button>
                )
                ]}
        >
            <div className="dashboard-form">

            <div ref={errorRef}>
                <UserAlerts error={error}  />
            </div>
            <Steps size="small" current={currentStep} >
                <Step title="Profile" />
                <Step title="Contacts"  />
                <Step title="Documents"  />
            </Steps>

            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                {currentStep === 0 && (
                <>

                <Radio.Group size="large" defaultValue={10} onChange={handleCustomerType} className="custom-input">
                    <Radio.Button  value={10}>Individual</Radio.Button>
                    <Radio.Button value={20}>Company</Radio.Button>
                </Radio.Group>

                {clientProfile && clientProfile.client_type==="030" &&
                <div>
                    <Radio.Group defaultValue={10} onChange={(e) => setResidentType(e.target.value)} className="custom-input">
                        <Radio value={10}>Tenant</Radio>
                        <Radio  value={20}>Owner</Radio>
                    </Radio.Group>
                </div>
                }

                <TextField className="custom-input"
                    onChange={(e) => {
                        setNameError(false)
                        setName(e.target.value)
                    }}
                    label="Name" 
                    variant="outlined" 
                    fullWidth
                    required
                    error={nameError}
                />

                <NumberFormat 
                    onChange={(e) => {
                        setMobileError(false)
                        setMobile(e.target.value)
                    }}
                    className="custom-input"
                    fullWidth
                    label="Telephone" 
                    variant="outlined" 
                    customInput={TextField}
                    format="### (###) ######" mask="_"
                    required
                    error={mobileError}
                />

                {/* <Autocomplete
                    onChange={(event, newValue) => {
                        setProperty(newValue.id);
                        }}
                    options={properties}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Property"  /> }
                /> */}

                </>
                )}

                {currentStep === 1 && (
                <> 
                    <TextField className="custom-input"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        label="Email" 
                        variant="outlined" 
                        fullWidth
                    />

                    {type==="20" &&

                    <TextField className="custom-input"
                        onChange={(e) => setWebsite(e.target.value)}
                        label="Website" 
                        variant="outlined" 
                        fullWidth
                    />  
                    }
                

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setAddress(e.target.value)
                        }}
                        label="Address" 
                        variant="outlined" 
                        fullWidth
                    />  

                    <TextField className="custom-input"
                        onChange={(e) => {
                            setCity(e.target.value)
                        }}
                        label="City/Town" 
                        variant="outlined" 
                        fullWidth
                    />  
                </>
                )}

                
                {currentStep === 2 && (
                <> 
                    
                    <Autocomplete
                        onChange={(event, newValue) => {
                            setCountry(newValue.id);
                            console.log("VALUE", newValue.id)
                            }}
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Nationality"  /> }
                    /> 


                    <Radio.Group
                        onChange={(e) => setId_type(e.target.value)}
                        className="custom-input"
                    >
                        <Radio value={10}>Citizen ID</Radio>
                        <Radio value={20}>Passport</Radio>
                    </Radio.Group>

                    <TextField className="custom-input"
                        onChange={handleIdNumber}
                        label="ID/Passport Number" 
                        helperText="Maximum number of characters is 9" 
                        variant="outlined" 
                        fullWidth
                        required
                        error={idNumberError}
                    /> 

                    </>
                )}

            </form>
        </div>
        </Modal>
        }

        </div>


    )

}

export default TenantNew;
