import React, { useEffect, useState, useContext, useRef } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import moment from 'moment';


import { Modal, message } from 'antd';
import NumberFormat from "react-number-format";

import UserAlerts from '../../components/UserAlerts';


const MeterReadingNew = ({meter, isModalOpen, setIsModalOpen }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');

    const [txnLines, setTxnLines] = useState([]);
    const [amt, setAmt] = useState("");
    const [price, setPrice] = useState("");

    const [currentReading, setCurrentReading] = useState('');
    const [lastReading, setLastReading] = useState('');
    const [consumption, setConsumption] = useState('');

    const [dt, setDt] = useState(new Date());
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    // const { data: properties } = useQuery({
    //     queryKey: ['properties'],
    //     queryFn: async () => {
    //         const response = await authAxios.get(`/rentals/properties/?page_size=${pageSize}`);
    //         return response.data.results;
    //     },
    // });

    const [customer, setCustomer] = useState("");

    // const { data: leases } = useQuery({
    //     queryKey: ['leases'],
    //     queryFn: async () => {
    //         const response = await authAxios.get(`/rentals/leases/?page_size=${pageSize}`);
    //         const lease = response.data.results.find((lease) => lease.unit === meter.unit)
    //         setCustomer(lease.customer)
    //         return response.data.results;
    //     },
    //     enabled: !!meter // Enable the query when UNIT are populated

    // });


    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState("");
    const { data: items } = useQuery({
        queryKey: ['items'],
        queryFn: async () => {            
            const response = await authAxios.get(`/inventory/items/?customer=${customer}&property=${meter.property}&page_size=${pageSize}`);
            const p = response.data.results.find((product) => product.sku === meter.sku_reference)
            setProduct(p)
            setProducts(response.data.results)
            return response.data.results;
        },
        enabled: !!customer && !!meter // Enable the query when  CUSTOMER and UNIT are populated

    });

    // const handleReading = (e) => {
    //     // setCurrentReading(e.target.value)
    //     setCurrentReading(parseFloat(e.target.value.replace(/,/g, '')) || 0);

    //     // const product = products.find((product) => product.sku === meter.sku_reference)
    //     // setPrice(leaseProduct.price_data.price)
    //     const c1 = parseFloat(e.target.value.replace(/,/g, ''));
    //     // console.log("lastReading type:", typeof lastReading, "value:", lastReading);
    //     // const c2 = parseFloat(lastReading.replace(/,/g, ''));

    //     // const usage = c1 - meter.last_reading
    //     const usage = c1 - lastReading
    //     // const usage = currentReading - lastReading

    //     setConsumption(usage)

    //     let price = product.price_data.price
    //     if (meter.price>0){
    //         price = meter.price
    //     }
    //     // let subTotal = product.price_data.price*usage
    //     let subTotal = price*usage

    //     // console.log("METER PRICE", meter.price)
    //     // console.log("PRICE", price)

    //     const tiers  = product.price_data.price_tiers

    //     if (product.price_data.metered_billing_type==="020"){
    //         // let totalCost = 0;
    //         subTotal = 0;

    //         let remainingUsage = usage;
        
    //         for (const tier of tiers) {
    //           const tierUsage = Math.min(remainingUsage, tier.end - tier.start);
    //         //   totalCost += tierUsage * tier.price;
    //           subTotal += tierUsage * tier.price;
    //           remainingUsage -= tierUsage;
          
    //           // Exit loop if all usage is billed
    //           if (remainingUsage === 0) {
    //             break;
    //           }
    //         }

    //         price = subTotal;
    //         // return totalCost;
    //     }
    //     else if (product.price_data.metered_billing_type==="030"){
    //         // subTotal = product.price_data.price
    //         for (const tier of tiers) {
    //             // console.log("USAGE",usage)
    //             if (usage <= tier.end) {
    //                 price = tier.price;
    //                 subTotal += usage * tier.price;
    //               break;
    //             }
    //           }
    //     }

    //     const newTxnLine = {
    //         product: product.product,
    //         sku:product.sku,
    //         quantity:consumption,
    //         // price:product.price_data.price,
    //         price:price,
    //         sub_total:subTotal,
    //         tax:0,
    //         total: subTotal,
    //     };
    //     // console.log("TXN LN",newTxnLine)
    //     setTxnLines([newTxnLine]);
    //     setAmt(newTxnLine.total)
    //     // setPrice(product.price_data.price)
    //     setPrice(price)

    // }

    // console.log("CONSUMPTION", consumption)
    // console.log("AMT", amt)

    // console.log("PRICE", price)

   
    const mutation = useMutation({
        mutationFn: (reading) => {
            return authAxios.post("/rentals/meter-readings/", reading);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // console.log("RESULT", result)
            message.success(`Successfully posted reading. Consumption ${result.data.consumption} Bill: ${result.data.amount}`);
            queryClient.invalidateQueries(['meter']);
            queryClient.invalidateQueries(['meterReadings']);

            // // Refetch the updated meter data
            // queryClient.refetchQueries(['meter', meter.id]);
            // queryClient.refetchQueries(['meterReadings', meter.id]);

            setIsModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

       
        if (validDataRef.current===true) {
            // const customer = "88"
            const txn_lines = txnLines
            const amount = amt

            // const unit = {client, reference, property, product, memo, user };
            // const c1 = parseFloat(e.target.value.replace(/,/g, ''));

            // const current_reading = parseFloat(currentReading.replace(/,/g, ''));
            // const last_reading = parseFloat(lastReading.replace(/,/g, ''));
            const current_reading = currentReading
            const last_reading = lastReading
            const date = moment(dt).format("YYYY-MM-DD")

            const reference = clientProfile.invoice_prefix + String(clientProfile.invoice_no).padStart(8, '0')

            meter = meter.id
            // const reading = {client, meter, current_reading, last_reading, date, memo, user, txn_lines, customer, amount, price, reference };
            const reading = {client, meter, current_reading, last_reading, date, memo, user };

            console.log("READING", reading)
            mutation.mutate(reading)
        }
    };

    // console.log(meter)

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }       
    },[error])

    useEffect(()=>{
        if (meter){
            setLastReading(meter.last_reading)
        }       
    },[meter])


    return (
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Meter Reading" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <NumberFormat 
                            onChange={(e) => {
                                // console.log("E", e.target.value)
                                // setLastReading(e.target.value)
                                setLastReading(parseFloat(e.target.value.replace(/,/g, '')) || 0);

                            }}
                            className="custom-input"
                            fullWidth
                            label="Last Reading" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            value={lastReading}
                            // disabled={parseFloat(lastReading) > 0}
                            disabled={parseFloat(meter.last_reading) > 0}

                        />

                        <NumberFormat 
                            // onChange={handleReading}
                            onChange={(e) => {
                                setCurrentReading(parseFloat(e.target.value.replace(/,/g, '')) || 0);
                            }}
                            className="custom-input"
                            fullWidth
                            label="Current Reading" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            // value={subTotal}
                            // disabled
                            // error={totalError}
                        />


                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Reading Date"
                                value={dt}
                                onChange={(newDate) => {
                                    setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                                renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                            />
                        </LocalizationProvider>
                        
                     

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default MeterReadingNew;
