// import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios"

import { createSearchParams, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { VisibilityOutlined } from "@mui/icons-material";


import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
// import { Card, CardContent } from "@mui/material";

// import Layout from "../../components/Layout";
// import ThreeDotsMenu from "../../components/ThreeDotsMenu";
import EllipsisMenu from "../../components/EllipsisMenu";


import { format } from 'date-fns';


// import ProductNew from "./ProductNew";

function PriceListItems({priceListItems}) {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    // const { data, fetchData}  = useAxios();   
    const navigate = useNavigate()


    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });


    // const { data: products } = useQuery({
    //     queryKey: ['skus', page],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/products/skus/?page=${page}`);
    //       setPageCount(response.data.page_count)
    //       return response.data.results
    //     },
    // });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true)
    };
  
    const handleDrawerClose = () => {
        setIsDrawerOpen(false)
    };

    //Pagination
     const handlePageChange = (event, value) => {
        setPage(value);
    };

    const menuItems = (item) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                navigate(`/dashboard/price-list-item/${item.id}`)
            }
        },
    ];


    return (
        <>          
            <table className="styled-table">
                <thead>
                    <tr >
                        <th>Reference</th>
                        <th>Date Created</th>
                        <th>Name</th>
                        <th>Price Type</th>
                        {/* <th className="cell-align-right">Price</th> */}
                        <th></th>
                    </tr>
                </thead>

                {priceListItems && priceListItems.map(item => (

                    <tbody key={item.id}>
                        <tr>
                            <td>{item.product_sku}</td>
                            <td>{convertDateTime(item.created_at)}</td>
                            <td>{item.product_name}</td>
                            <td>{item.type_name}</td>
                            {/* {clientProfile && clientProfile.currency_code? 
                                <td className="cell-align-right">{Number(item.price).toLocaleString('en-UK', { style: 'currency', currency: clientProfile.currency_code })}</td>
                                :
                                <td>{Number(item.price).toLocaleString('en-UK')}</td>
                            } */}
                            <td className='styled-table-menu'>
                                {/* <ThreeDotsMenu data={item} threeSubMenu={threeSubMenu} /> */}
                                <EllipsisMenu items={menuItems(item)} />
                            </td>
                        </tr>
                    </tbody>
                ))}
                

            </table>
            

            <Pagination 
                className="custom-mui-pagination"
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            <Link to="/dashboard/product-new" className="nav-link">
                <Fab className="fab" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </Link>

    </>            

    )
}
export default PriceListItems