import React, { useState, useContext, useRef } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";

import { Modal } from 'antd';
// import NumberFormat from "react-number-format";

// import '../../styles/ProductDetails.css'

import UserAlerts from '../../components/UserAlerts';


const UnitNew = ({ property, isModalOpen, setIsModalOpen }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');
    const [reference, setReference] = useState('');
    const [sku, setSku] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: products } = useQuery({
        // queryKey: ['rental-inventory','property'],
        queryKey: ['products'],
        queryFn: async () => {
            // const response = await authAxios.get(`/rentals/rental-inventory/?property=${property}&page_size=${pageSize}`);
            // const response = await authAxios.get(`/inventory/items/?page_size=${pageSize}`);
            const response = await authAxios.get(`/products/skus/?page_size=${pageSize}`);
            return response.data.results;
        },
    });

   
    const mutation = useMutation({
        mutationFn: (unit) => {
            // return authAxios.post("/rentals/inventory/", unit);
            return authAxios.post("/rentals/property-units/", unit);

        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // queryClient.invalidateQueries(['units']);
            queryClient.invalidateQueries(['property-units']);
            setIsModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

       
        if (validDataRef.current===true) {
            
            // const unit = {client, reference, property, product, memo, user };
            const unit = {client, reference, property, sku, memo, user };

            console.log("UNIT", unit)
            mutation.mutate(unit)
        }
    };


    return (
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Property Unit" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <Autocomplete
                            onChange={(event, newValue) => {
                                setSku(newValue.id)

                                }}
                            
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Product" 
                                    {...params} 
                                />
                            }
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                // setNameError(false)
                                setReference(e.target.value)
                            }}
                            label="Reference" 
                            variant="outlined" 
                            fullWidth
                            required
                            // error={nameError}
                        />
                        

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default UnitNew;
