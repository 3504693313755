import { useState, useContext, useEffect, useRef} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import authAxios from "../../components/authAxios";


// import { FormControl } from "@mui/material";
// import { MenuItem } from "@mui/material";
// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
// import { Select } from "@mui/material";

import { Autocomplete, InputLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import NumberFormat from "react-number-format";

import moment from 'moment';

//ANT DESIGN STEPS
import { Steps, Form, Input, Select, Button, Radio } from 'antd';

import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import { UserContext } from "../../App";
import ProductDetails from "../products/ProductDetails";
import './Rentals.css'
import Loading from "../../components/Loading";

function LeaseNew() {
    const {page_size} = useContext(UserContext);
    const {pageSize} = useContext(UserContext);

    const queryClient = useQueryClient();
    const [error, setError] = useState('');
    const navigate = useNavigate();
    // const location = useLocation();
    const errorRef = useRef(null);


    const tag = "LEASE"

    const [dashboardTitle, setDashboardTitle] = useState('Add Lease');

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          if (response.data.client_type==="030"){
            setDashboardTitle("Add Subscription")
          }
          return response.data
        },
    });

    const { data: leaseReference } = useQuery({
        queryKey: ['leaseReference'],
        queryFn: async () => {
            const response = await authAxios.get(`/saas/default-reference/?tag=${tag}`);
            return response.data
        },
    });


    const { data: properties } = useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/properties/?page_size=${page_size}`);
          return response.data.results;
        },
    });

    const [property, setProperty] = useState('');
    const [unit, setUnit] = useState('');
    const [customer, setCustomer] = useState('');

    const { data: units, refetch: refetchUnits } = useQuery({
        queryKey: ['units', property],
        queryFn: async () => {
          if (property) {
            // const response = await authAxios.get(`/rentals/inventory/?property=${property}&page_size=${page_size}`);
            const response = await authAxios.get(`/rentals/property-units/?property=${property}&page_size=${page_size}`);

            return response.data.results;
          }
          return [];
        },
        enabled: !!property, // Fetch units only when property is selected
    });

    const { data: customers, isLoading } = useQuery({
        queryKey: ['customers'],
        queryFn: async () => {
        //   const response = await authAxios.get(`/crm/customers?page_size=${page_size}`);
        //   return response.data.results
          const response = await authAxios.get("/crm/customers");
          return response.data
        },
    });

    const { data: billingCycles } = useQuery({
        queryKey: ['billingCycles'],
        queryFn: async () => {
          const response = await authAxios.get("/subscriptions/billing-cycles");
          return response.data
        },
    });

    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState("");
    const { data: items } = useQuery({
        queryKey: ['items'],
        queryFn: async () => {            
            const response = await authAxios.get(`/inventory/items/?customer=${customer}&property=${property}&page_size=${pageSize}`);
            setProducts(response.data.results)
            return response.data.results;
        },
        // enabled: tag !== "INV" || !!customer // Enable the query unless the tag is INV and customer is not populated
        // enabled: tag !== "INV" || !!lease // Enable the query unless the tag is INV and customer is not populated
        enabled: !!customer && !!property // Enable the query when  CUSTOMER and UNIT are populated
    });
    
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const [dt, setDt] = useState(new Date());
    const [dt, setDt] = useState(null);
    const [dt1, setDt1] = useState(null);
    const [dt2, setDt2] = useState(moment())

    // const [tenant, setTenant] = useState('');

    const [property_name, setPropertyName] = useState('');
    const [billingCycle, setBillingCycle] = useState('');
    const [billingInterval, setBillingInterval] = useState(1);

    const [billingType, setBillingType] = useState('010');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');
    const [memo, setMemo] = useState('');
    const [price, setPrice] = useState('');
    // const [txnLines, setTxnLines] = useState([]);

    const { Step } = Steps;
    const { Item } = Form;
    // const { TextArea } = Input;
    // const { Option } = Select;
    const [currentStep, setCurrentStep] = useState(0);

   
    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };
  
    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };

    // const addTxnLine = (newTxnLine) => {
    //     setTxnLines([...txnLines, newTxnLine]);
    //     setIsModalOpen(false); // Close the modal after adding the expense
    // };

    const handleUnitSelection = (e) => {
        setUnit(e)
        // console.log("PRODUCTS",products);
        // console.log("UNIT",e);

        // const product = products.find((product) => product.sku === e.sku)

        try {
            const p = products.find((product) => product.sku === e.sku_reference)
            setProduct(p)
            setPrice(p.price_data.price)
            // const newTxnLine = {
            //     product: product.product,
            //     // sku:product.sku,
            //     sku:product.sku,
            //     quantity:1,
            //     price:product.price_data.price,
            //     sub_total:product.price_data.price,
            //     total:product.price_data.price,
            // };
            // setTxnLines([newTxnLine]);
        }
        catch (error){
            console.log(error)
        }
    }

    const handleConfirmedPrice = (e) => {
        // console.log("TESTING", e)
        const amt = parseFloat(e.target.value.replace(/,/g, ''));
        // setPrice(e.target.value)
        setPrice(amt)
    }

    const mutation = useMutation({
        mutationFn: (lease) => {
            return authAxios.post("/subscriptions/subscriptions/", lease);
            // return authAxios.post("/rentals/leases/", lease);

        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                        // console.log("WE ARE HERE:", errorMessage) || setError(errorMessage)

                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['leases']);
            navigate('/dashboard/leases/');
        },
      })



    const handleSubmit = (e) => {
        e.preventDefault();

        // if (name && mobile && email && address && city ) {

            // const telephone = mobile.replace(/[()" "]/g,"")

            //Change the format of birth date before saving
            // let birth_date = "";
            // if (dt){
            //     birth_date = moment(dt).format("YYYY-MM-DD")
            // }

            
            const start_date = moment(dt).format("YYYY-MM-DD")
            const end_date = moment(dt1).format("YYYY-MM-DD")
            // const next_billing_time = moment(dt2).format("YYYY-MM-DD")

            let next_billing_time = moment(dt2).format("YYYY-MM-DD")
            const currentDate = moment();
            if (moment(dt2).isAfter(currentDate, 'day')) {
                next_billing_time = moment(dt2)
                    .hours(12)
                    .minutes(0)
                    .seconds(0)
                    .milliseconds(0)
                    .format("YYYY-MM-DD HH:mm:ss");
            }
         
            const billing_cycle = billingCycle
            const billing_interval = billingInterval
            const billing_type = billingType

            // const name = "Testing 123" 

            // const txn_lines = txnLines
            let txn_lines = []
            const newTxnLine = {
                product: product.product,
                sku:product.sku,
                quantity:1,
                // price:product.price_data.price,
                // sub_total:product.price_data.price,
                // price: amt,
                // sub_total: amt,
                // total: amt,
                price: price,
                sub_total: price,
                total: price,
            };

            txn_lines = [...txn_lines, newTxnLine];

            const reference = leaseReference

            // const lease = { client, reference, customer, tenant, property, unit, txn_lines, start_date, end_date, billing_cycle, billing_interval, user, memo };
            const lease = {client, reference, customer, property, unit, txn_lines, start_date, end_date, billing_type, billing_cycle, billing_interval, next_billing_time, user, memo };
            console.log("LEASE",lease)
            mutation.mutate(lease)

            
        // } 
    
    }

    // console.log("DT2",dt2)

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])

    if (isLoading) return <Loading loading={true} />;



    return(
        <div className="dashboard-main">
            <section className="dashboard-content">
                <section className="dashboard-form dashboard-form-compact">
                    {/* <UserAlerts error={error} /> */}
                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>
                    {/* <DashboardHeader dashboardTitle="Add Lease" dashboardPreTitle="Rentals" /> */}
                    <DashboardHeader dashboardTitle={dashboardTitle} dashboardPreTitle="Rentals" />

                    <div className="lease-new">

                        <Steps size="small" current={currentStep}>
                            <Step title="Property" />
                            <Step title="Dates" />
                            <Step title="Billing" />
                        </Steps>
                    
                        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                            {currentStep === 0 && (
                            <>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        // console.log("VALUE",newValue)
                                        // setTenant(newValue.id);
                                        setCustomer(newValue.id);
                                        // setPropertyName(newValue.property_name);
                                        // setProperty(newValue.property)
                                    }}
                                    options={customers || []}
                                    getOptionLabel={(option) => { return option ? option.name : '' }} // Ensure option is not null or undefined
                                    renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Tenant" />}
                                    // value={customer !== null && tenants? tenants.find((option) => option.id === customer) : null }
                                />

                                {/* <TextField className="custom-input"
                                    value={property_name}
                                    // defaultValue={tenant.property_name}
                                    label="Property" 
                                    variant="outlined" 
                                    fullWidth
                                    disabled
                                /> */}

                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setProperty(newValue.id);
                                    }}
                                    options={properties || []}
                                    getOptionLabel={(option) => { return option ? option.name : '' }} // Ensure option is not null or undefined
                                    renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Property" />}
                                    // value={customer !== null && tenants? tenants.find((option) => option.id === customer) : null }
                                />

                                <Autocomplete
                                    // onChange={(event, newValue) => {
                                    //     handleUnitSelection
                                    //     setUnit(newValue)
                                    //     console.log("UNIT",newValue);
                                    // }}
                                    onChange={(event, newValue) => handleUnitSelection(newValue)}
                                    options={units || []}
                                    getOptionLabel={(option) => {
                                        // return option ? option.product_name : ''; // Ensure option is not null or undefined
                                        return option ? option.reference : ''; // Ensure option is not null or undefined
                                    }}
                                    renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Unit" />}
                                    disabled={!property} // Disable unit selection until property is selected
                                    // value={unit !== null && units ? units.find((option) => option.id === unit.id) : null } // Find the object in options array
                                />


                                <NumberFormat 
                                    value={price}
                                    // onChange={(e) => setAmount(e.target.value)} 
                                    onChange={(e) => handleConfirmedPrice(e)} 
                                    className="custom-input"
                                    fullWidth
                                    label="Price" 
                                    variant="outlined" 
                                    customInput={TextField}
                                    thousandSeparator={true} 
                                    // disabled
                                />

                           
                            </>
                            )}

                            {currentStep === 1 && (
                            <>
                                {/* <ProductDetails
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    addTxnLine={addTxnLine}
                                    txnLines={txnLines} 
                                    setTxnLines={setTxnLines} 
                                    clientProfile={clientProfile}
                                    tag={tag}
                                    property={property}
                                    customer={customer} 
                                /> */}
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        value={dt}
                                        onChange={(newDate) => {
                                            setDt(moment(newDate).format("YYYY-MM-DD"));

                                        }}
                                        renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        value={dt1}
                                        onChange={(newDate) => {
                                            setDt1(moment(newDate).format("YYYY-MM-DD"));

                                        }}
                                        renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="First Billing Date"
                                        value={dt2}
                                        onChange={(newDate) => {
                                            setDt2(moment(newDate).format("YYYY-MM-DD"));
                                        }}
                                        renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                    />
                                </LocalizationProvider>
                            </>
                            )}
                             
                            {currentStep === 2 && (
                            <>
                                {/* <div className="two-columns">

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Start Date"
                                        value={dt}
                                        onChange={(newDate) => {
                                            setDt(moment(newDate).format("YYYY-MM-DD"));

                                        }}
                                        renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="End Date"
                                        value={dt1}
                                        onChange={(newDate) => {
                                            setDt1(moment(newDate).format("YYYY-MM-DD"));

                                        }}
                                        renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                    />
                                </LocalizationProvider>
                                </div> */}


                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setBillingCycle(newValue.id);
                                    }}
                                    options={billingCycles || []}
                                    getOptionLabel={(option) => { return option ? option.name : '' }} // Ensure option is not null or undefined
                                    renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Billing Cycle" />}
                                    value={billingCycle !== null && billingCycles? billingCycles.find((option) => option.id === billingCycle) : null }
                                />

                                <NumberFormat 
                                    onChange={(e) => setBillingInterval(e.target.value)} 
                                    className="custom-input"
                                    fullWidth
                                    label="Billing Interval" 
                                    variant="outlined" 
                                    customInput={TextField}
                                    thousandSeparator={true} 
                                    required
                                    // error={amtError}
                                />
                             

                                <Radio.Group
                                    onChange={(e) => setBillingType(e.target.value)}
                                    className="custom-input"
                                >
                                    <Radio value='010'>Advance</Radio>
                                    <Radio value='020'>Arrears</Radio>
                                </Radio.Group>
                               
                            </>
                            )}

                            <div className="button-border-top">
                                {currentStep > 0 && (
                                    <Button type="default" onClick={prevStep}>
                                        Previous
                                    </Button>
                                )}
                                {currentStep < 2 && (
                                    <Button type="primary" onClick={nextStep}>
                                        Next
                                    </Button>
                                )}
                                {currentStep === 2 && (
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                )}
                                {/* {currentStep < 1 && (
                                    <Button type="primary" onClick={nextStep}>
                                        Next
                                    </Button>
                                )}
                                 {currentStep === 1 && (
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                )} */}
                            </div>
                        </form> 

                    </div>
                </section>
            </section> 

        </div>
    )

}

export default LeaseNew;
