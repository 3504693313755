import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";




// import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";

import DashboardHeader from "../../components/DashboardHeader";

import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

//TABS - ANT DESIGN
// import { Tabs } from "antd";

import TransactionList from "../accounting/TransactionList";
import CustomerEdit from "../crm/CustomerEdit";
import TransactionView from "./TransactionView";
import Loading from "../../components/Loading";
import PaymentView from "./PaymentView";


const Payment = () => {
    const params = useParams()
    const navigate = useNavigate()

    const [title, setTitle] = useState("")
    const [removeBorder, setRemoveBorder] = useState(true);

    const paymentId = params.id


    const { data: payment } = useQuery({
        queryKey: ['payment', paymentId],
        queryFn: async () => {
        //   const response = await authAxios.get(`accounting/transactions/${params.id}`);
          const response = await authAxios.get(`payments/payments/${paymentId}`);
          setTitle(response.data.type_name+" "+response.data.reference)
          return response.data
        },
    });
        

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };



    return (
        <div className="dashboard-main">
            {payment &&

            <section className="dashboard-content dashboard-content-compact">

                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle={title} dashboardPreTitle="Accounting" removeBorder={removeBorder} />
                    {/* {transaction.type_code==="010" &&
                        <div className="dashboard-content-header-nav-icons">
                            <IconButton 
                                onClick={() => navigate('/dashboard/invoicereport/',{state:{reference:transaction.reference}})}
                            > 
                                <DescriptionIcon/>
                            </IconButton>
                        </div>
                    } */}
                </div>

                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="customer-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Payment Details">
                        <PaymentView 
                            payment={payment}
                        />
                    </Tab>
                </Tabs> 
            </section>
            }

        </div>
        
    );
  };
  
  export default Payment;