import React, { useState, useContext, useRef, useEffect } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';

import { TextField, Autocomplete } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import moment from 'moment';


import { Modal } from 'antd';
import UserAlerts from '../../components/UserAlerts';
import { UserContext } from "../../App";


const MoveIn = ({ tag, occupancy, selectedLease, isMoveInModalOpen, setIsMoveInModalOpen }) => {

    const { clientProfile } = useContext(UserContext);

    // console.log("TAG",selectedLease)
    const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');


    const [dt, setDt] = useState(new Date());
    const [dt1, setDt1] = useState(new Date());
    // const [endDate, setEndDate] = useState(null);
    const dt2 = moment()

    const [memo, setMemo] = useState('');
    const [customerLabel, setCustomerLabel] = useState('');
    const [title, setTitle] = useState('');


    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsMoveInModalOpen(true);
    };

    const handleCancel = () => {
        setIsMoveInModalOpen(false);
    };

    // const occ = selectedLease.occupancy.find((occupancy) => (occupancy.lease === selectedLease.id && occupancy.client === selectedLease.client ))
    // console.log("OCC",occ)
    // const selectedAccount2 = accounts2.find((account) => account.value === e);


    // const { data: occupancy } = useQuery({
    //     queryKey: ['occupancy'],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/rentals/occupancies/?page=${page}`);
    //       setPageCount(response.data.page_count)
    //       return response.data.results
    //     },
    // });
   
    const mutation = useMutation({
        mutationFn: (data) => {
            if (tag==="MOVEIN"){
                return authAxios.post("/rentals/occupancies/", data);
            }
            if (tag==="MOVEOUT"){
                return authAxios.put(`rentals/occupancies/${occupancy.id}/`, data);
            }

        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['leases']);
            setIsMoveInModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true
       
        if (validDataRef.current===true) {
            const start_date = moment(dt).format("YYYY-MM-DD")
            
            let end_date = null
            if (tag==="MOVEOUT"){
                // setEndDate(moment(dt1).format("YYYY-MM-DD"))
                end_date = moment(dt1).format("YYYY-MM-DD")
            }
            // const end_date = endDate
            const lease = selectedLease.id
            const data = {client, lease, start_date, end_date,  memo, user };
            console.log("OCCUPANCY", data)
            mutation.mutate(data)
        }
    };

    

    useEffect(()=>{   
        if (clientProfile && clientProfile.client_type==="030"){
            setCustomerLabel("Resident")
        }
        else{
            setCustomerLabel("Tenant")
        }        
        if (tag==="MOVEOUT"){
            setTitle("Move Out")
        }
        else {
            setTitle("Move In")
    
        }
    },[clientProfile, tag])

    return (
        <>
        {isMoveInModalOpen && 
            <Modal className="dashboard-modal" title={title} visible={isMoveInModalOpen} open={isMoveInModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <TextField className="custom-input"
                            value={selectedLease.customer_name}
                            // label="Tenant" 
                            label={customerLabel} 
                            variant="outlined" 
                            fullWidth
                            disabled
                        />

                        <TextField className="custom-input"
                            value={selectedLease.property}
                            label="Property" 
                            variant="outlined" 
                            fullWidth
                            disabled
                        />

                        {tag === "MOVEIN" &&
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label="Occupancy Date"
                                    value={dt}
                                    onChange={(newDate) => {
                                        setDt(moment(newDate).format("YYYY-MM-DD"));
                                    }}
                                    renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                />
                            </LocalizationProvider>
                        }

                        {tag==="MOVEOUT" &&
                        <>
                            <TextField className="custom-input"
                                value={occupancy.start_date}
                                label="Occupancy Date" 
                                variant="outlined" 
                                fullWidth
                                disabled
                            />

                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                    label="Move Out Date"
                                    value={dt1}
                                    maxDate={dt2}
                                    inputFormat="DD-MM-YYYY" 
                                    onChange={(newDate) => {
                                        setDt1(moment(newDate).format("YYYY-MM-DD"));
                                    }}
                                    renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                                />
                            </LocalizationProvider>
                        </>
                        }


                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default MoveIn;
