// import React, {memo} from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { TextField } from "@mui/material";

import {Button} from "@mui/material";


const PriceListItemView = ({priceListItem}) => {
    // const navigate = useNavigate()

    // console.log("ITEM",priceListItem)


    return(
        <>
        <section className="dashboard-form">
            {priceListItem &&
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        label="SKU"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceListItem.product_sku}
                        InputProps={{ readOnly: true }}
                    />

                    <TextField className="custom-input"
                        label="Date Created"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceListItem.created_at}
                        InputProps={{ readOnly: true }}
                    />
                    <TextField className="custom-input"
                        label="Status"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {priceListItem.status_name}
                        InputProps={{ readOnly: true }}
                    />
                </div>

                
                <div className="grouped-fields-light">

                    <TextField className="custom-input"
                        label="Memo"
                        variant="outlined" 
                        fullWidth
                        multiline
                        rows={2}
                        defaultValue = {priceListItem.memo}
                        InputProps={{ readOnly: true }}
                    />
                  
                </div>
              
                <br/>
                <br/>

            </form>
            }

        </section>
        </>

    )
}

export default PriceListItemView;