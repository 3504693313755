import { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import authAxios from "../../components/authAxios";
import { Link, useNavigate } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import Chip from '@mui/material/Chip';

import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoginSharp, LogoutSharp } from "@mui/icons-material";
// import { VisibilitySharp } from "@mui/icons-material";
import { VisibilityOutlined } from "@mui/icons-material";

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Loading from "../../components/Loading";



// import { Tabs } from 'antd';
import { Tabs } from "antd";

import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import MoveIn from "./MoveIn";
import DashboardHeader from "../../components/DashboardHeader";

import { UserContext } from "../../App";
import MeterNew from "./MeterNew";
import CustomSearchBar from "../../components/CustomSearchBar";
// import '../crm/Crm.css'
import './Rental.css'



const Meters = () => {
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [property, setProperty] = useState("");


    const [status, setStatus] = useState("");
    const [searchText, setSearchText] = useState('');
    const { clientProfile } = useContext(UserContext);
    const { TabPane } = Tabs;


    const onChange = (key) => {
      };


    // const menuItems = (property) => [
    //     {
    //         label: 'View',
    //         icon: <VisibilityOutlined />,
    //         onClick: () => {
    //             // Handle view action
    //             navigate(`/dashboard/meter/${property.id}`)
    //         }
    //     },
    // ];

    const { data: meters, ...rest } = useQuery({
        queryKey: ['meters', property, status, page, searchText],
        queryFn: async () => {
          // const response = await authAxios.get(`/rentals/meters/?status=${status}&page=${page}`);
          const response = await authAxios.get(`/rentals/meters/?status=${status}&property=${property}&search=${searchText}&page=${page}`);
        //   const response = await authAxios.get(`/rentals/meters/?status=${status}&property=${property}`);
          setPageCount(response.data.page_count);
          return response.data.results;
        },
      //   enabled: !!status || page === 1, // Only fetch data when status is provided or on initial page load
      });

    const handlePageChange = (event, value) => {
        setPage(value);
    };



    return (
            <div className="dashboard-main">                
               
                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">
                        <DashboardHeader dashboardTitle="Utility Meters" dashboardPreTitle="Rentals" removeBorder={true}/>
                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>
                    <Tabs defaultActiveKey="All" onChange={onChange}>
                        <TabPane tab="All" key="All">
                            <FilterMeters 
                                status="" 
                                setProperty={setProperty}
                                property={property}
                                meters={meters}     
                            />
                        </TabPane>
                        <TabPane tab="Active" key="010">
                            <FilterMeters 
                                status="010" 
                                setProperty={setProperty}
                                property={property}
                                meters={meters}     

                            />                 
                        </TabPane>
                        <TabPane tab="Inactive" key="020">
                            <FilterMeters 
                                status="020" 
                                setProperty={setProperty}
                                property={property}
                                meters={meters}     

                            />
                        </TabPane >
                  
                    </Tabs>
                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />
                    <MeterNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                     <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                    {/* <Link to="/dashboard/lease-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>  */}

                </section>
            </div>

    )
}
export default Meters


// const FilterLeases = ({ ThreeDotsMenu, status }) => {
// const FilterLeases = ({ status }) => {

const FilterMeters = ({ status, meters, setProperty, property }) => {
    // const [page, setPage] = useState(1);
    // const [pagecount, setPageCount] = useState();
    // const [isMoveInModalOpen, setIsMoveInModalOpen] = useState(false);
    // const [selectedLease, setSelectedLease] = useState("");
    // const [occupancy, setOccupancy] = useState("");
    // const [tag, setTag] = useState("");

    const navigate = useNavigate()


    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    // };

    const { data: properties, isLoadingProperties } = useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
        //   const response = await authAxios.get(`/rentals/properties/`);
          const response = await authAxios.get(`/rentals/properties-with-meters`);

        //   return response.data.results
          return response.data
        },
    });
    
    // const { data: meters, ...rest } = useQuery({
    //   queryKey: ['meters', status, page],
    //   queryFn: async () => {
    //     // const response = await authAxios.get(`/rentals/meters/?status=${status}&page=${page}`);
    //     const response = await authAxios.get(`/rentals/meters/?status=${status}&property=${property}&page=${page}`);
    //     setPageCount(response.data.page_count);
    //     return response.data.results;
    //   },
    // //   enabled: !!status || page === 1, // Only fetch data when status is provided or on initial page load
    // });


    const ThreeDotsMenu = ({data}) => {    
        const [anchorEl, setAnchorEl] = useState(null);    
        // console.log("DATA",data)

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }


        const handleClose = () => {
            setAnchorEl(null);
        }


        return(
            <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    {/* <Link to="/dashboard/meter/${}" state={{ tag: "LOCATION" }} className="nav-link"> */}

                    <MenuItem className="styled-threedots-dropdown" >
                        <ListItemIcon>
                            <VisibilityOutlined/>
                        </ListItemIcon>
                        <ListItemText onClick={()=>navigate(`/dashboard/meter/${data.id}`)}>
                            View
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </>
        )
    }

    const handleChipClick = (e) => {
        if (e.id){
            // console.log('You clicked the Chip.', e);
            setProperty(e.id)
        }
        else{
            setProperty("")
        }
    };

    // console.log("PROPERTIES", properties)

    if (isLoadingProperties) return <Loading loading={true} />;

    return (
    <div className="filter-meters">
        {properties && properties.length>1 && status !== "020" &&
            <div className="chip-list-container">
                <div className="chip-list">
                    <Chip 
                        className="custom-chip" 
                        sx={{width:75}} 
                        onClick={()=>handleChipClick("All")} 
                        label="All" 
                        variant="outlined"
                        // variant={property === "" ? "filled" : "outlined"}
                        color={property === "" ? "primary" : "default"}

                    />
                    {properties.map(myProperty => (
                        <Chip 
                            className="custom-chip" 
                            key={myProperty.id} 
                            onClick={()=>handleChipClick(myProperty)} 
                            label={myProperty.name} 
                            variant="outlined"
                            // variant={property === myProperty.id ? "filled" : "outlined"}
                            color={property === myProperty.id ? "primary" : "default"}

                        />
                    ))
                    }
                </div>
            </div>
        }
        <table className="styled-table">
            <thead>
                <tr >
                    <th>Meter No</th>
                    <th>Date</th>
                    <th>Property</th>
                    <th>Tenant</th>
                    <th>Last Reading</th>
                    <th className="cell-align-right">Price</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            {meters && meters.map((meter) => (          
                <tbody key={meter.id}>
                    <tr>
                        {/* <td>{formatReference(meter.reference)}</td> */}
                        <td>{meter.reference}</td>
                        <td>{convertDateTime(meter.created_at)}</td>
                        <td>{meter.property_unit_name}</td>
                        <td>{meter.customer}</td>
                        <td>{meter.last_reading}</td>
                        <td className="cell-align-right">{Number(meter.price).toLocaleString('en-UK', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td><Pillchip label={meter.status_name} /></td>
                        <td className='styled-table-menu' >
                            <ThreeDotsMenu data={meter} />
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>


        {/* <Pagination 
            className="custom-mui-pagination"
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        /> */}
    </div>
    );
  };


