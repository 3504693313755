// import React, {memo} from "react";
import { useState } from "react";
import { useQueryClient} from '@tanstack/react-query';

import useAxios from "../../components/useAxios";
import { useNavigate, useParams } from "react-router-dom";

import { TextField, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import NumberFormat from "react-number-format";

// import { Radio } from "@mui/material";
// import { RadioGroup } from "@mui/material";
// import { FormControlLabel } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { Autocomplete } from "@mui/material";
// import moment from 'moment';
// import UserAlerts from "../../components/UserAlerts";
// import { useCountRenders } from "../../hooks/useCountRenders";
// const CustomerEdit = () => {

// const CustomerView = ({customer, setError, contact, countries}) => {
const CustomerView = ({customer}) => {

    // useCountRenders()
    const params = useParams()
    // const [isUpdated, setIsUpdated] = useState(false)
    const queryClient = useQueryClient();


    const {fetchData, data, response}  = useAxios();
    // const { data:customerData, fetchData:fetchCustomerData}  = useAxios();   


    const [dt, setDt] = useState(new Date());

    const [name, setName] = useState(customer.name);
    const [email, setEmail] = useState(customer.email);
    const [phone, setPhone] = useState(customer.telephone);
    const [address, setAddress] = useState(customer.address);
    const [city, setCity] = useState(customer.city);
    const [id_type, setId_type] = useState('');
    const [id_number, setId_number] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');

    const [website, setWebsite] = useState(customer.website);
    const [isPerson, setIsPerson] = useState(false);
    const client = localStorage.getItem('clientID');
    const user = localStorage.getItem('userID');

    const [nameError, setNameError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [idNumberError, setIdNumberError] = useState(false)

    const navigate = useNavigate();

    const handleChange = (event) => {
        setName(event.target.value);
      };

    const handleIdNumber = (e) => {
        if (e.target.value.length>9){
            setIdNumberError(true)
        }
        else{
            setIdNumberError(false)
            setId_number(e.target.value)
        }
    }

    return(
        <section className="dashboard-form">
            {customer &&
            <form noValidate autoComplete="off" >
                <div className="grouped-fields-light">
                    <TextField className="custom-input"
                        // value={name}
                        defaultValue = {customer.name}
                        label="Name" 
                        // InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                    />
                    <TextField className="custom-input"
                        defaultValue = {customer.email}
                        label="Email" 
                        // InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={emailError}
                    />

                    <NumberFormat 
                        // value = {phone}
                        // value = {customer.telephone}
                        defaultValue = {customer.telephone}
                        className="custom-input"
                        fullWidth
                        label="Mobile" 
                        variant="outlined" 
                        customInput={TextField}
                        format="### (###) ######" mask="_"
                        InputProps={{ readOnly: true }}
                        // required
                        // error={phoneError}
                    />

                    <TextField className="custom-input"
                        // value={address ?? ''}
                        defaultValue = {customer.address}
                        label="Address" 
                        // InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        variant="outlined" 
                        fullWidth
                        // required
                        // error={mobileError}
                    />

                    <TextField className="custom-input"
                        // value={city ?? ''}
                        defaultValue = {customer.city}
                        label="City/Town" 
                        variant="outlined" 
                        // InputLabelProps={{ shrink: true }}
                        InputProps={{ readOnly: true }}
                        fullWidth
                        // required
                        // error={cityError}
                    />  

                    {/* <TextField className="custom-input"
                        defaultValue = {customer.country}
                        label="Country" 
                        variant="outlined" 
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />   

                    <TextField className="custom-input"
                        defaultValue = {customer.website}
                        label="Website" 
                        variant="outlined" 
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />   */}
                </div>

                {/* {isPerson &&
                <>
                <div className="grouped-fields-light">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <FormControlLabel className="custom-radio" value="F" control={<Radio />} label="Female" />
                        <FormControlLabel className="custom-radio" value="M" control={<Radio />} label="Male" />
                    </RadioGroup>


                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Date of Birth"
                            value={dt}
                            onChange={(newDate) => {
                                setDt(moment(newDate).format("YYYY-MM-DD"));
                                }}
                            renderInput={(params) => <TextField fullWidth required className="custom-input" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                </>
                } */}

                {/* <div className="button-border-top">
                    <button id="submit" className="btn btn-primary form-buttons" type="submit">
                        Save
                    </button>
                </div> */}
                <Button
                    onClick={()=>navigate("/dashboard/customers")}
                    variant="outlined" 
                    size="small"
                    startIcon={<ArrowBackIcon />}
                >
                        Back
                </Button>
            </form>
            }
        </section>
    )

}

// export default memo(CustomerEdit);
export default CustomerView;

