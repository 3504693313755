import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

import useAxios from "../components/useAxios";
import axios from "axios";


const InvoiceReport = () => {

  const location = useLocation();
  const { data, fetchData}  = useAxios();   
  const reference = location.state.reference;
  const tag = location.state.tag;
  const [filename, setFilename] = useState("")

  // const id = location.state.id;


  const fetchReport = () => {

    console.log("INVOICE",location.state)

    fetchData({
        // url: `/accounting/invoice/?reference=${reference}`,
        url: `/accounting/invoice/?reference=${reference}&tag=${tag}`,

        // url: `/accounting/invoice/?reference=${id}`,
        method: 'get',
        responseType: 'blob'
    })
  }


  const fetchAdminInvoice = () => {

    console.log("PLATFORM INVOICE",location.state)

    fetchData({
        url: `/accounting/platform-invoice/?reference=${reference}&tag=${tag}`,
        method: 'get',
        responseType: 'blob'
    })
  }

  const fetchPosReport = () => {

    console.log("POS INVOICE",location.state)

    fetchData({
        url: `/accounting/pos-invoice/?reference=${reference}`,
        // url: `/accounting/invoice/?reference=${id}`,
        method: 'get',
        responseType: 'blob'
    })
  }

  const fetchQuote = () => {

    console.log("QUOTE",location.state)

    fetchData({
        url: `/accounting/quote/?reference=${reference}`,
        // url: `/accounting/invoice/?reference=${id}`,
        method: 'get',
        responseType: 'blob'
    })
  }

 

  useEffect(()=>{
      if (tag === "QUOTE"){
        setFilename('quote.pdf')
        fetchQuote()
      }
      else{
        setFilename('invoice.pdf')
        fetchReport()
      }
      // fetchReport()

      if (data.size){
          // const filename = 'zidi-report.pdf'
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
      }
  },[data.size])

  console.log("DATA",data)
  console.log("Blob",Blob)

}

export default InvoiceReport;