import authAxios from "../../components/authAxios";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { TextField, FormControlLabel, Autocomplete, InputLabel, MenuItem } from "@mui/material";
import { FormControl, Select } from "@mui/material";

import { TreeSelect } from "antd";

// IMAGE UPLOAD ANT DESIGN
// import { message, Upload } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

//ANT DESIGN STEPS
import { Steps, Radio, Button } from 'antd';

// import Sidebar from "./Sidebar";
import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";
import { UserContext } from "../../App";
import NumberFormat from "react-number-format";

// import CustomSwitch from "../../components/CustomSwitch";

import '../../styles/TreeSelectStyles.css'

import './Products.css'


const ProductNew = () => {
    const {page_size} = useContext(UserContext);
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const { data: inventoryTypes } = useQuery({
        queryKey: ['inventoryTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/inventory/inventory-types');
          return response.data
        },
    });


    const { data: productTypes } = useQuery({
        queryKey: ['productTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/products/product-types');
          return response.data
        },
    });

    const { data: priceLists } = useQuery({
        queryKey: ['priceLists'],
        queryFn: async () => {
          const response = await authAxios.get(`/products/price-lists/?page_size=${page_size}`);
          return response.data.results;
        },
    });

    const { data: units } = useQuery({
        queryKey: ['units'],
        queryFn: async () => {
          const response = await authAxios.get('/products/units');
          return response.data
        },
    });

    const { data: taxes } = useQuery({
        queryKey: ['taxes'],
        queryFn: async () => {
          const response = await authAxios.get('/accounting/taxes');
          return response.data
        },
    });

    const { data: accountTypes } = useQuery({
        queryKey: ['accountTypes'],
        queryFn: async () => {
          const response = await authAxios.get('/accounting/account-types');
          return response.data
        },
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: accounts } = useQuery({
        queryKey: ['accounts'],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/accounts?page_size=${page_size}`);
        //   setRevAccounts(response.data.results.filter(account => account.type_code === '10100'))
          setRevAccounts(response.data.results.filter(account => (account.type_code === '10100'||account.type_code === '40200')))
          return response.data.results;
        },
    });

    const { data: transactionCategories } = useQuery({
        queryKey: ['transactionCategories'],
        queryFn: async () => {
          const response = await authAxios.get(`/accounting/transaction-categories?page_size=${page_size}`);
          return response.data.results

        },
    });

    const [meteredBillingType, setMeteredBillingType] = useState('');

    const validDataRef = useRef(false);

    const [revAccounts, setRevAccounts] = useState([]);
    // const [creditAccounts, creditAccounts] = useState([]);

    // const [expAccounts, setExpAccounts] = useState([]);
    // const [inventoryAccounts, setInventoryAccounts] = useState([]);

    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [salesModel, setSalesModel] = useState('');

    const [unitPrice, setUnitPrice] = useState('');
    const [costInput, setCostInput] = useState('');
    const [upc, setUpc] = useState('');
    const [unit, setUnit] = useState('');
    const [memo, setMemo] = useState('');
    const [brand, setBrand] = useState('');
    const [tax, setTax] = useState([]);
    const [category, setCategory] = useState([]);
    const [collection, setCollection] = useState([]);
    const [property, setProperty] = useState([]);
    const [transactionCategory, setTransactionCategory] = useState([]);
    // const [priceList, setPriceList] = useState([]);
    const [priceMeter, setPriceMeter] = useState('');


    const [inventoryType, setInventoryType] = useState('');

    // const [type, setType] = useState('');
    const [priceType, setPriceType] = useState('');

    const [rentalProductType, setRentalProductType] = useState('');

    const [revenueAccount, setRevenueAccount] = useState('');
    const [expenseAccount, setExpenseAccount] = useState('');
    // const [debtorAccount, setDebtorAccount] = useState('');
    const [inventoryAccount, setInventoryAccount] = useState('');

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const [skuError, setSkuError] = useState(false)
    const [priceError, setPriceError] = useState(false)
    const [inventoryTypeError, setInventoryTypeError] = useState(false)
    const [nameError, setNameError] = useState(false)
    const [revenueAccountError, setRevenueAccountError] = useState(false)
    const [inventoryAccountError, setInventoryAccountError] = useState(false)
    const [isGoods, setIsGoods] = useState(false);
    const [productOptions, setProductOptions] = useState([{}]);

    const { Step } = Steps;
    // const { Item } = Form;
    const [currentStep, setCurrentStep] = useState(0);

    const nextStep = () => {
      setCurrentStep(currentStep + 1);
    };
  
    const prevStep = () => {
      setCurrentStep(currentStep - 1);
    };


    // Function to recursively transform data to the expected tree structure
    const transformCategoriesTree = (data) => {
        return data.map((item) => ({
            value: item.id.toString(), // Convert to string if needed
            title: item.name,
            children: item.sub_categories ? transformCategoriesTree(item.sub_categories) : [],
        }));
    };

    // const transformDataToTree = (data) => {
    //     return data.map((item) => ({
    //         value: item.id.toString(), // Convert to string if needed
    //         title: item.name,
    //         children: item.sub_accounts ? transformDataToTree(item.sub_accounts) : [],
    //     }));
    // };

    const filterTreeNode = (input, treeNode) => {
        const nodeLabel = treeNode.title.toLowerCase(); // Access title directly
        return nodeLabel.includes(input.toLowerCase());
    };

    const [hasVariants, setHasVariants] = useState(false);
    const handleChange = (event) => {
        setHasVariants(event.target.checked);
    };

    // const handleProductType = (e) => {
    //     setRentalProductType(e.target.value)

    //     // Set sales model to Subscription for Rent products;
    //     if (e.target.value==="030"||e.target.value==="040"){
    //         setSalesModel('020') 
    //     }
    //     else{
    //         setSalesModel('010') 
    //     }  
    //     const typeRev = accountTypes.find(accountType => accountType.code === "10100");
    //     setRevAccounts(accounts.filter(account => account.type === typeRev.id))
    // }

    const mutation = useMutation({
        mutationFn: (product) => {
            // return authAxios.post("/rentals/products/", product);
            return authAxios.post("/products/products/", product);

        },
        onError: (error, variables, context) => {
            setError(error.message)
            errorRef.current.scrollIntoView();              
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries(['products']);
            navigate('/dashboard/products/');
        },
      })

    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

        // if (clientProfile.auto_sku===false && sku === '') {
        //     setSkuError(true)
        //     validDataRef.current=false
        // }

        if (name === '') {
            setNameError(true)
            validDataRef.current=false
        }
       
        // if (debtorAccount === '') {
        //     setDebtorAccountError(true)
        // }

        if (revenueAccount === '') {
            setRevenueAccountError(true)
            validDataRef.current=false
        }

        if (isGoods && clientProfile.inventory_tracking && inventoryAccount === '') {
            setInventoryAccountError(true)
            validDataRef.current=false
        }
        if (isGoods && (unitPrice === '')) {
            setPriceError(true)
            validDataRef.current=false
        }
       
        if (validDataRef.current===true) {

            // DEFAULT TO 'SERVICES' FOR PRODUCTS IN RENTAL APP
            const type = productTypes.find(productType => productType.code === "10").id

            //Change the format of Price field before saving
            const price = parseFloat(unitPrice.replace(/,/g, ''));

            let cost = 0
            cost = parseFloat(costInput.replace(/,/g, ''));

            let inventory_type=""
            if (isGoods){
                inventory_type = inventoryType
            }
            else{
                // IF PRODUCT IS A SERVICE, SET TYPE TO FINISHED GOODS
                inventory_type = inventoryTypes.find(type => type.code === "10").id
            }

            const revenue_account = revenueAccount
            const expense_account = expenseAccount
            const inventory_account = inventoryAccount
            const product_options = productOptions
            const has_variants = hasVariants
            const rental_product_type = rentalProductType

            // const sku = reference
            // const reference = sku
            // const sales_model = salesModel

            const transaction_category = transactionCategory
            // const price_list = priceList
            const price_type =  priceType
            // const meter_price_type =  meterPriceType
            const price_meter = priceMeter
            const metered_billing_type = meteredBillingType

            // const app = "RENTALS"
            const app = ""


            const product = { 
                // app, client, sku, name, property, unit, price, cost, type, rental_product_type, inventory_type, category, collection, brand, memo, tax, 
                // revenue_account, transaction_category, billing_cycle, billing_interval, sales_model, user 
                app, client, sku, name, property, unit, price, cost, type, rental_product_type, inventory_type, category, collection, brand, memo, tax, 
                revenue_account, transaction_category, price_type, metered_billing_type, user 
            };
            console.log("PRODUCT",product)
            mutation.mutate(product)
        } 
    }

    return(
        <div className="dashboard-main">
            <section className="dashboard-content">
                <section className="dashboard-form dashboard-form-compact dashboard-form-products">
                <div ref={errorRef}>
                    <UserAlerts error={error}  />
                </div>
                <DashboardHeader dashboardTitle="Add Product" dashboardPreTitle="Products" />

                <Steps size="small" current={currentStep}>
                    <Step title="Profile" />
                    <Step title="Accounts" />
                    <Step title="Pricing" />
                </Steps>

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {currentStep === 0 && (
                    <>
                    {/* <div className="grouped-fields-light"> */}
                        {/* <RadioGroup
                            row
                            name="row-radio-buttons-group"
                            defaultChecked=""
                            onChange={handleProductType}
                        >
                            <FormControlLabel className="custom-radio" value="010" control={<Radio />} label="Service" />
                            <FormControlLabel className="custom-radio" value="020" control={<Radio />} label="Utilities" />
                            <FormControlLabel className="custom-radio" value="030" control={<Radio />} label="Residential" />
                            <FormControlLabel className="custom-radio" value="040" control={<Radio />} label="Commercial" />
                        </RadioGroup> */}

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setNameError(false)
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            error={nameError}
                        />
             
                    
                        {clientProfile && !clientProfile.auto_sku &&
                            <TextField className="custom-input"
                                onChange={(e) => {
                                    sku(e.target.value)
                                }}
                                label="Reference" 
                                variant="outlined" 
                                fullWidth
                                required
                                // error={skuError}
                            />
                        }

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    {/* </div> */}
                    </>
                    )}

                    {currentStep === 1 && (
                    <>
                    {/* <div className="grouped-fields-light"> */}
                        {/* {isGoods &&
                        <Autocomplete
                            onChange={(event, newValue) => {
                                setUnit(newValue.id);
                                }}
                            options={units}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Unit of Measurement"  /> }
                        />
                        } */}

                        
                        <FormControl 
                            variant="outlined" 
                            fullWidth className="custom-input" 
                            error={revenueAccountError}
                            required
                        >
                            <InputLabel>Credit Account</InputLabel>
                            <Select
                                className="custom-select"
                                label="Revenue Account"
                                onChange={(e) => {
                                    setRevenueAccount(e.target.value)
                                    setRevenueAccountError(false)
                                }}
                                defaultValue = ""
                            >
                                {revAccounts.map((account) => { 
                                    return(
                                    <MenuItem className="custom-select-list" key={account.id} value={account.id}>{account.name}</MenuItem>
                                    )
                                })}   
                                
                            </Select>
                        </FormControl>


                        {taxes &&
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setTax(newValue)
                            }}

                            options={taxes}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Taxes"  /> }
                        />
                        }

                        {clientProfile && clientProfile.transaction_class_tracking && transactionCategories &&
                        <Autocomplete
                            multiple
                            onChange={(event, newValue) => {
                                setTransactionCategory(newValue)
                            }}

                            options={transactionCategories}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Transaction Class"  /> }
                        />
                        }

                   {/* </div> */}
                   
                  

                    </>
                    )}

                    {currentStep === 2 && (
                    <>
                        {/* <div className="grouped-fields-light"> */}

                        <Radio.Group
                            onChange={(e) => setPriceType(e.target.value)}
                            className="custom-input"
                        >

                            <Radio.Button  value={'010'}>Fixed</Radio.Button>
                            <Radio.Button value={'030'}>Metered</Radio.Button>
                            <Radio.Button value={'020'} disabled>Calculated</Radio.Button>

                        </Radio.Group>

                        {priceType === "030" && 

                        <div>
                        <Radio.Group                     
                            className="custom-input"
                            onChange={(e) => setMeteredBillingType(e.target.value)}
                        >
                            <Radio value={'010'}>Unit</Radio>
                            <Radio value={'020'}>Volume</Radio>
                            <Radio value={'030'}>Tiered</Radio>
                        </Radio.Group>
                        </div>
                        }


                        { (priceType === "010" || meteredBillingType === "010") &&                        
                        <NumberFormat 
                            onChange={(e) => {
                                setPriceError(false)
                                setUnitPrice(e.target.value)
                            }}
                            className="custom-input"
                            fullWidth
                            label="Price" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            required
                            error={priceError}
                        />
                        }
                        {/* </div> */}
                        
                    </>
                    )}


                    <div className="button-border-top">
                        {currentStep > 0 && (
                            <Button type="default" onClick={prevStep}>
                                Previous
                            </Button>
                        )}
                        {currentStep < 2 && (
                            <Button type="primary" onClick={nextStep}>
                                Next
                            </Button>
                        )}
                        {currentStep === 2 && (
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        )}
                    </div>
                </form>

                </section>
            </section>
        </div>
    )

}

export default ProductNew;