import React from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { fab } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faLocationDot, faLocationPin, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";

// import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'


function Footer() {
    const isMobile = useMediaQuery({ query: '(max-width: 750px)' })

    return (
        <>
        {isMobile?
            <footer className="footer">
                <div className="footer-logo-container">
                    <div className="footer-logo footer-section">
                        <a href="https://twitter.com/getkoddi" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} /> 
                        </a>
                        {/* <a href="https://instagram.com/zidi.app/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} /> 
                        </a> */}
                         <a href="https://www.facebook.com/getkoddi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} /> 
                    </a>
                        <a href="https://www.linkedin.com/company/getkoddi" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} /> 
                        </a>
                    </div>
                </div>

                <div className="footer-text-container">
                    <div className="footer-section">
                        <div className="footer-section-title">Products</div>
                        <ul className="footer-list">
                            {/* <li><Link to="/crm">CRM</Link></li>                            
                            <li><a href="/">Point of Sale</a></li>
                            <li><a href="/">Purchasing</a></li>
                            <li><a href="/">Inventory Management</a></li>
                            <li><a href="/">Order Management</a></li>
                            <li><a href="/">Expense Management</a></li>
                            <li><a href="/">Accounting</a></li>
                            <li><a href="/">Human Resources</a></li> */}
                            <li><Link to="/products/crm">CRM</Link></li>  
                            {/* <li><Link to="/products/pos">Point of Sale</Link></li>           */}
                            {/* <li><Link to="/products/inventory">Inventory Management</Link></li>    
                        //                 <li><a href="/">Billing</a></li>
                        //  */}
                            <li><a href="/">Property Management</a></li>
                            {/* <li><a href="/">Order Management</a></li> */}
                            <li><a href="/">Finance</a></li>
                            <li><a href="/">Human Resources</a></li>
                        </ul>
                    </div>


                    <div className="footer-section footer-section-company">
                        <div className="footer-section-title">
                            Company
                        </div>
                        <ul className="footer-list">
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/">About</a></li>
                            <li><a href="/">Careers</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        :
        <>
        <footer className="footer">
            {/* <div className="footer-logo-container"> */}
                <div className="footer-logo footer-section">
                    <a href="https://twitter.com/getkoddi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} /> 
                    </a>
                    <a href="https://www.facebook.com/getkoddi" target="_blank" rel="noopener noreferrer">
                        {/* <i className="fab fa-facebook"></i> */}
                        <FontAwesomeIcon icon={faFacebook} /> 
                    </a>
                    {/* <a href="https://instagram.com/zidi.app/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} /> 
                    </a> */}
                    <a href="https://www.linkedin.com/company/getkoddi" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} /> 
                    </a>
                </div>
            {/* </div> */}

            {/* <div className="footer-text-container"> */}



            <div className="footer-section">
                <div className="footer-section-title">Products</div>
                <ul className="footer-list">
                    <li><Link to="/products/crm">CRM</Link></li>  
                    {/* <li><Link to="/products/pos">Point of Sale</Link></li>           */}
                    {/* <li><Link to="/products/inventory">Inventory Management</Link></li>                             */}

                    <li><a href="/">Property Management</a></li>
                    <li><a href="/">Billing</a></li>
                    <li><a href="/">Payments</a></li>
                    <li><a href="/">Finance</a></li>
                    {/* <li><a href="/">Human Resources</a></li> */}
                </ul>
            </div>

          

            <div className="footer-section footer-section-legal">
                <div className="footer-section-title">Resources</div>
                <ul className="footer-list">
                    {/* <li><a href="/">Support</a></li> */}
                    {/* <li><a href="/">Integrations</a></li> */}
                    <li><a href="/">Privacy Notice</a></li>
                    <li><a href="/">Terms and Conditions</a></li>
                </ul>
            </div>

            <div className="footer-section footer-section-company">
                <div className="footer-section-title">
                    Company
                </div>
                <ul className="footer-list">
                    <li><a href="/contact">Contact</a></li>
                    {/* <li><a href="/">About</a></li> */}
                    {/* <li><a href="/">Team</a></li> */}
                    <li><a href="/">Careers</a></li>
                </ul>
            </div>
            {/* </div> */}


            {/* <div className="footer-section">
                <div className="footer-section-title">Contact</div>
                    <ul className="footer-list">
                        <li>
                            <FontAwesomeIcon icon={faLocationDot} /> 
                            Unipen Building, Argwings Kodhek Rd, Nairobi, Kenya
                        </li>
                        <li>
                            <a href="tel:+254202028950">
                                <FontAwesomeIcon icon={faPhoneSquare} /> 
                                +254.20.2028950
                            </a>
                        </li>
                    </ul>
            </div> */}
            
        </footer>
        <section className="footer-copyright">
            ©2024 Koddi. All rights reserved.
        </section>
        </>
        }
        </>
    )
  }

  export default Footer;
