// import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import InputBase from '@mui/material/InputBase';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { Input } from '@mui/material';

// const CustomSearchBar = ({searchText}) => {
//   return (
//     <div className='custom-mui-search'>


//       {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
//         <MenuIcon />
//       </IconButton> */}
//       <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
//         <SearchIcon />
//       </IconButton>
//       <InputBase
//         sx={{ ml: 1, flex: 1 }}

//         inputProps={{ 'aria-label': 'search google maps' }}
//         endAdornment={searchText?<HighlightOffIcon/>:null}
//         // onChange={console.log("TEST123")}
//         onSubmit={console.log("TEST123")}
//       />


//       {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
//         <SearchIcon />
//       </IconButton> */}
//       {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
//       <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
//         <DirectionsIcon />
//       </IconButton> */}
//     </div>
//       );
//     }

// export default CustomSearchBar;


import React, { useState } from 'react';
import './CustomSearch.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { useQuery } from 'react-query';
// import authAxios from './authAxios'; // Ensure you have this configured for your API calls

// const CustomSearchBar = ({ placeholder, page, onResultsFetched }) => {

const CustomSearchBar = ({ searchText, setSearchText }) => {
  const [searchKey, setSearchKey] = useState('');
  // const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (e) => {
    // setSearchText(e.target.value);
    setSearchKey(e.target.value);
  };

  const handleClear = () => {
    setSearchText('');
    setSearchKey('');

    // setSearchTerm('');
  };

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the default form submission
    // setSearchText(searchText); // Set the search term state to trigger the query
    setSearchText(searchKey); // Set the search term state to trigger the query
  };

  // console.log("KEY WORDS",searchText)

  return (
    <div className="hover-search-box">
      <form onSubmit={handleSearch}>
        {/* <div className="search-icon-container"> */}
          {/* <button type="submit" className="search-icon-button">
            <i className="fas fa-search search-icon"></i>
          </button> */}
          
          <div className="input-container">
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" className="search-icon-button">
              <SearchIcon className='search-icon' />
            </IconButton>

            <input
              type="text"
              // value={searchText}
              value={searchKey}

              onChange={handleChange}
              // placeholder={placeholder}
              className="search-input"
            />
            {/* {searchText && ( */}
            {searchKey && (
              // <button type="button" className="clear-button" onClick={handleClear}>
              //   <i className="fas fa-times"></i>
              // </button>
              <IconButton color="primary" sx={{ p: '10px' }} aria-label="clear search text" className="clear-button" onClick={handleClear}>
                <HighlightOffIcon className="clear-icon" />
              </IconButton> 
            )}
          </div>
        {/* </div> */}
      </form>
      {/* You can render customers and handle status here if needed */}
    </div>
  );
};

export default CustomSearchBar;