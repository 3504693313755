import { useState, useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import authAxios from "../../components/authAxios";
import { Link } from "react-router-dom";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';


import { Menu } from "@mui/material";
import { MenuItem } from "@mui/material";
import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoginSharp, LogoutSharp } from "@mui/icons-material";
// import { VisibilitySharp } from "@mui/icons-material";
import { VisibilityOutlined } from "@mui/icons-material";

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


import { Tabs } from "antd";
import { format } from 'date-fns';
import Pillchip from "../../components/Pillchip";
import MoveIn from "./MoveIn";
import DashboardHeader from "../../components/DashboardHeader";
import CustomSearchBar from "../../components/CustomSearchBar";

import { UserContext } from "../../App";
import moment from 'moment';



const Leases = () => {
    // const { clientProfile } = useContext(UserContext);
    const [dashboardTitle, setDashboardTitle] = useState("");
    const { TabPane } = Tabs;

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const [status, setStatus] = useState("");
    const [searchText, setSearchText] = useState('');


    const { data: leases } = useQuery({
        queryKey: ['leases', status, page, searchText],
        queryFn: async () => {
          const response = await authAxios.get(`/rentals/leases/?status=${status}&search=${searchText}&page=${page}`);
          setPageCount(response.data.page_count);
          return response.data.results;
        },
      //   enabled: !!status || page === 1, // Only fetch data when status is provided or on initial page load
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });


    const onChange = (key) => {
        setStatus(key)
        if (key==="000"){
            setStatus("")
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    
    useEffect(()=>{   
        if (clientProfile && clientProfile.client_type==="030"){
            setDashboardTitle("Subscriptions")
        }
        else{
            setDashboardTitle("Leases")
        }        
    },[clientProfile])


    return (
            <div className="dashboard-main">                
               
                <section className="dashboard-content">
                    <div className="dashboard-content-header-nav">
                        <DashboardHeader dashboardTitle={dashboardTitle} dashboardPreTitle="Rentals" removeBorder={true}/>
                        <div className="dashboard-content-header-nav-icons">
                            <CustomSearchBar 
                                searchText={searchText}
                                setSearchText={setSearchText}
                            />
                        </div>
                    </div>


                    <Tabs defaultActiveKey="All" onChange={onChange}>
                        <TabPane tab="All" key="000">
                            <FilterLeases 
                                // status="" 
                                status={status} 
                                clientProfile={clientProfile} 
                                leases={leases}    
                            />
                        </TabPane>
                        <TabPane tab="Pending" key="010">
                            <FilterLeases 
                                // status="010" 
                                status={status} 
                                clientProfile={clientProfile} 
                                leases={leases}  
                            />                 
                        </TabPane>
                        <TabPane tab="Active" key="020">
                            <FilterLeases 
                                // status="020" 
                                status={status} 
                                clientProfile={clientProfile} 
                                leases={leases}  
                            />
                        </TabPane >
                        <TabPane tab="Moved Out" key="030">
                            <FilterLeases 
                                // status="030" 
                                status={status} 
                                clientProfile={clientProfile} 
                                leases={leases} 
                            />
                        </TabPane>
                    </Tabs>

                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    <Link to="/dashboard/lease-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> 

                </section>
            </div>

    )
}
export default Leases


// const FilterLeases = ({ ThreeDotsMenu, status }) => {

const FilterLeases = ({ status, leases, clientProfile }) => {

    // const { clientProfile } = useContext(UserContext);
    // const [page, setPage] = useState(1);
    // const [pagecount, setPageCount] = useState();
    const [isMoveInModalOpen, setIsMoveInModalOpen] = useState(false);
    const [selectedLease, setSelectedLease] = useState("");
    const [occupancy, setOccupancy] = useState("");
    const [tag, setTag] = useState("");


    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const formatReference = (reference) => {
        return `${reference.slice(0, 3)}-${reference.slice(3, 9)}-${reference.slice(9)}`;
    };

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    // };
    
    // const { data: leases, ...rest } = useQuery({
    //   queryKey: ['leases', status, page],
    //   queryFn: async () => {
    //     const response = await authAxios.get(
    //       `/rentals/leases/?status=${status}&page=${page}`
    //     );
    //     setPageCount(response.data.page_count);
    //     return response.data.results;
    //   },
    // });

    // const { data: clientProfile } = useQuery({
    //     queryKey: ['clientProfile'],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
    //       return response.data

    //     },
    // });


    const ThreeDotsMenu = ({data}) => {    
        const [anchorEl, setAnchorEl] = useState(null);    
        // console.log("DATA",data)

        const handleClick = (e) => {
            setAnchorEl(e.currentTarget);
        }


        const handleClose = () => {
            setAnchorEl(null);
        }


        return(
            <>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem className="styled-threedots-dropdown">
                        <ListItemIcon>
                            <VisibilityOutlined/>
                        </ListItemIcon>
                        <ListItemText>
                            View
                        </ListItemText>
                    </MenuItem>
                    {data.status_code === "010" &&
                    <MenuItem className="styled-threedots-dropdown">
                        <ListItemIcon>
                            <LoginSharp/>
                        </ListItemIcon>
                        <ListItemText onClick={()=>{
                            setSelectedLease(data)
                            setTag("MOVEIN")
                            setIsMoveInModalOpen(true)
                        }}>
                            Move In
                        </ListItemText>
                    </MenuItem>
                    }
                    {data.status_code === "020" &&
                    <MenuItem className="styled-threedots-dropdown">
                        <ListItemIcon>
                            <LogoutSharp/>
                        </ListItemIcon>
                        <ListItemText onClick={()=>{
                            setSelectedLease(data)
                            setTag("MOVEOUT")
                            const selectedOccupancy = data.occupancy.find((occupancy) => (occupancy.lease === data.id && occupancy.client === data.client ))
                            setOccupancy(selectedOccupancy)
                            // console.log("OCCUPANCY",occ)

                            setIsMoveInModalOpen(true)
                        }}>
                            Move Out
                        </ListItemText>
                    </MenuItem>
                    }
                </Menu>
            </>
        )
    }
  
    return (
    <div>
        {clientProfile &&
            <table className="styled-table">
            <thead>
                <tr >
                    <th>Reference</th>
                    <th>Start Date</th>
                    {clientProfile.client_type==="030"?<th>Resident</th>:<th>Tenant</th>}
                    <th>Property</th>
                    <th className="cell-align-right">Amount</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            {leases && leases.map((lease) => (          
                <tbody key={lease.id}>
                    <tr>
                        <td>{formatReference(lease.reference)}</td>
                        {/* <td>{convertDateTime(lease.created_at)}</td> */}
                        <td>{moment(lease.start_date).format("DD-MM-YYYY")}</td>
                        <td>{lease.customer_name}</td>
                        <td>{lease.property}</td>
                        {/* <td>{lease.amount}</td> */}
                        <td className="cell-align-right">{Number(lease.amount).toLocaleString('en-UK')}</td>
                        <td><Pillchip label={lease.status_name} /></td>
                        <td className='styled-table-menu' >
                            <ThreeDotsMenu data={lease} />
                        </td>
                    </tr>
                </tbody>
            ))}
        </table>

       
        }

        <MoveIn 
            isMoveInModalOpen={isMoveInModalOpen}
            setIsMoveInModalOpen={setIsMoveInModalOpen}
            tag={tag}
            selectedLease={selectedLease}
            occupancy={occupancy}
        />

        {/* <Pagination 
            className="custom-mui-pagination"
            count={pagecount} 
            page={page} 
            onChange={handlePageChange} 
        /> */}
    </div>
    );
  };


