import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import DashboardHeader from "../../components/DashboardHeader";
import TransactionList from "../accounting/TransactionList";
import UserAlerts from "../../components/UserAlerts";
import PropertyView from "../rentals/PropertyView";
import UnitList from "../rentals/UnitList";
import PriceListView from "./PriceListView"
import ProductList from "./PriceListItems";
import PriceListItem from "./PriceListItems";
import PriceListItems from "./PriceListItems";

const PriceList = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const priceListId = params.id

    // const [removeBorder, setRemoveBorder] = useState(true);
    const removeBorder = true
    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    // const { data: transactions } = useQuery({
    //     queryKey: ['property-ledger', page],
    //     queryFn: async () => {
    //         // const response = await authAxios.get(`/accounting/subledger/?account=${params.id}&page=${page}`);
    //         const response = await authAxios.get(`/rentals/property-ledger/?property=${propertyId}&page=${page}`);
    //         setPageCount(response.data.page_count)
    //         return response.data.results
    //     },
    // });

    const { data: priceList } = useQuery({
        queryKey: ['priceList', priceListId],
        queryFn: async () => {
          const response = await authAxios.get(`products/price-lists/${priceListId}`);
        //   return response.data
          return response.data

        },
    });


    const { data: priceListItems } = useQuery({
        queryKey: ['priceListItems'],
        queryFn: async () => {
          const response = await authAxios.get(`products/price-list-items/?price_list=${priceList.id}`);
          return response.data.results
        },
        enabled: !!priceList
    });
        

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return (
        <div className="dashboard-main">
            {priceList &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle={priceList.name} dashboardPreTitle="Products" removeBorder={removeBorder} />

                    {/* <div className="dashboard-content-header-nav-icons">
                        <Tooltip title="Statement">
                            <IconButton 
                                onClick={() => navigate('/dashboard/customer-statement/',{state:{reference:params.id}})}
                            > 
                                <DescriptionIcon/>
                            </IconButton>
                        </Tooltip>
                    </div> */}
                </div>


              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="property-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <PriceListView 
                            priceList={priceList}
                            // setError={setError}
                        />
                    </Tab>

                    <Tab eventKey="price-list-items" title="Price List Items">
                        <PriceListItems 
                            priceListItems={priceListItems} 
                        />
                    </Tab>
                    
                </Tabs> 


            </section>
            }

        </div>
    );
  };
  
  export default PriceList;