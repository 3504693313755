import { useState, memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

// import useAxios from "../components/useAxios";
// import authAxios from "../../components/authAxios";
// import authAxios from "./authAxios";
// import useAxiosInterceptor from "./useAxiosInterceptor";
import useAxiosInterceptor from "../components/useAxiosInterceptor";
// import axiosI

// import authAxios from './authAxios'
import authAxios from "../components/authAxios";

import { List } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import GroupIcon from '@mui/icons-material/Group';
import InventorySharpIcon from '@mui/icons-material/InventorySharp';
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import QrCodeSharpIcon from '@mui/icons-material/QrCodeSharp';
import ShoppingBagSharpIcon from '@mui/icons-material/ShoppingBagSharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import SpokeIcon from '@mui/icons-material/Spoke';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import SellIcon from '@mui/icons-material/Sell';
import HomeWorkSharpIcon from '@mui/icons-material/HomeWorkSharp';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import logo from '../assets/zidi-logo.svg'
import logo from '../assets/koddi-logo.svg'


const AdminSidebar = () => {
    const [open, setOpen] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState([]);
    const {error} = useAxiosInterceptor();

    const { data: clientModules } = useQuery({
        queryKey: ['clientModules'],
        queryFn: async () => {
            const response = await authAxios.get('/saas/client-modules');
            const cm = response.data.find((cm) => cm.client === parseInt(localStorage.getItem('clientID')))
            return cm.modules
        },
    });


    const { data: adminMenu } = useQuery({
        queryKey: ['adminMenu'],
        queryFn: async () => {
            const response = await authAxios.get('/saas/platform-admin-menu');
            return response.data
        },
    });

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });


    // console.log("MENU", adminMenu)



    const MenuIcon = ({item}) =>{
        if (item.name === "Dashboard"){
            return <AppsSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "POS"){
        if (item.code === "POS"){
            // return <PointOfSaleIcon className="dashboard-navicon"/>
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>

        }
        if (item.name === "Sales"){
            return <SellIcon className="dashboard-navicon"/>
        }
        // if (item.name === "CRM"){
        if (item.name === "CRM" || item.name === "Residents" || item.name === "Tenants"){
            return <GroupIcon className="dashboard-navicon"/>
        }
        if (item.name === "Products"){
            return <InventorySharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Purchase"){
            return <LocalShippingIcon className="dashboard-navicon"/>
        }
        if (item.name === "Inventory"){
            return <FontAwesomeIcon icon={faBarcode} className="dashboard-navicon"/>
            // return <QrCodeSharpIcon className="dashboard-navicon"/>
        }
        if (item.name === "Orders"){
            return <ShoppingCartSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Human Resources"){
        if (item.name === "Human Resources" || item.name === "Owners"){
            return <PortraitSharpIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Integrations"){
        //     return <SpokeIcon className="dashboard-navicon"/>
        // }
        if (item.code === "ECOM" || item.name === "E-Comm Platforms"){
            return <SpokeIcon className="dashboard-navicon"/>
        }
        // if (item.name === "Rentals"){

        if (item.name === "Rentals" ||item.name === "Properties" ){
            return <HomeWorkSharpIcon className="dashboard-navicon"/>
        }

        if (item.name === "Subscriptions" || item.name === "Leases" ){
            return <EventRepeatIcon className="dashboard-navicon"/>
        }
        if (item.name === "Accounting"){
            return <BarChartIcon className="dashboard-navicon"/>
        }
        if (item.name === "Settings"){
            return <SettingsSharpIcon className="dashboard-navicon"/>
        }
    }

    const handleClick = (index) => {
        const newOpen = {...open}; 
        newOpen[index] = !newOpen[index];
        setOpen(newOpen);
        setSelectedIndex(index);
    };

    
    return(
        <section className="dashboard-menu flex-column">
            <div className="dashboard-brand">
                <Link to="/">
                    <img src={logo} alt="brand logo"/>  
                </Link>

            </div>
            {clientProfile && clientProfile.menu_profile.menu_items.length>0 &&
            <List >
                {/* {adminMenu.menu_items.map(item => (  */}
                {clientProfile && clientProfile.menu_profile.menu_items.map(item => ( 


                    <div key={item.id}>
                        
                        {/* {item.parent === null && 
                        <> */}

                        {/* IF PARENT MENU HAS SUBMENUS */}
                        {/* {item.menu_items.length>0 ? */}
                        {item.children.length>0 ?
                        <>
                            <ListItemButton 
                                selected={selectedIndex === item.id}
                                className="dashboard-navlink" 
                                onClick={() => handleClick(item.id)}
                                // component={Link} to={item.path}
                            >
                                <MenuIcon item={item} />
                                <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                                {/* {item.children.length>0 && (open[item.id] ? <ExpandLess /> : <ExpandMore />)} */}
                                {(open[item.id] ? <ExpandLess /> : <ExpandMore />)}

                            </ListItemButton>
                            <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                {/* {item.menu_items.map((item1) => (  */}
                                {item.children.map((item1) => ( 



                                    // <div key={item1.id}>
                                    <div key={`${item.id}-${item1.id}`}> {/*Key & Index are a combination of parent + child keys*/}


                                        {item1.children.length>0 ?
                                        <>

                                        <ListItemButton  
                                            // selected={selectedIndex === item1.id} 
                                            selected={selectedIndex === `${item.id}-${item1.id}`}
                                            className="dashboard-submenu-navlink" 
                                            // onClick={() => handleClick(item1.id)}
                                            onClick={() => handleClick(`${item.id}-${item1.id}`)}
                                        >
                                            <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                            {/* {open[item1.id] ? <ExpandLess /> : <ExpandMore />} */}
                                            {open[`${item.id}-${item1.id}`] ? <ExpandLess /> : <ExpandMore />}
                                        </ListItemButton>
                                        {/* <Collapse in={open[item1.id]} timeout="auto" unmountOnExit> */}
                                        <Collapse in={open[`${item.id}-${item1.id}`]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>

                                            {item1.children.map((item2) => ( 
                                                // <div key={item2.id}>
                                                <div key={`${item.id}-${item2.id}`}> {/*Key & Index are a combination of parent/module + grand child keys*/}

                                                    <ListItemButton  
                                                        // selected={selectedIndex === item2.id} 
                                                        selected={selectedIndex === `${item.id}-${item2.id}`}
                                                        className="dashboard-submenu-navlink" 
                                                        // onClick={() => handleClick(item2.id)}
                                                        onClick={() => handleClick(`${item.id}-${item2.id}`)}
                                                        component={Link} to={item2.path}
                                                    >
                                                        <ListItemText primary={item2.name} className="dashboard-submenu-text"/>
                                                    </ListItemButton>
                                                </div>
                                            ))}

                                            </List>
                                        </Collapse>

                                        </>
                                        :
                                        <ListItemButton  
                                            // selected={selectedIndex === item1.id} 
                                            selected={selectedIndex === `${item.id}-${item1.id}`}
                                            className="dashboard-submenu-navlink" 
                                            // onClick={() => handleClick(item1.id)}
                                            onClick={() => handleClick(`${item.id}-${item1.id}`)}
                                            component={Link} to={item1.path}
                                        >
                                            <ListItemText primary={item1.name} className="dashboard-navlink-text"/>
                                        </ListItemButton>
                                        }

                                    </div>
                                ))}

                                </List>
                            </Collapse> 
                        </>
                        :
                        <ListItemButton 
                            selected={selectedIndex === item.id}
                            className="dashboard-navlink" 
                            onClick={() => handleClick(item.id)}
                            component={Link} to={item?.path || '#'} //Optional Chaining with fallback menu
                        >
                            <MenuIcon item={item} />
                            <ListItemText primary={item.name} className="dashboard-navlink-text"/>
                        </ListItemButton>
                        }
                    </div>
                    ))
                    }
            </List>
            } 
        </section> 
    )
}

export default memo(AdminSidebar);