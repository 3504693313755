import React, { useState, useContext, useRef } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";

import { Modal } from 'antd';
// import NumberFormat from "react-number-format";

// import '../../styles/ProductDetails.css'

import UserAlerts from '../../components/UserAlerts';


const PriceListNew = ({isModalOpen, setIsModalOpen }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [sku, setSku] = useState('');
    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // const { data: products } = useQuery({
    //     queryKey: ['products'],
    //     queryFn: async () => {
    //         const response = await authAxios.get(`/products/skus/?page_size=${pageSize}`);
    //         return response.data.results;
    //     },
    // });

   
    const mutation = useMutation({
        mutationFn: (priceList) => {
            return authAxios.post("/products/price-lists/", priceList);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // queryClient.invalidateQueries(['units']);
            queryClient.invalidateQueries(['priceLists']);
            setIsModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

       
        if (validDataRef.current===true) {
            const priceList = {client, name, memo, user };
            console.log("PRICE LIST", priceList)
            mutation.mutate(priceList)
        }
    };


    return (
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Price List" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        {/* <Autocomplete
                            onChange={(event, newValue) => {
                                setSku(newValue.id)

                                }}
                            
                            options={products}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Product" 
                                    {...params} 
                                />
                            }
                        /> */}

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            label="Name" 
                            variant="outlined" 
                            fullWidth
                            required
                            // error={nameError}
                        />
                        

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default PriceListNew;
