
import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import { useQuery } from "@tanstack/react-query";

import { IconButton, Button } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faFileExcel} from '@fortawesome/free-solid-svg-icons';



import { SearchOutlined } from '@ant-design/icons';
import { FilterFilled } from '@ant-design/icons';



import { Dropdown, Menu, Form, Space, DatePicker, Row, Col } from 'antd';


// import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import authAxios from "../components/authAxios";

import moment from 'moment';



// import Layout from "../components/Layout";
import DashboardHeader from "../components/DashboardHeader";
import './Report.css'
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

import Loading from "../components/Loading";


const OwnerStatement = () => {
    const location = useLocation();
    const property = location.state.property;
    const [reportMonth, setReportMonth] = useState(moment().format('MM'))
    const [reportYear, setReportYear] = useState(moment().format('YYYY'))
    // const [totalBalanceBf, setTotalBalanceBf] = useState(0)

    const [otherDeductions, setOtherDeductions] = useState(0)
    const [totalDeductions, setTotalDeductions] = useState(0)
    const [amountDue, setAmountDue] = useState(0)


    const { data: ownerStatement, isLoading } = useQuery({
        queryKey: ['owner-statement', reportMonth, reportYear],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/owner-statement/?property=${property.id}&month=${reportMonth}&year=${reportYear}`);
            console.log("OWNER STATEMENT", response.data)
            // return response.data.report_data
            return response.data

        },
    });

    if (isLoading) return <Loading loading={true} />;


    // const { data: collectionReport } = useQuery({
    //     queryKey: ['collection-report', reportMonth, reportYear],
    //     queryFn: async () => {
    //         const response = await authAxios.get(`/rentals/collection-report/?property=${property.id}&month=${reportMonth}&year=${reportYear}`);
    //         return response.data
    //     },
    // });

    // const totalBalanceBf = ownerStatement ? ownerStatement.reduce((acc, data) => {
    //     return acc + (parseFloat(data.balance_brought_forward) || 0);
    // }, 0) : 0;

    // const totalAmountPayable = ownerStatement ? ownerStatement.reduce((acc, data) => {
    //     return acc + (parseFloat(data.amount_payable) || 0);
    // }, 0) : 0;

    // const totalAmountPaid = ownerStatement ? ownerStatement.reduce((acc, data) => {
    //     return acc + (parseFloat(data.amount_paid) || 0);
    // }, 0) : 0;

    // const totalBalance = ownerStatement ? ownerStatement.reduce((acc, data) => {
    //     return acc + (parseFloat(data.balance) || 0);
    // }, 0) : 0;

    // console.log("BAL",totalBalanceBf)
    const downloadXLS = () => {

        const worksheetData = ownerStatement.report_data.map(data => ({
            'House No': data.house_no,
            'Tenant': data.customer,
            'Contact': data.contact,
            'Balance B/F': parseFloat(data.balance_brought_forward),
            'Amount Payable': parseFloat(data.amount_payable),
            'Amount Paid': parseFloat(data.amount_paid),
            'Balance': parseFloat(data.balance),
        })); // Add totals row at the end
    // })).concat(totalsRow); // Add totals row at the end

       
        const totalsRow = {
            'House No': 'TOTALS', // Adjust label if needed
            'Tenant': '', 
            'Contact': '',
            // 'Balance B/F': totalBalanceBf,
            // 'Amount Payable': totalAmountPayable,
            // 'Amount Paid': totalAmountPaid,
            // 'Balance': totalBalance,
          };

        // Add totals row to the worksheet data
        worksheetData.push(totalsRow);
        

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(worksheetData);
       

        // console.log("WS", ws)
        // console.log("TOTALS", wsTotals)


        // Append totals to the worksheet
        // XLSX.utils.sheet_add_json(ws, [wsTotals[0]], { skipHeader: true, origin: -1 });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Collection Report');

        XLSX.writeFile(wb, `Collection_Report_${property.name}_${reportMonth}_${reportYear}.xlsx`);
    };

    const onChange = (date, dateString) => {
        // console.log(date);
        setReportMonth(date.month()+1)
        setReportYear(date.year())
      };

    const menu = (
        <Menu>
            <form className="filter-form">
                <Space direction="vertical">
                    <DatePicker onChange={onChange} picker="month" placement="bottomRight" />
                </Space>
            </form>
        </Menu>
      );
    const reportPreTitle=property.name+" - PERIOD "+reportMonth+"/"+reportYear


    // a helper function to check if a column has data
    const hasColumnData = (columnName) => {
        return ownerStatement.report_data.some(row => row[columnName] !== 0 && row[columnName] !== null && row[columnName] !== undefined);
      };

    const formatNumber = (number) => {
        return number.toLocaleString('en-UK', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    // useEffect(()=>{   
    //     setTotalDeductions(otherDeductions)
    //     setAmountDue(totalAmountPayable-totalDeductions)
      
    // },[])


    return (
        <div className="dashboard-main owners-statement">
            <section className="dashboard-content">
                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle="Owner Statement" dashboardPreTitle={reportPreTitle} />
                    <div className="dashboard-content-header-nav-icons">
                        {/* <button onClick={downloadXLS}>Download XLS</button> */}
                        <IconButton onClick={downloadXLS}>
                            <FontAwesomeIcon className="menu-icon" icon={faFileExcel} />
                        </IconButton>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow>
                      
                        <Button className="menu-button-filter" variant="outlined" startIcon={<TuneIcon />}>
                            Filter
                        </Button>

                    </Dropdown>
                    </div>
                
                </div>

                <table >
                        <thead>
                        <tr>
                            <th>House No</th>
                            <th>Tenant</th>
                            <th>Contact</th>
                            <th className="cell-align-right">Balance B/F</th>
                            <th className="cell-align-right">Deposit</th>
                            <th className="cell-align-right">Rent</th>
                            {hasColumnData('garbage_payable') && <th className="cell-align-right">Garbage</th>}
                            {hasColumnData('security_payable') && <th className="cell-align-right">Security</th>}
                            {hasColumnData('water_payable') && <th className="cell-align-right">Water</th>}
                            <th className="cell-align-right">Total Due</th>
                            <th className="cell-align-right">Paid</th>
                            <th className="cell-align-right">Balance</th>
                        </tr>
                        </thead>

                        {/* {collectionReport && collectionReport.map(data => (
                        <tbody key={data.house_no}>
                            <tr>
                                <td>{data.house_no}</td>
                                <td>{data.customer}</td>
                                <td>{data.contact}</td>
                                <td className="cell-align-right">{Number(data.balance_brought_forward).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.amount_payable).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.amount_paid).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.balance).toLocaleString()}</td>
                            </tr>
                        </tbody>
                        ))} */}
                          
                        <tbody >
                            {ownerStatement && ownerStatement.report_data.map(data => (
                            <tr key={data.house_no}>
                                <td>{data.house_no}</td>
                                <td>{data.customer}</td>
                                <td>{data.contact}</td>
                                <td className="cell-align-right">{Number(data.balance_brought_forward).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.deposit_payable).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.rent_payable).toLocaleString()}</td>
                                {hasColumnData('garbage_payable') && <td className="cell-align-right">{Number(data.garbage_payable).toLocaleString()}</td>}
                                {hasColumnData('security_payable') && <td className="cell-align-right">{Number(data.security_payable).toLocaleString()}</td>}
                                {hasColumnData('water_payable') && <td className="cell-align-right">{Number(data.water_payable).toLocaleString()}</td>}

                                <td className="cell-align-right">{Number(data.amount_payable).toLocaleString()}</td>

                                <td className="cell-align-right">{Number(data.amount_paid).toLocaleString()}</td>
                                <td className="cell-align-right">{Number(data.balance).toLocaleString()}</td>
                            </tr>

                            ))}

                            {ownerStatement && ownerStatement.totals &&
                            <>
                            <tr className="totals-row" >
                                <th className="cell-bold" colSpan="3">TOTALS</th>
                                <th className="cell-align-right">{ownerStatement.totals.balance_brought_forward.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.deposit_payable.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.rent_payable.toLocaleString()}</th>
                                {hasColumnData('garbage_payable') && <th className="cell-align-right">{ownerStatement.totals.garbage_payable.toLocaleString()}</th>}
                                {hasColumnData('security_payable') && <th className="cell-align-right">{ownerStatement.totals.security_payable.toLocaleString()}</th>}
                                {hasColumnData('water_payable') && <th className="cell-align-right">{ownerStatement.totals.water_payable.toLocaleString()}</th>}
                                <th className="cell-align-right">{ownerStatement.totals.amount_payable.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.amount_paid.toLocaleString()}</th>
                                <th className="cell-align-right">{ownerStatement.totals.balance.toLocaleString()}</th>
                            </tr>
                           

                            {/* <tr>
                                <td colSpan="4">TOTAL RENT</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right cell-bold">{formatNumber(ownerStatement.totals.rent_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr> */}

                            <tr>
                                <td colSpan="4">MANAGEMENT FEES  {(ownerStatement.management_fee_rate*100).toFixed(2)}%</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.management_fee)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">PAYMENTS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.payments)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">COLLECTIONS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.collections)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">EXPENSES</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.property_expenses)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">TOTAL DEDUCTIONS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.deductions)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>

                            <tr>
                                <td colSpan="4">DEPOSITS</td>
                                <td className="cell-align-right"></td>
                                <th className="cell-align-right">{formatNumber(ownerStatement.totals.deposit_payable)}</th>
                                <td className="cell-align-right"></td>
                                <td className="cell-align-right"></td>
                            </tr>
                            </>
                            }


                            
                        </tbody>

                        <tfoot>
                        {ownerStatement && ownerStatement.totals &&

                          <tr>
                              <th colSpan="4">AMOUNT DUE</th>
                              <th className="cell-align-right"></th>
                              <th className="cell-align-right">{formatNumber(ownerStatement.totals.owner_balance)}</th>
                              <th className="cell-align-right"></th>
                              <th className="cell-align-right"></th>
                          </tr>
                        }


                        </tfoot>

                        {/* <tbody>
                      
                        </tbody> */}




                </table>

                {/* <table >
                    <tbody>
                        <tr>
                            <td>TOTAL RENT</td>
                            <td></td>
                            <td></td>
                            <td className="cell-align-right"></td>
                            <th className="cell-align-right">{totalAmountPayable.toLocaleString()}</th>
                            <td className="cell-align-right"></td>
                            <td className="cell-align-right"></td>
                        </tr>
                    </tbody>

                </table> */}


            </section>
        </div>
    );
};

export default OwnerStatement;


