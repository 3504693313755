// import React, { useEffect } from "react"
import { useQuery } from "@tanstack/react-query";

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import authAxios from "../../components/authAxios";


import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import { Dropdown, Menu, Space } from 'antd';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ClientProfile from "./ClientProfile";
import AccountingSettings from "./AccountingSettings";
import EbaySettings from "./EbaySettings";


import DashboardHeader from "../../components/DashboardHeader";
import UserAlerts from "../../components/UserAlerts";
import '../../styles/AppSettings.css'
import SettingsMenu from "./SettingsMenu";
import ClientProfileView from "./ClientProfileView";


const AppSettings = () => {
    const [key, setKey] = useState('company');
    const [removeBorder, setRemoveBorder] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    // const errorRef = useRef(null);
    const userAlertRef = useRef(null);
    const navigate = useNavigate()


    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    const { data: countries } = useQuery({
        queryKey: ['countries'],
        queryFn: async () => {
          const response = await authAxios.get('/countries');
          return response.data
        },
    });

    const menu = (
        <Menu>
            <Menu.Item onClick={()=>navigate("/dashboard/upload-client-data",{state:{clientProfile}})}>
                <a>
                    Upload Data
                </a>
            </Menu.Item>
            <Menu.Item disabled>
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    Other Items
                </a>
            </Menu.Item>
        </Menu>
    );

    useEffect(()=>{
        if (error||success){
            userAlertRef.current.scrollIntoView();  
        }            
    },[error, success])


    return(
        <div className="dashboard-main">
            {/* <SettingsMenu /> */}

            {clientProfile && 

            <section className="dashboard-content"> 
                <div ref={userAlertRef}>
                    <UserAlerts error={error} success={success}/>
                </div>

                <div className="dashboard-content-header-nav">
               
                    <DashboardHeader dashboardTitle="Settings" dashboardPreTitle="Settings" removeBorder={removeBorder}  />
                    <div className="dashboard-content-header-nav-icons">

                        <Dropdown overlay={menu} placement="bottomRight" arrow>
                            <a onClick={e => e.preventDefault()}>
                            <Space>
                                <IconButton> 
                                    <MenuIcon />
                                </IconButton>
                            </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>

                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="dashboard-tabs"
                >
                    <Tab eventKey="company" title="Company">
                        <ClientProfileView 
                            clientProfile={clientProfile} 
                            countries={countries} 
                            setError={setError}
                            success={success}
                            setSuccess={setSuccess}
                        />

                    </Tab>

                    <Tab eventKey="acc" title="Accounting">
                        {/* <AccountingSettings clientProfile={client} /> */}
                        <AccountingSettings clientProfile={clientProfile} />

                    </Tab>
                    {clientProfile.ebay_is_active &&
                    // {client.ebay_is_active &&
                    <Tab eventKey="ebay" title="EBay">
                        {/* <EbaySettings clientProfile={client} /> */}
                        <EbaySettings clientProfile={clientProfile} />

                    </Tab>
                    }
                    
                    
                </Tabs>     
                {/* } */}

            </section>
            }
            

        </div>

    )

}

export default AppSettings;