// import React from "react";
// import { useState } from "react";
import { Chip } from "@mui/material";
// import { createTheme, ThemeProvider } from '@mui/material/styles';

const Pillchip = ({label}) => {   

    const getColor = (label) => {
        switch (label) {
            case 'Draft':
                return { backgroundColor: '#607d8b', color: 'white'};



            case 'Paid':
                return { backgroundColor: '#009688', color: 'white'};

            case 'Posted':
                return { backgroundColor: '#009688', color: 'white'};
            
            case 'Billed':
                return { backgroundColor: '#009688', color: 'white'};
            
            case 'Completed':
                return { backgroundColor: '#009688', color: 'white'};

            case 'Active':
                return { backgroundColor: '#009688', color: 'white'};
            case 'Occupied':
                return { backgroundColor: '#009688', color: 'white'};
            case 'Sent':
                return { backgroundColor: '#009688', color: 'white'};
           
            case 'Verified':
                return { backgroundColor: '#00bcd4', color: 'white'};



            case 'In Progress':
                return { backgroundColor: '#ffe082', color: 'black'};


            case 'Failed':
                return { backgroundColor: '#ff5252', color: 'white'};



            case 'Moved Out':
                return { backgroundColor: '#00bcd4', color: 'white'};

        //   case 'Submitted':
        //     return { backgroundColor: '#4caf50', color: 'white' };
        //   case 'Approved':
        //     return { backgroundColor: '#2196f3', color: 'white' };
            default:
                return {backgroundColor: '#607d8b', color: 'white'}; // Default styles
        }
        };

    const color = getColor(label)

    return (    
        <Chip label={label} size="small" sx={color} className="custom-pill-chip"/>
      );
}
 
export default Pillchip;