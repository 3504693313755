import React from "react";

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import useAxios from "../../components/useAxios";
import authAxios from "../../components/authAxios";


import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";


import {Radio, message } from "antd";

import UserAlerts from "../../components/UserAlerts";
import DashboardHeader from "../../components/DashboardHeader";

import NumberFormat from "react-number-format";



function BulkCreateMeters() {
    const location = useLocation();
    const queryClient = useQueryClient();


    // const {response, error, fetchData}  = useAxios();
    // const errorRef = useRef(null);
    // const validDataRef = useRef(false);
    const [error, setError] = useState('');

    // useEffect(() => {
    //     if (response.status === 201){
    //         navigate('/dashboard/customers/');
    //     }    
    // }, [response.status]); 

    const navigate = useNavigate();

    const [utilityType, setUtilityType] = useState('10');
    const [sku, setSku] = useState('');
    // const [createdMetersCount, setCreatedMetersCount] = useState(null);
    const [price, setPrice] = useState('');

    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const property = location.state.property;

    const { data: products } = useQuery({
        queryKey: ['products'],
        queryFn: async () => {
            // const response = await authAxios.get(`/products/skus/?page_size=${pageSize}`);
            const response = await authAxios.get("/products/skus/");
            return response.data;
        },
    });

    const handleConfirmedPrice = (e) => {
        // console.log("TESTING", e)
        const amt = parseFloat(e.target.value.replace(/,/g, ''));
        // setPrice(e.target.value)
        setPrice(amt)
    }

    const mutation = useMutation({
        mutationFn: (meter) => {
            return authAxios.post("/rentals/bulk-create-meters/", meter);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // queryClient.invalidateQueries(['units']);
            // console.log(result)
            // setCreatedMetersCount(result.data.count);
            // message.success(`Successfully created ${result.data.count} meters.`);
            message.success(`${result.data.message}`);
            queryClient.invalidateQueries(['meters']);
            navigate('/dashboard/meters/');
        },
    })


    const handleSubmit = (e) => {
        e.preventDefault();

        if (property) {
            const property_id = property.id
            const sku_id = sku

            const meter = { client, property_id, sku_id, price, user, sku };

            console.log(meter)
            mutation.mutate(meter)

            // fetchData({
            //     url: '/rentals/upload-invoice-data/',
            //     method: 'post',
            //     data: upload,
            //     headers: {"Content-Type" : "multipart/form-data"} //REQUIRED FOR FILE UPLOADS
            // })

      } 
  
  }

    return(
        <div className="dashboard-main">
            <section className="dashboard-content">

            <section className="dashboard-form dashboard-form-compact">
            {/* <section className="dashboard-form-light"> */}
                <UserAlerts error={error} />
                <DashboardHeader dashboardTitle="Bulk Create Meters" dashboardPreTitle="Properties" />

                <form noValidate autoComplete="off" onSubmit={handleSubmit}>

                    <Radio.Group 
                        onChange={(e) => setUtilityType(e.target.value)} 
                        className="custom-input"
                        value={utilityType}
                    >
                        <Radio value="10">Water</Radio>
                        <Radio value="20" disabled>Electricity</Radio>

                    </Radio.Group>

                    <TextField className="custom-input"
                        label="Name"
                        variant="outlined" 
                        fullWidth
                        defaultValue = {property.name}
                        InputProps={{ readOnly: true }}
                    />

                    <Autocomplete
                        onChange={(event, newValue) => {
                            setSku(newValue.id)
                            setPrice(newValue.price)
                            }}
                        options={products}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => 
                            <TextField 
                                className='custom-input'
                                label="Product" 
                                {...params} 
                            />
                        }
                    />

                    <NumberFormat 
                        value={price}
                        // onChange={(e) => setAmount(e.target.value)} 
                        onChange={(e) => handleConfirmedPrice(e)} 
                        className="custom-input"
                        fullWidth
                        label="Price" 
                        variant="outlined" 
                        customInput={TextField}
                        thousandSeparator={true} 
                        // disabled
                    />

                    <div className="button-border-top">
                        <button id="submit" className="btn btn-primary form-buttons" type="submit">
                            Submit
                        </button>
                    </div>

                    {/* {createdMetersCount !== null && (
                        <div className="result-message">
                            Successfully created {createdMetersCount} meters.
                        </div>
                    )} */}


                </form>

            </section>
            </section> 

        </div>
    )

}

export default BulkCreateMeters;
