import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import { Link } from "react-router-dom";
import Pagination from '@mui/material/Pagination';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import DashboardHeader from "../../components/DashboardHeader";
import { useMediaQuery } from 'react-responsive'
import { Card, CardContent } from "@mui/material";

import { format } from 'date-fns';

import ThreeDotsMenu from "../../components/ThreeDotsMenu";
// import ContactNew from "../../components/ContactNew";
import PriceListNew from "./PriceListNew";
import Pillchip from "../../components/Pillchip";



function PriceLists() {
    // const isMobile = useMediaQuery({ query: '(max-width: 750px)' })
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const tag = "OWNER"
    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const { data: priceLists } = useQuery({
        queryKey: ['priceLists', page],
        queryFn: async () => {
          const response = await authAxios.get(`/products/price-lists/?page=${page}`);
          setPageCount(response.data.page_count)
          return response.data.results
        },
        // keepPreviousData : true,
    });

    const handlePageChange = (event, value) => {
        setPage(value);
    };

     const threeSubMenu = [
        {
            id: 1,
            menu: "View",
            url: "/dashboard/price-list/",

        }, 
        // {
        //     id: 2,
        //     menu: "Edit",
        //     url: "/dashboard/customeredit/",
        // }
    ];   

    return (
            <div className="dashboard-main">
                <section className="dashboard-content">
                    <DashboardHeader dashboardTitle="Price Lists" dashboardPreTitle="PRODUCTS" />

                
                    <table className="styled-table">
                        <thead>
                            <tr >
                                <th>ID</th>
                                <th>Name</th>
                                <th>Date Created</th>
                                <th>Products</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>

                        {priceLists && priceLists.map(priceList => (

                            <tbody key={priceList.id}>
                                <tr>
                                    <td>{priceList.id}</td>
                                    <td>{priceList.name}</td>
                                    <td>{convertDateTime(priceList.created_at)}</td>
                                    <td>{priceList.product_count}</td>
                                    {/* <td>{priceList.status_name}</td> */}
                                    <td><Pillchip label={priceList.status_name} /></td>
                                    <td className='styled-table-menu'>
                                        <ThreeDotsMenu data={priceList} threeSubMenu={threeSubMenu} />
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                        

                    </table>

                    <PriceListNew 
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        // property={property.id}
                    />
                        
                    <Pagination 
                        className="custom-mui-pagination"
                        count={pagecount} 
                        page={page} 
                        onChange={handlePageChange} 
                    />

                    {/* <Link to="/dashboard/contact-new" className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}
                    {/* <Link to="/dashboard/contact-new" state={{ tag: "OWNER" }} className="nav-link">
                        <Fab className="fab" color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link> */}

                    <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                        setIsModalOpen(true)}}
                        >
                        <AddIcon />
                    </Fab>
                </section>
            </div>

    )
}
export default PriceLists