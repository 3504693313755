import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import authAxios from "../../components/authAxios";

import { IconButton } from "@mui/material";
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';


//TABS - BOOTSTRAP
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import DashboardHeader from "../../components/DashboardHeader";
// import TransactionList from "../accounting/TransactionList";
import UserAlerts from "../../components/UserAlerts";
// import PropertyView from "../rentals/PropertyView";
// import UnitList from "../rentals/UnitList";
// import PriceListView from "./PriceListView"
// import ProductList from "./PriceListItems";
// import PriceListItem from "./PriceListItems";
// import PriceListItems from "./PriceListItems";
import PriceListItemView from "./PriceListItemView";
import PriceTiers from "./PriceTiers";

const PriceListItem = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [error, setError] = useState('');
    const errorRef = useRef(null);

    const priceListItemId = params.id

    // const [removeBorder, setRemoveBorder] = useState(true);
    const removeBorder = true
    
    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();

    const { data: clientProfile } = useQuery({
        queryKey: ['clientProfile'],
        queryFn: async () => {
          const response = await authAxios.get(`/clientprofiles/${localStorage.getItem('clientProfile')}`);
          return response.data

        },
    });

    // const { data: priceList } = useQuery({
    //     queryKey: ['priceList', priceListId],
    //     queryFn: async () => {
    //       const response = await authAxios.get(`products/price-lists/${priceListId}`);
    //     //   return response.data
    //       return response.data

    //     },
    // });


    const { data: priceListItem } = useQuery({
        queryKey: ['priceListItem', priceListItemId],
        // queryKey: ['priceListItem'],
        queryFn: async () => {
          const response = await authAxios.get(`products/price-list-items/${priceListItemId}`);
          return response.data
        },
        // enabled: !!priceList
    });

    const { data: priceTiers } = useQuery({
        queryKey: ['priceTiers'],
        queryFn: async () => {
          const response = await authAxios.get(`products/price-tiers/?price_list_item=${priceListItem.id}`);
          return response.data.results
        },
        enabled: !!priceListItem
    });
        

    //Bootstrap Tabs
    const [key, setKey] = useState('profile');
    const handleTabChange = (k) => {
        setKey(k)
    };

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(()=>{
        if (error){
            errorRef.current.scrollIntoView();  
        }            
    },[error])


    return (
        <div className="dashboard-main">
            {priceListItem &&

            <section className="dashboard-content dashboard-content-compact">
                 <div ref={errorRef}>
                    <UserAlerts error={error} />
                </div>

                <div className="dashboard-content-header-nav">
                    <DashboardHeader dashboardTitle={priceListItem.product_name} dashboardPreTitle="Price List Item" removeBorder={removeBorder} />

                    {/* <div className="dashboard-content-header-nav-icons">
                        <Tooltip title="Statement">
                            <IconButton 
                                onClick={() => navigate('/dashboard/customer-statement/',{state:{reference:params.id}})}
                            > 
                                <DescriptionIcon/>
                            </IconButton>
                        </Tooltip>
                    </div> */}
                </div>


              
                <Tabs
                    activeKey={key}
                    onSelect={(k) => handleTabChange(k)}
                    className="dashboard-tabs"
                    id="property-tabs"
                    mountOnEnter={true}
                    unmountOnExit={true}
                    transition={false}
                    >
                    
                    <Tab eventKey="profile" title="Profile">
                        <PriceListItemView 
                            priceListItem={priceListItem}
                            // setError={setError}
                        />
                    </Tab>

                    <Tab eventKey="price-tiers" title="Tiers">
                        <PriceTiers 
                            priceTiers={priceTiers} 
                            priceListItem={priceListItem} 
                        />
                    </Tab>
                    
                </Tabs> 


            </section>
            }

        </div>
    );
  };
  
  export default PriceListItem;