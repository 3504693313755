import React, { useState, useContext, useRef } from 'react';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import authAxios from '../../components/authAxios';
import {UserContext} from "../../App"

import { TextField, Autocomplete } from "@mui/material";

import { Modal, message } from 'antd';
// import NumberFormat from "react-number-format";

import UserAlerts from '../../components/UserAlerts';


const MeterNew = ({isModalOpen, setIsModalOpen }) => {
    // const page_size = useContext(UserContext).page_size;
    // const userSettings = useContext(UserContext);
    const { pageSize } = useContext(UserContext);
    const queryClient = useQueryClient();

    const errorRef = useRef(null);
    const validDataRef = useRef(false);
    const [error, setError] = useState('');


    const [reference, setReference] = useState('');
    const [property, setProperty] = useState('');
    const [unit, setUnit] = useState('');
    const [sku, setSku] = useState('');

    const [memo, setMemo] = useState('');
    const user = localStorage.getItem('userID');
    const client = localStorage.getItem('clientID');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { data: properties } = useQuery({
        queryKey: ['properties'],
        queryFn: async () => {
            const response = await authAxios.get(`/rentals/properties/?page_size=${pageSize}`);
            return response.data.results;
        },
    });

    const { data: units, refetch: refetchUnits } = useQuery({
        queryKey: ['units', property],
        queryFn: async () => {
          if (property) {
            const response = await authAxios.get(`/rentals/property-units/?property=${property}&page_size=${pageSize}`);
            return response.data.results;
          }
          return [];
        },
        enabled: !!property, // Fetch units only when property is selected
    });

    const { data: products } = useQuery({
        queryKey: ['products'],
        queryFn: async () => {
            // const response = await authAxios.get(`/rentals/rental-inventory/?property=${property}&page_size=${pageSize}`);
            // const response = await authAxios.get(`/inventory/items/?page_size=${pageSize}`);
            const response = await authAxios.get(`/products/skus/?page_size=${pageSize}`);
            return response.data.results;
        },
    });

   
    const mutation = useMutation({
        mutationFn: (meter) => {
            return authAxios.post("/rentals/meters/", meter);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            // console.log("RESULT", result)
            // message.success(`Successfully created transaction ${result.data.reference}`);

            // queryClient.invalidateQueries(['units']);
            queryClient.invalidateQueries(['meters']);
            setIsModalOpen(false);
        },
        })


    const handleSubmit = (e) => {
        e.preventDefault();
        validDataRef.current=true

       
        if (validDataRef.current===true) {
            
            // const unit = {client, reference, property, product, memo, user };
            const meter = {client, reference, unit, sku, memo, user };

            console.log("UNIT", meter)
            mutation.mutate(meter)
        }
    };


    return (
        <>
        {isModalOpen && 
            <Modal className="dashboard-modal" title="Add Utility Meter" visible={isModalOpen} open={isModalOpen} onOk={handleSubmit} onCancel={handleCancel}>
                <div className="dashboard-form">

                    <div ref={errorRef}>
                        <UserAlerts error={error}  />
                    </div>

                    <form onSubmit={handleSubmit}>

                        <Autocomplete
                            onChange={(event, newValue) => {
                                setProperty(newValue.id)
                                }}
                            options={properties}
                            getOptionLabel={(option) => option.name}

                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Property" 
                                    {...params} 
                                />
                            }
                        />

                        <Autocomplete
                            onChange={(event, newValue) => {
                                setUnit(newValue.id);
                            }}
                            options={units || []}
                            getOptionLabel={(option) => {
                                // return option ? option.product_name : ''; // Ensure option is not null or undefined
                                return option ? option.reference : ''; // Ensure option is not null or undefined
                            }}
                            renderInput={(params) => <TextField className="custom-input" fullWidth {...params} label="Unit" />}
                            disabled={!property} // Disable unit selection until property is selected
                        />

                        <Autocomplete
                            onChange={(event, newValue) => {
                                setSku(newValue.id)
                                }}
                            options={products}
                            getOptionLabel={(option) => option.name}

                            renderInput={(params) => 
                                <TextField 
                                    className='custom-input'
                                    label="Product" 
                                    {...params} 
                                />
                            }
                        />

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setReference(e.target.value)
                            }}
                            label="Meter No" 
                            variant="outlined" 
                            fullWidth
                            required
                            // error={nameError}
                        />
                        
                        {/* <NumberFormat 
                            className="custom-input"
                            fullWidth
                            label="Total" 
                            variant="outlined" 
                            customInput={TextField}
                            thousandSeparator={true} 
                            value={subTotal}
                            disabled
                            error={totalError}
                        /> */}

                        <TextField className="custom-input"
                            onChange={(e) => {
                                setMemo(e.target.value)
                            }}
                            label="Notes"
                            variant="outlined"
                            multiline
                            rows={2}
                            fullWidth
                        />

                    </form>
                </div>
            </Modal>
        }
        </>

    );
};

export default MeterNew;
