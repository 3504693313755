import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';


import authAxios from "../../components/authAxios";

import Pagination from '@mui/material/Pagination';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { VisibilityOutlined } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { format } from 'date-fns';

import Pillchip from "../../components/Pillchip";
import EllipsisMenu from "../../components/EllipsisMenu";

import './Crm.css'



const MessageList = ({customer}) => {

    const [page, setPage] = useState(1);
    const [pagecount, setPageCount] = useState();
    const queryClient = useQueryClient();
    const [error, setError] = useState('');

    const customerId = customer.id

    // PAGINATION
    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const { data: messages } = useQuery({
        queryKey: ['messages', customerId, page],
        queryFn: async () => {
            const response = await authAxios.get(`/communication/messages/?customer=${customerId}&page=${page}`);
            // const response = await authAxios.get(`/communication/messages/?page=${page}`);

            setPageCount(response.data.page_count)
            return response.data.results
        },
    });

    // const [isModalOpen, setIsModalOpen] = useState(false);

    const convertDateTime = (myDateTime) => {
        return format(new Date(myDateTime), 'dd-MM-yyyy HH:mm');
    };

    const menuItems = (message) => [
        {
            label: 'View',
            icon: <VisibilityOutlined />,
            onClick: () => {
                // Handle view action
                // navigate(`/dashboard/customer/${customer.id}`)
            }
        },
    ];

    const mutation = useMutation({
        mutationFn: (unit) => {
            return authAxios.delete(`/rentals/property-units/${unit.id}`);
        },
        onError: (error, variables, context) => {
            console.log("ERROR",error)

            if (error.response.data.error){
                // If it's an array, display each error message
                if (Array.isArray(error.response.data.error)) {
                    error.response.data.error.map((errorMessage) => (
                        setError(errorMessage)
                    ));
                } else {
                    // Handle single error message
                    setError(error.response.data.error); 
                }
            } else {
                // Fallback to Standard error message
                setError(error.message); 
            }      
        },
        onSuccess: (result) => {
            queryClient.invalidateQueries(['property-units']);
            // navigate('/dashboard/leases/');
        },
      })

    return (
        <>
            {/* {units && */}
            <table className="styled-table message-box">
                <thead>
                    <tr >
                        <th>Date</th>
                        <th>Type</th>
                        <th>Message</th>
                        <th>Status</th>
                        {/* <th></th> */}
                    </tr>
                </thead>

                {messages && messages.map(message => (
                    <tbody key={message.id}>
                        <tr>
                            <td>{convertDateTime(message.created_at)}</td>
                            <td>{message.type_name}</td>
                            <td>{message.content}</td>
                            <td><Pillchip label={message.status_name} /></td>
                            {/* <td className='styled-table-menu'>
                                <EllipsisMenu items={menuItems(message)} />
                            </td> */}

                        </tr>
                    </tbody>
                ))}
                
            </table> 
                    
            <Pagination 
                className="custom-mui-pagination"
                count={pagecount} 
                page={page} 
                onChange={handlePageChange} 
            />

            {/* <Fab className="fab" color="primary" aria-label="add" onClick={()=>{
                setIsModalOpen(true)}}
                >
                <AddIcon />
            </Fab> */}

        </>
    )

}

export default MessageList;